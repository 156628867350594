import React from 'react';
import Col from 'reactstrap/es/Col';
import Price from '../../Components/Price';

function Less({summary}) {
  let totalCash = 0;
  let totalCard = 0;
  let totalSales = 0;
  return (
    <Col className="report">
      <table>
        <thead>
          <tr>
            <th>Store Name</th>
            <th>Total Cash</th>
            <th>Total Card</th>
            <th>Total Sales</th>
          </tr>
        </thead>
        <tbody>
          {summary.vendor_names.map((vendor, index) => {
            totalCash += summary.total_cash[index].count;
            totalCard += summary.total_card[index].count;
            totalSales += summary.total_sales[index].count;
            return (
              <tr key={vendor.id}>
                <td>{vendor.title}</td>
                <td>
                  <Price>{summary.total_cash[index].count}</Price>
                </td>
                <td>
                  <Price>{summary.total_card[index].count}</Price>
                </td>
                <td>
                  <Price>{summary.total_sales[index].count}</Price>
                </td>
              </tr>
            );
          })}
          <tr className="grand-total">
            <td>Total</td>
            <td>
              <Price>{totalCash}</Price>
            </td>
            <td>
              <Price>{totalCard}</Price>
            </td>
            <td>
              <Price>{totalSales}</Price>
            </td>
          </tr>
        </tbody>
      </table>
    </Col>
  );
}

export default Less;
