import {createSelector} from 'reselect';

export const selectDrivers = (state) => state.get('drivers');

export const makeSelectDrivers = () =>
  createSelector(selectDrivers, (DriversState) =>
    DriversState.get('drivers').toJS(),
  );

export const makeSelectState = () =>
  createSelector(selectDrivers, (DriversState) => DriversState.get('state'));

export const makeSelectStatus = () =>
  createSelector(selectDrivers, (DriversState) => DriversState.get('status'));
