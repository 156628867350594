import React, {useState} from 'react';
import FormGroup from 'reactstrap/es/FormGroup';
import Label from 'reactstrap/es/Label';
import Input from 'reactstrap/es/Input';
import {useDispatch, useSelector} from 'react-redux';
import TextInput from '../../Components/TextInput';
import {makeSelectMenu} from '../../Selectors/Menu';
import {removePrinter, updatePrinter} from './actions';
import Loadable from '../../Components/Loadable';

function AddPrinter({printer, isDefault, index}) {
  const dispatch = useDispatch();
  const menu = useSelector(makeSelectMenu());
  const [state, setState] = useState('initial');
  const [type, setType] = useState(printer.type);
  const [name, setName] = useState(printer.name);
  const [host, setHost] = useState(printer.host);
  const [port, setPort] = useState(printer.port);
  const [prints, setPrints] = useState(printer.prints || 1);
  const [vat, setVat] = useState(printer.vat);
  const [checkout, setCheckout] = useState(printer.checkout);
  const [notes, setNotes] = useState(printer.notes);
  const [summary, setSummary] = useState(printer.summary);
  const [paid, setPaid] = useState(printer.paid);
  const [customer, setCustomer] = useState(printer.customer);
  const [thankYou, setThankYou] = useState(printer.thankYou);
  const [store, setStore] = useState(printer.store);
  const [categories, setCategories] = useState(printer.categories);

  const toggleSelect = ({id}) => {
    if (categories.includes(id)) {
      setCategories(categories.filter((category) => category !== id));
    } else {
      setCategories([...categories, id]);
    }
  };

  const handleAddPrinter = () => {
    setState('loading');
    dispatch(
      updatePrinter(
        {
          id: printer.id,
          type,
          name,
          host,
          port,
          checkout,
          prints,
          vat,
          notes,
          summary,
          paid,
          customer,
          thankYou,
          store,
          categories,
        },
        index,
        isDefault,
      ),
    );
  };
  return (
    <div className="add-printer">
      {isDefault ? (
        <div className="is-default">
          <button type="button" className="add-printer-btn btn-info" disabled>
            DEFAULT PRINTER
          </button>
        </div>
      ) : null}
      <div className="printer-type">
        <button
          className={`${type === 'usb' ? 'selected' : ''}`}
          type="button"
          onClick={() => setType('usb')}
        >
          USB
        </button>
        <button
          className={`${type === 'network' ? 'selected' : ''}`}
          type="button"
          onClick={() => setType('network')}
        >
          NETWORK
        </button>
      </div>
      {type === 'usb' && (
        <TextInput
          autoComplete="off"
          inputClassName="form-control"
          type="text"
          value={name}
          onChange={(value) => setName(value)}
          placeholder="Printer Name"
        />
      )}
      {type === 'network' && (
        <div className="network">
          <TextInput
            autoComplete="off"
            inputClassName="form-control ip-address"
            type="text"
            value={host}
            onChange={(value) => setHost(value)}
            placeholder="IP Address"
          />
          <TextInput
            autoComplete="off"
            inputClassName="form-control port"
            type="text"
            value={port}
            onChange={(value) => setPort(value)}
            placeholder="Port"
          />
        </div>
      )}
      <div className="no-of-prints">
        <h5>Number of Prints</h5>
        <TextInput
          autoComplete="off"
          inputClassName="form-control"
          type="text"
          value={prints}
          onChange={(value) => setPrints(value)}
          placeholder="Number of Prints"
        />
      </div>

      <FormGroup check>
        <Label check>
          <Input
            type="checkbox"
            defaultChecked={store}
            onChange={() => setStore(!store)}
          />{' '}
          Print Store Details
        </Label>
      </FormGroup>
      <FormGroup check>
        <Label check>
          <Input
            type="checkbox"
            defaultChecked={checkout}
            onChange={() => setCheckout(!checkout)}
          />{' '}
          Print Checkout Details
        </Label>
      </FormGroup>

      <FormGroup check>
        <Label check>
          <Input
            type="checkbox"
            defaultChecked={notes}
            onChange={() => setNotes(!notes)}
          />{' '}
          Print Notes
        </Label>
      </FormGroup>
      <div className="categories-selector">
        {menu.map((sub) => (
          <button
            key={sub.id}
            className={categories.includes(sub.id) ? 'category-selected' : ''}
            type="submit"
            onClick={() => toggleSelect(sub)}
          >
            {sub.title}
          </button>
        ))}
      </div>

      <FormGroup check>
        <Label check>
          <Input
            type="checkbox"
            defaultChecked={summary}
            onChange={() => setSummary(!summary)}
          />{' '}
          Print Order Summary
        </Label>
      </FormGroup>

      <FormGroup check>
        <Label check>
          <Input
            type="checkbox"
            defaultChecked={vat}
            onChange={() => setVat(!vat)}
          />{' '}
          Print VAT
        </Label>
      </FormGroup>

      <FormGroup check>
        <Label check>
          <Input
            type="checkbox"
            defaultChecked={paid}
            onChange={() => setPaid(!paid)}
          />{' '}
          Print Paid Status
        </Label>
      </FormGroup>

      <FormGroup check>
        <Label check>
          <Input
            type="checkbox"
            defaultChecked={customer}
            onChange={() => setCustomer(!customer)}
          />{' '}
          Print Customer Details
        </Label>
      </FormGroup>

      <FormGroup check>
        <Label check>
          <Input
            type="checkbox"
            defaultChecked={thankYou}
            onChange={() => setThankYou(!thankYou)}
          />{' '}
          Print Thank You note
        </Label>
      </FormGroup>
      <button
        type="button"
        className="add-printer-btn"
        onClick={handleAddPrinter}
      >
        <Loadable color="white" active={state === 'loading'}>
          SAVE PRINTER
        </Loadable>
      </button>
      {isDefault ? null : (
        <button
          type="button"
          className="add-printer-btn btn-danger"
          onClick={() => dispatch(removePrinter(printer.id, index))}
        >
          REMOVE PRINTER
        </button>
      )}
    </div>
  );
}

export default AddPrinter;
