import {createSelector} from 'reselect';

export const selectMenu = (state) => state.get('menu');

export const makeSelectMenu = () =>
  createSelector(selectMenu, (MenuState) => MenuState.get('menu').toJS());

export const makeSelectSelectedItem = () =>
  createSelector(selectMenu, (MenuState) =>
    MenuState.get('selectedItem').toJS(),
  );

export const makeSelectSelectedMenu = () =>
  createSelector(selectMenu, (MenuState) =>
    MenuState.get('selectedMenu').toJS(),
  );

export const makeSelectState = () =>
  createSelector(selectMenu, (MenuState) => MenuState.get('state'));

export const makeSelectCartItem = () =>
  createSelector(selectMenu, (MenuState) => MenuState.get('cartItem').toJS());
