import {createSelector} from 'reselect';

export const selectTill = (state) => state.get('till');

export const makeSelectOpeningTotal = () =>
  createSelector(selectTill, (TillState) => TillState.get('openingTotal'));

export const makeSelectClosingTotal = () =>
  createSelector(selectTill, (TillState) => TillState.get('closingTotal'));

export const makeSelectOpeningTime = () =>
  createSelector(selectTill, (TillState) => TillState.get('openingTime'));

export const makeSelectClosingTime = () =>
  createSelector(selectTill, (TillState) => TillState.get('closingTime'));

export const makeSelectStatus = () =>
  createSelector(selectTill, (TillState) => TillState.get('status'));

export const makeSelectState = () =>
  createSelector(selectTill, (TillState) => TillState.get('state'));
