/* eslint-disable */
import {fromCents} from './prices';

const totalColsPerPage = 48;
const leftCols = 43;

const init = '\x1B\x40';
const alignCenter = '\x1B\x61\x31';
const alignLeft = '\x1B\x61\x30';
const alignRight = '\x1B\x61\x32';
const newLine = '\x0A';
const bold_on = '\x1B\x45\x0D';
const bold_off = '\x1B\x45\x0A';
const em_mode_on = '\x1B\x21\x30';
const em_mode_off = '\x1B\x21\x0A\x1B\x45\x0A';
const small_text = '\x1B\x4D\x31';
const normal_text = '\x1B\x4D\x25';
const norm_text = '\x1B\x4D\x25';
const big_text = '\x1B\x21\x25';
const bigger_text = '\x1B\x21\x20';
const cutPaper = '\x1B\x69';
const openCashDrawer = '\x10' + '\x14' + '\x01' + '\x00' + '\x05';
const total_amount = 0;
const voucher_amount = 0;

// Pound sign
// \u00a35

/// //////////////////////////////////////////////////////////////////
//
// Server Side method for verifying the certificate
// Recommended: Because the private key is on server
//
/// //////////////////////////////////////////////////////////////////

export const printSalesReport = function(report, branch, title, more = false) {
  return [
    init +
    em_mode_on +
    alignCenter +
    getTitle(title) +
    em_mode_off +
    newLine +
    newLine +
    dottedLine() +
    newLine +
    newLine +
    getSalesReport(report, more) +
    em_mode_off +
    alignCenter +
    dottedLine() +
    newLine +
    alignLeft +
    getStoreDetails(branch) +
    newLine +
    newLine +
    newLine +
    newLine +
    newLine +
    newLine +
    newLine +
    cutPaper,
  ];
};

function getTitle(title) {
  return title;
}

function getSalesReport(summary, more) {
  let summaryString = '';
  let totalCash = 0;
  let totalCard = 0;
  let totalOrders = 0;
  for (let i = 0; i < summary.vendor_names.length; i++) {
    totalCash += summary.total_cash[i].count || 0;
    totalCard += summary.total_card[i].count || 0;
    totalOrders += summary.total_orders[i].count || 0;
    summaryString += bold_on + getItemRowFull(summary.vendor_names[i].title, '') + bold_off + newLine;
    summaryString += alignLeft;
    if (more) {
      summaryString += getItemRowFull(' Collection Orders', summary.takeaway[i].count) + newLine;
      summaryString += getItemRowFull(' Delivery Orders', summary.delivery[i].count) + newLine;
    }
    summaryString += getItemRowFull(' Total Cash', fromCents(summary.total_cash[i].count) || 0) + newLine;
    summaryString += getItemRowFull(' Total Card', fromCents(summary.total_card[i].count) || 0) + newLine;
  }
  summaryString += dottedLine() + newLine;
  summaryString += bold_on;
  summaryString += getItemRowFull('Total Orders ', totalOrders) + newLine;
  summaryString += getItemRowFull('Total Card ', fromCents(totalCard)) + newLine;
  summaryString += getItemRowFull('Total Cash ', fromCents(totalCash)) + newLine;
  summaryString += bold_off;
  return summaryString;
}

// Store Details
function getStoreDetails(branch) {
  return (
    alignLeft +
    bold_on +
    getItemRowFull('Store Details: ', '') +
    bold_off +
    newLine +
    getItemRowFull(branch.official_title, '') +
    newLine +
    getItemRowFull(`${branch.address}, ${branch.zipcode}`, '') +
    newLine +
    getItemRowFull(`Reg No: ${branch.registration_no}`, '') +
    newLine +
    getItemRowFull(`VAT No: ${branch.vat_no}`, '') +
    newLine
  );
}

function getItemRowFull(itemTitle, itemPrice = '') {
  const price = itemPrice.toString();
  const marginSpace = totalColsPerPage === 48 ? '' : '   ';

  let colsLeft = leftCols;
  if (itemTitle.length > leftCols) {
    colsLeft = !price ? totalColsPerPage - 5 : colsLeft;
    let rightCols = !price ? 5 : price.length;

    const itemTitleLength = itemTitle.length;
    let receipt = '';
    let startFrom = 0;
    let remainingCharacters = itemTitle.length;

    while (startFrom < itemTitleLength) {
      receipt +=
        /* alignLeft + */ marginSpace +
        itemTitle
          .substr(startFrom, colsLeft)
          .padEnd(totalColsPerPage - rightCols, ' ') +
        (startFrom === 0 ? price : '');
      receipt += newLine;
      remainingCharacters =
        remainingCharacters + itemTitleLength - (itemTitleLength + colsLeft);
      startFrom += colsLeft;
    }
    return receipt;
  }
  return (
    marginSpace +
    itemTitle.padEnd(totalColsPerPage - price.length, ' ') + price
  );
}

function maxRound(text) {
  const re = new RegExp(`.{1,${leftCols}}`, 'g');
  const chunk = text.match(re);
  const spaced = chunk.map((i) => `  ${i}`);
  return spaced.join(newLine);
}


function getPrice(price) {
  return parseFloat(price / 100).toFixed(2);
}

function dottedLine() {
  if (totalColsPerPage === 48) {
    return `${alignCenter}------------------------------------------------`;
  }
  return `${alignCenter}------------------------------------------`;
}
