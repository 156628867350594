export const filterDriversById = (drivers = [], id) =>
  drivers.find((driver) => driver.id === id);

export const getOnlineDrivers = (drivers) =>
  drivers.reduce((total, driver) => {
    if (driver.status === 'clocked-in') {
      return total + 1;
    }
    return total;
  }, 0);
