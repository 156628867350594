import {
  call,
  put,
  takeLatest,
  select,
  all,
  takeEvery,
} from 'redux-saga/effects';

import {push} from 'connected-react-router';
import apiUrl from '../../Configs/ApiUrl';
import apiCall from '../../Services/ApiCall';
import {
  CREATE_BOOKING,
  FETCH_BOOKINGS,
  SYNC_BOOKING,
  UPDATE_BOOKING,
} from './constants';
import {
  createBookingError,
  createBookingSuccess,
  fetchBookingsError,
  fetchBookingsSuccess,
  syncBookingError,
  syncBookingSuccess,
  updateBookingError,
  updateBookingSuccess,
} from './actions';
import AuthorizedPostHeaders from '../../Configs/AuthorizedPostHeaders';
import {makeSelectHotel, makeSelectToken} from '../Authentication/selectors';
import printSyncedBooking from '../../Libs/printSyncedBooking';
import printOfflineBooking from '../../Libs/printOfflineBooking';
import {unauthorized} from '../Authentication/actions';
import {
  makeSelectDefaultPrinter,
  makeSelectPrinters,
} from '../Management/selectors';

export function* createBooking(action) {
  const token = yield select(makeSelectToken());
  const printers = yield select(makeSelectPrinters());
  const defaultPrinter = yield select(makeSelectDefaultPrinter());
  const {branch} = yield select(makeSelectHotel());
  const requestUrl = `${apiUrl}/pos/bookings/posGenerateBooking`;
  try {
    const booking = yield call(
      apiCall,
      requestUrl,
      AuthorizedPostHeaders(action.booking, token),
    );
    yield put(createBookingSuccess(action.booking.bookingUnique, booking));
  } catch (e) {
    console.log('err occurred', e);
    if (e.message === 'Unauthorized') {
      yield put(unauthorized());
      yield put(push('/'));
    } else {
      yield put(createBookingError(e.message, action.booking.bookingUnique));
    }
  }
}

export function* updateBooking(action) {
  const token = yield select(makeSelectToken());
  const requestUrl = `${apiUrl}/pos/bookings/posUpdateBooking`;
  try {
    const booking = yield call(
      apiCall,
      requestUrl,
      AuthorizedPostHeaders(action.booking, token),
    );
    yield put(updateBookingSuccess(action.booking.bookingUnique, booking));
  } catch (e) {
    console.log('err occurred in update booking', e);
    if (e.message === 'Unauthorized') {
      yield put(unauthorized());
      yield put(push('/'));
    } else {
      yield put(updateBookingError(e.message, action.booking.bookingUnique));
    }
  }
}

export function* syncBooking(action) {
  const token = yield select(makeSelectToken());
  const requestUrl = `${apiUrl}/pos/bookings/posGenerateBooking`;
  try {
    const booking = yield call(
      apiCall,
      requestUrl,
      AuthorizedPostHeaders(action.booking, token),
    );
    yield put(syncBookingSuccess(action.booking.bookingUnique, booking));
  } catch (e) {
    console.log('err occurred', e);
    if (e.message === 'Unauthorized') {
      yield put(unauthorized());
      yield put(push('/'));
    } else {
      yield put(syncBookingError(e.message));
    }
  }
}

export function* fetchBookings() {
  const token = yield select(makeSelectToken());
  const {branch} = yield select(makeSelectHotel());
  const requestUrl = `${apiUrl}/pos/bookings/posHistory`;
  if (!branch?.id) {
    return;
  }
  try {
    const bookings = yield call(
      apiCall,
      requestUrl,
      AuthorizedPostHeaders({branch_id: branch.id}, token),
    );
    // console.log('response', bookings);
    yield put(fetchBookingsSuccess(bookings || []));
  } catch (e) {
    console.log('err occurred', e);
    if (e.message === 'Unauthorized') {
      yield put(unauthorized());
      yield put(push('/'));
    } else {
      yield put(fetchBookingsError(e.message));
    }
  }
}

function* updateBookingSaga() {
  yield takeLatest(UPDATE_BOOKING, updateBooking);
}

function* createBookingSaga() {
  yield takeLatest(CREATE_BOOKING, createBooking);
}

function* syncBookingSaga() {
  yield takeEvery(SYNC_BOOKING, syncBooking);
}

function* fetchBookingsSaga() {
  yield takeLatest(FETCH_BOOKINGS, fetchBookings);
}

export default function* bookingsSaga() {
  yield all([
    fetchBookingsSaga(),
    createBookingSaga(),
    syncBookingSaga(),
    updateBookingSaga(),
  ]);
}
