import React from 'react';
import Row from 'reactstrap/es/Row';
import Loader from './Loader';

function Addresses({addresses, setAddress, state, selectedAddress, mode}) {
  if (state === 'error') {
    return (
      <Row className="justify-content-center align-items-center p-3">
        <p className="alert alert-danger">No Addresses Found</p>
      </Row>
    );
  }
  if (mode === 'addresses') {
    return (
      addresses.length > 0 && (
        <div className="addresses">
          {state === 'loading' ? (
            <Loader />
          ) : (
            <>
              <h3 className="section-header">SELECT ADDRESS</h3>
              {addresses.map((address) => {
                const adrs = address
                  .split(', ')
                  .filter((a) => !!a)
                  .join(', ');
                return (
                  <div
                    key={adrs}
                    className={`address ${
                      selectedAddress === adrs ? 'selected' : ''
                    }`}
                    onClick={() => setAddress(adrs)}
                  >
                    <h4>{adrs}</h4>
                  </div>
                );
              })}
            </>
          )}
        </div>
      )
    );
  }
  return null;
}

export default Addresses;
