import React from 'react';
import Price from '../../Components/Price';
import {priceByMode} from '../../Libs/prices';

const Addon = ({addon, free = false, mode}) => {
  return (
    <div className="addon">
      <div className="left">
        <p className="extras-text">
          {addon.quantity}x &nbsp;{addon.title} &nbsp;
          <strong>
            {free ? (
              '(free)'
            ) : (
              <Price className="extras-text">
                {priceByMode(
                  {
                    price_takeaway: addon.price_takeaway,
                    price_delivery: addon.price_delivery,
                  },
                  mode,
                )}
              </Price>
            )}
          </strong>
        </p>
      </div>
    </div>
  );
};

export default Addon;
