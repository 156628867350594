import React from 'react';
import {Link} from 'react-router-dom';

import {useSelector} from 'react-redux';
import {makeSelectUserPermissions} from '../Pages/Authentication/selectors';

export default function FooterNav() {
  const permissions = useSelector(makeSelectUserPermissions());
  return (
    <div className="footer-menu">
      <div className="parent-menu">
        <div className="menu-item">
          <Link to="/">
            <i className="fas fa-home" /> HOME
          </Link>
        </div>
        {permissions.customers_access === true && (
          <div className="menu-item">
            <Link to="/customers">
              <i className="fas fa-user-circle" /> CUSTOMER NOTES
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
