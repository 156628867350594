import React from 'react';
import Master from '../Master';
import ProductList from '../../Components/ProductList';

const Dashboard = () => {
  return (
    <Master>
      <ProductList />
    </Master>
  );
};

export default Dashboard;
