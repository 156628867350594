import React, {useEffect, useState} from 'react';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';
import {useSelector} from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import qz from 'qz-tray';
import {makeSelectHotel, makeSelectToken} from '../Authentication/selectors';
import apiCall from '../../Services/ApiCall';
import apiUrl from '../../Configs/ApiUrl';
import AuthorizedPostHeaders from '../../Configs/AuthorizedPostHeaders';
import {makeSelectDrivers} from '../Drivers/selectors';
import {filterDriversById} from '../../Libs/users';
import QzTray from '../../Configs/QZTray';
import {printDriverSalesReport} from '../../Libs/printDriverSalesReport';
import {makeSelectDefaultPrinter} from '../Management/selectors';
import More from './More';
import Less from './Less';

function Sales() {
  const {id} = useParams();
  const {branch} = useSelector(makeSelectHotel());
  const drivers = useSelector(makeSelectDrivers());
  const defaultPrinter = useSelector(makeSelectDefaultPrinter());
  const token = useSelector(makeSelectToken());
  const [totalHours, setTotalHours] = useState(null);
  const [more, setMore] = useState(true);
  const [state, setState] = useState('initial');
  const [status, setStatus] = useState('');
  const [summary, setSummary] = useState({});

  useEffect(() => {
    setState('loading');
    setStatus('');
    apiCall(
      `${apiUrl}/drivers/dailySummaryByDriverID`,
      AuthorizedPostHeaders(
        {
          driver_id: id,
          branch_id: branch.id,
        },
        token,
      ),
    )
      .then(({task_summary, total_hours}) => {
        setState('success');
        // console.log('response', {task_summary, total_hours});
        setTotalHours(total_hours);
        setSummary(task_summary);
      })
      .catch((e) => {
        setState('error');
        setStatus(e.message);
        console.log('error', e);
      });
  }, []);

  async function handlePrintSalesReport() {
    try {
      const driver = filterDriversById(drivers, id);
      const printString = printDriverSalesReport(summary, driver, more);
      const config = await QzTray(defaultPrinter);
      await qz.print(config, printString);
      await qz.websocket.disconnect();
    } catch (e) {
      console.log('offline print error', e);
    }
  }

  const driver = filterDriversById(drivers, id);
  return (
    <div className="sales">
      <Link to="/members" className="go-back">
        Go Back
      </Link>
      <Row>
        <Col>
          <h2 className="title">{driver?.name}</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="header">DRIVER JOBS SUMMARY</h3>
        </Col>
        <Col>
          <button
            className="show-details"
            type="button"
            onClick={() => setMore(!more)}
          >
            {more ? 'SHOW LESS' : 'SHOW MORE'}
          </button>
        </Col>
      </Row>
      {totalHours != null && (
        <Row>
          <Col>
            <h5>
              Active Hours: <strong>{totalHours}</strong>
            </h5>
          </Col>
        </Row>
      )}
      <Row>
        {more ? <More summary={summary} /> : <Less summary={summary} />}
      </Row>
      <Row>
        <Col className="print-btn">
          <button
            className="print"
            type="button"
            onClick={handlePrintSalesReport}
          >
            Print
          </button>
        </Col>
      </Row>
    </div>
  );
}

export default Sales;
