import React from 'react';
import {Spinner} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {makeSelectMenu, makeSelectSelectedMenu} from '../../Selectors/Menu';
import {setSingleMenu} from '../../Actions/Menu';
import history from '../../Libs/history';

export default function TopNav() {
  const dispatch = useDispatch();

  const menu = useSelector(makeSelectMenu());
  const selectedMenu = useSelector(makeSelectSelectedMenu());

  const setMenuHandler = (submenu) => {
    dispatch(setSingleMenu(submenu));
    history.push('/dashboard');
  };

  const renderMenus = () => {
    return menu.map((submenu) => {
      let classActive = '';
      if (submenu.id === selectedMenu.id) {
        classActive = 'active-menu';
      } else {
        classActive = '';
      }
      return (
        <div
          key={submenu.id}
          className={`nav-custom ${classActive}`}
          onClick={() => setMenuHandler(submenu)}
        >
          {submenu.title}
        </div>
      );
    });
  };

  return (
    <div className="topNav">
      <div className="nav-scroll">
        {menu.length > 0 ? (
          renderMenus()
        ) : (
          <div className="nav-custom">
            <Spinner color="light" />
          </div>
        )}
      </div>
    </div>
  );
}
