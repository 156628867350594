/* eslint-disable */
const initialForm = {
  title: '',
  type: '',
  amount: '',
};

export default function validateDiscount(inputs = initialForm) {
  if (inputs.title.trim().length === 0) {
    return 'Title field is required';
  }
  if (inputs.type.trim().length === 0) {
    return 'Type is required.';
  }
  if (!inputs.amount) {
    return 'Amount is required';
  }
  return 'validated';
}
