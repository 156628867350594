/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */

import findIndex from 'lodash/findIndex';
import {checkIfObjectEmpty} from './object';

export const productPrice = (
  {price_takeaway, price_delivery, quantity, tiers, extras = [], hold = []},
  mode,
) => {
  const pPrices = getItemPrice({price_takeaway, price_delivery}, tiers[0]);
  const pPrice = priceByMode(pPrices, mode);
  const tierPrice = getTierPrices(tiers, mode);
  const extrasPrice = getExtrasPrices(extras, hold);
  return (pPrice + tierPrice + extrasPrice) * quantity;
};

export const getExtrasPrices = (extras, hold) =>
  extras.reduce(
    (total, extra, i) => (hold.length > i ? 0 : extra.price + total),
    0,
  );

export const getTierPrices = (tiers, mode) =>
  tiers.reduce((total, tier) => getTierPrice(tier, mode) + total, 0);

export const onlyPrice = (product) => {
  return product.price;
};

export const onlyPriceWithDiscount = ({price_takeaway, price_delivery}, mode) =>
  priceByMode({price_takeaway, price_delivery}, mode);

export const productPriceWithoutQuantity = (
  {price_takeaway, price_delivery, tiers},
  mode,
) => {
  const pPrices = getItemPrice({price_takeaway, price_delivery}, tiers[0]);
  const pPrice = priceByMode(pPrices, mode);
  return pPrice + getTierPrices(tiers);
};

export const cartTotal = (products, mode) => {
  return products.reduce(
    (total, product) => productPrice(product, mode) + total,
    0,
  );
};

export const getItemPrice = (price, size) => {
  if (size) {
    if (size.type === 'Size') {
      return size.selected.price;
    }
    return price;
  }
  return price;
};
export const getSyncedItemPrice = (price_takeaway, price_delivery, size) => {
  if (size) {
    if (size.type === 'Size') {
      return {
        price_takeaway: size.booking_tier_sizes.price_takeaway,
        price_delivery: size.booking_tier_sizes.price_delivery,
      };
    }
    return {price_takeaway, price_delivery};
  }
  return {price_takeaway, price_delivery};
};

export const getTierPrice = (tier, mode) => {
  if (!tier) {
    return 0;
  }
  if (tier.type === 'Option') {
    return getOptionPrice(tier.selected, mode);
  }
  if (tier.type === 'Addon') {
    return getAddonsPrice(tier.selected, mode);
  }
  if (tier.type === 'Product') {
    return getTierProductPrice(tier.selected, mode);
  }
  return 0;
};

export const getTierProductPrice = (product, mode) => {
  return priceByMode(product.price, mode);
};

export const getSizePrice = (size) => {
  return size.price;
};

export const getOptionPrice = (option, mode) => {
  return priceByMode(option.price, mode);
};

export const getAddonsPrice = (addons, mode) =>
  addons.paid?.reduce(
    (total, addon) => getAddonPrice(addon, mode) + total,
    0,
  ) || 0;

export const getAddonPrice = (addon, mode) =>
  priceByMode(addon.price, mode) * addon.quantity;

export const priceBySize = (item, {selected: {id}}) => {
  const index = findIndex(item.price, (price) => id === price.size_id);
  if (index === -1) {
    return 0;
  }
  return item.price[index];
};

export const getDeliveryCharges = (operation, mode, total) => {
  if (checkIfObjectEmpty(operation)) {
    return 0;
  }
  if (mode === 'Delivery') {
    if (operation.delivery_type === 'cap') {
      if (total >= operation.delivery_cap) {
        return parseInt(operation.delivery_charges, 10);
      }
      return 0;
    }
    return parseInt(operation.delivery_charges, 10);
  }
  if (mode === 'Takeaway') {
    return 0;
  }
  return 0;
};

export const getVoucherPrice = (voucher, total) => {
  let voucherAmount = 0;
  if (checkIfObjectEmpty(voucher)) {
    voucherAmount = 0;
  }
  if (voucher.type === 'fixed') {
    voucherAmount = voucher.amount;
  }
  if (voucher.type === 'percentage') {
    voucherAmount = total * (voucher.amount / 100);
  }
  if (voucher.type === 'cap') {
    if (total >= voucher.total) {
      voucherAmount = voucher.amount;
    }
    voucherAmount = 0;
  }
  if (voucherAmount > total) {
    return 0;
  }
  return voucherAmount;
};

export const getProperPrice = (item, s) => {
  if (s) {
    const size = s;
    if (size.type === 'Size') {
      return priceBySize(item, size);
    }
    return item?.price[0];
  }
  return item.price[0];
};

export const getDiscountedPrice = (discount, prices, mode) => {
  return priceByMode(prices, mode);
};

export const getDiscountPrice = (discount, price) => {
  if (!discount || checkIfObjectEmpty(discount)) {
    return 0;
  }
  if (discount.type === 'fixed') {
    return parseInt(discount.amount, 10);
  }
  return parseInt((price * discount.amount) / 100, 10);
};

export const calculateRemaining = (total, payments) => {
  const paymentsTotal = payments.reduce((t, payment) => payment.amount + t, 0);
  return parseFloat((total - paymentsTotal) / 100).toFixed(2);
};

export const toCents = (amount) => Math.round(amount * 100, 10);

export const calculateVoucherDiscount = (
  subTotal,
  total,
  deliveryCharges = 0,
) => parseInt(subTotal, 10) + deliveryCharges - parseInt(total, 10);

export const priceByMode = ({price_takeaway, price_delivery}, mode) => {
  if (mode === 'Takeaway') {
    return price_takeaway;
  }
  if (mode === 'Delivery') {
    return price_delivery;
  }
  throw new Error('Invalid Checkout mode');
  // return 0;
};

export const fromCents = (price) => {
  if (price) {
    return parseFloat(price / 100).toFixed(2);
  }
  return 0;
};

export const getMinOrderAmount = (branch, mode) => {
  if (mode === 'Takeaway') {
    return branch.min_takeaway_order;
  }
  if (mode === 'Delivery') {
    return branch.min_delivery_order;
  }
  return 0;
};
