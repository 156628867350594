import React from 'react';
import {useSelector} from 'react-redux';
import {getProperPrice, priceByMode} from '../../Libs/prices';
import Price from '../../Components/Price';
import {makeSelectCheckoutMode} from '../../Components/Cart/selectors';

function Options({options, setTier, tierDetails, selectedSize}) {
  const mode = useSelector(makeSelectCheckoutMode());
  return options.map((option) => {
    const prices = getProperPrice(option, selectedSize);
    return (
      <div
        key={option.id}
        className="pl-2"
        onClick={() => {
          setTier({
            ...tierDetails,
            selected: {
              ...option,
              price: prices,
              quantity: 1,
            },
          });
        }}
      >
        <div className="prod-list">
          <div className="prod-title">
            <h6>{option.title}</h6>
          </div>
          <div className="prod-price">
            <h6>
              <Price>{priceByMode(prices, mode)}</Price>
            </h6>
          </div>
        </div>
      </div>
    );
  });
}

export default Options;
