import React from 'react';
import {priceByMode} from '../../Libs/prices';
import Price from '../../Components/Price';

const Size = ({size, title, mode}) => (
  <div className="size">
    <div className="left">
      <p className="basket-title">{title}: &nbsp;</p>
      <p className="extras-title">
        <Price>
          {priceByMode(
            {
              price_takeaway: size.price_takeaway,
              price_delivery: size.price_delivery,
            },
            mode,
          )}
        </Price>
      </p>
    </div>
  </div>
);

export default Size;
