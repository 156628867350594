export const OPEN_TILL = 'Favorite/Till/OPEN_TILL';

export const CLOSE_TILL = 'Favorite/Till/CLOSE_TILL';

export const FETCH_TILL = 'Favorite/Till/FETCH_TILL';

export const FETCH_TILL_SUCCESS = 'Favorite/Till/FETCH_TILL_SUCCESS';

export const FETCH_TILL_ERROR = 'Favorite/Till/FETCH_TILL_ERROR';

export const CLEAR_TILL = 'Favorite/Till/CLEAR_TILL';
