import React, {useState} from 'react';
import {Container, Row, Col, Form, FormGroup, Alert} from 'reactstrap';
import {useHistory, Redirect} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Time from '../../Components/Time';
import validateLogin from '../../Libs/validateLogin';
import apiCall from '../../Services/ApiCall';
import apiUrl from '../../Configs/ApiUrl';
import PostHeaders from '../../Configs/PostHeaders';
import Loadable from '../../Components/Loadable';
import {login, unauthorized} from './actions';
import TextInput from '../../Components/TextInput';
import {makeSelectToken} from './selectors';

function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const t = useSelector(makeSelectToken());
  const [state, setState] = useState('initial');
  const [status, setStatus] = useState('');
  const [email, setEmail] = useState('izmirbargrill@izmir.com');
  const [password, setPassword] = useState('password');
  const handleLogin = (evt) => {
    evt.preventDefault();
    setState('loading');
    setStatus('');
    const credentials = {email, password};
    const validate = validateLogin(credentials);
    if (validate === 'validated') {
      apiCall(`${apiUrl}/pos/login`, PostHeaders(credentials))
        .then(({token, user, hotel}) => {
          setState('success');
          dispatch(login(token, user, hotel));
          history.push('/clock-in');
        })
        .catch((e) => {
          console.log('login error', e);

          if (e.message === 'Unauthorized') {
            dispatch(unauthorized());
            history.push('/');
          } else {
            setStatus(e.message);
            setState('error');
          }
        });
    }
  };

  if (t.length > 0) {
    return <Redirect to="/clock-in" />;
  }
  return (
    <div className="main-div mt-5">
      <Container className="App">
        <div className="auth-inner">
          <Row>
            <Col>
              <h1 className="text-center main-title m-0">
                IZMIR BAR AND GRILL
              </h1>
              <p className="text-center m-0">
                <small className="small-title">Enter Your User Details</small>
              </p>
            </Col>
          </Row>

          <Form className="form" onSubmit={handleLogin}>
            <div className="num">
              <Row>
                <Col md="12">
                  <FormGroup>
                    <TextInput
                      inputClassName="input-class"
                      type="text"
                      name="email"
                      value={email}
                      onChange={(value) => setEmail(value)}
                      placeholder="Enter Password"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <TextInput
                      inputClassName="input-class"
                      type="password"
                      name="password"
                      value={password}
                      onChange={(value) => setPassword(value)}
                      placeholder="Enter Password"
                    />
                  </FormGroup>
                </Col>
              </Row>
              {state === 'error' ? (
                <Row>
                  <Col md="12" sm="12" xs="12">
                    <Alert color="danger">{status}</Alert>
                  </Col>
                </Row>
              ) : null}
              <Row>
                <Col md="12" sm="12" xs="12">
                  <button
                    onClick={handleLogin}
                    className="btn btn-primary btn-sm input-signin"
                    type="submit"
                  >
                    <Loadable color="white" active={state === 'loading'}>
                      LOGIN
                    </Loadable>
                  </button>
                </Col>
              </Row>
              <Time />
            </div>
          </Form>
        </div>
      </Container>
    </div>
  );
}

export default Login;
