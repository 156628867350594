import React from 'react';
import {Col} from 'reactstrap';
import {useSelector} from 'react-redux';
import {getProperPrice, priceByMode} from '../../Libs/prices';
import Price from '../../Components/Price';
import {makeSelectCheckoutMode} from '../../Components/Cart/selectors';

function TierProducts({products, setTier, tierDetails, selectedSize}) {
  const mode = useSelector(makeSelectCheckoutMode());
  return products.map((p) => {
    const prices = getProperPrice(p, selectedSize);
    return (
      <Col
        key={p.id}
        md="3"
        className="paddingLeft0"
        onClick={() =>
          setTier({
            ...tierDetails,
            selected: {
              ...p,
              price: prices,
              quantity: 1,
            },
          })
        }
      >
        <div className="prod-list">
          <div className="prod-title">
            <h6>{p.title}</h6>
          </div>
          <div className="prod-price">
            <h6>
              <Price>{priceByMode(prices, mode)}</Price>
            </h6>
          </div>
        </div>
      </Col>
    );
  });
}

export default TierProducts;
