export const filterMenu = (m, q) => {
  if (q) {
    return m.filter((submenu) => {
      const sub = {
        ...submenu,
        products: submenu.products.filter((product) => {
          return product.title.toLowerCase().includes(q.toLowerCase());
        }),
      };
      console.log('sub', sub);
      return sub.products.length > 0;
    });
  }
  return m;
};

export const searchMenu = (m, q) => {
  if (q) {
    const menu = [];
    m.forEach((submenu) => {
      const sub = {
        ...submenu,
        products: submenu.products.filter((product) => {
          return product.title.toLowerCase().includes(q.toLowerCase());
        }),
      };
      if (sub.products.length > 0) {
        menu.push(sub);
      }
    });
    return menu;
  }
  return m;
};

export const getProductById = (m, id) => {
  let product = {};
  for (let i = 0; i < m.length; i += 1) {
    if (m[i].products) {
      for (let j = 0; j < m[i].products.length; j += 1) {
        if (m[i].products[j].id === id) {
          product = m[i].products[j];
        }
      }
    }
  }
  return product;
};
