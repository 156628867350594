import React, {useEffect, useState} from 'react';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';
import {useSelector} from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import qz from 'qz-tray';
import {makeSelectHotel, makeSelectToken} from '../Authentication/selectors';
import apiCall from '../../Services/ApiCall';
import apiUrl from '../../Configs/ApiUrl';
import {checkIfObjectEmpty} from '../../Libs/object';
import Price from '../../Components/Price';
import {makeSelectDrivers} from '../Drivers/selectors';
import {filterDriversById} from '../../Libs/users';
import Loader from '../../Components/Loader';
import AuthorizedPostHeaders from '../../Configs/AuthorizedPostHeaders';
import QzTray from '../../Configs/QZTray';
import {printDriverLogReport} from '../../Libs/printDriverLogReport';
import {makeSelectDefaultPrinter} from '../Management/selectors';

function Log() {
  const {id} = useParams();
  const {branch} = useSelector(makeSelectHotel());
  const drivers = useSelector(makeSelectDrivers());
  const defaultPrinter = useSelector(makeSelectDefaultPrinter());
  const token = useSelector(makeSelectToken());
  const [state, setState] = useState('initial');
  const [status, setStatus] = useState('');
  const [summary, setSummary] = useState({});

  const driver = filterDriversById(drivers, id);

  useEffect(() => {
    setState('loading');
    setStatus('');
    apiCall(
      `${apiUrl}/drivers/clockHistoryByDriverID`,
      AuthorizedPostHeaders(
        {
          branch_id: branch.id,
          driver_id: id,
        },
        token,
      ),
    )
      .then((response) => {
        setState('success');
        console.log('response', response);
        setSummary(response);
        // setSummary(response);
      })
      .catch((e) => {
        setState('error');
        setStatus(e.message);
        console.log('error', e);
      });
  }, []);

  async function handlePrintDriverLog() {
    try {
      const printString = printDriverLogReport(summary, driver);
      const config = await QzTray(defaultPrinter);
      await qz.print(config, printString);
      await qz.websocket.disconnect();
    } catch (e) {
      console.log('offline print error', e);
    }
  }
  return (
    <div className="sales">
      <Link to="/" className="go-back">
        Go Back
      </Link>
      <Row>
        <Col>
          <h2 className="title">{driver?.name}</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="header">DRIVER LOG</h3>
        </Col>
      </Row>
      <Row>
        <Col className="report">
          {checkIfObjectEmpty(summary) ? (
            <div className="loading-wrapper">
              <Loader color="black" />
            </div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Time In</th>
                  <th>Time Out</th>
                  <th>Total Hours</th>
                  <th>Total Pay</th>
                </tr>
              </thead>
              <tbody>
                {summary.map((session) => (
                  <tr key={session.date + session.clock_in}>
                    <td>{session.date}</td>
                    <td>{session.clock_in}</td>
                    <td>{session.clock_out}</td>
                    <td>{session.total_hours}</td>
                    <td>
                      <Price>{session.total_pay || 0}</Price>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center align-items-center mt-4">
          <button
            className="print"
            type="button"
            onClick={handlePrintDriverLog}
          >
            Print
          </button>
        </Col>
      </Row>
    </div>
  );
}

export default Log;
