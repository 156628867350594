import React from 'react';
import Col from 'reactstrap/es/Col';
import Price from '../../Components/Price';

function More({summary}) {
  let totalTakeaway = 0;
  let totalDelivery = 0;
  let totalPayOnCollection = 0;
  let totalPayOnDelivery = 0;
  let totalCashOrders = 0;
  let totalCardOrders = 0;
  let totalMulti = 0;
  let totalOrders = 0;
  let totalCash = 0;
  let totalCard = 0;
  let totalSales = 0;
  return (
    <Col className="report">
      <table>
        <thead>
          <tr>
            <th>Store Name</th>
            <th>Collection</th>
            <th>Delivery</th>
            <th>Pay On Collection</th>
            <th>Pay On Delivery</th>
            <th>Cash Order</th>
            <th>Card Order</th>
            <th>Mix Order</th>
            <th>Total Orders</th>
            <th>Total Cash</th>
            <th>Total Card</th>
            <th>Total Sales</th>
          </tr>
        </thead>
        <tbody>
          {summary.vendor_names.map((vendor, index) => {
            totalTakeaway += summary.takeaway[index].count;
            totalDelivery += summary.delivery[index].count;
            totalPayOnCollection +=
              summary.pay_on_collection_orders[index].count;
            totalPayOnDelivery += summary.pay_on_delivery_orders[index].count;
            totalCashOrders += summary.cash_orders[index].count;
            totalCardOrders += summary.card_orders[index].count;
            totalMulti += summary.multi_payment_orders[index].count;
            totalOrders += summary.total_orders[index].count;
            totalCash += summary.total_cash[index].count;
            totalCard += summary.total_card[index].count;
            totalSales += summary.total_sales[index].count;
            return (
              <tr key={vendor.id}>
                <td>{vendor.title}</td>
                <td>{summary.takeaway[index].count}</td>
                <td>{summary.delivery[index].count}</td>
                <td>{summary.pay_on_collection_orders[index].count}</td>
                <td>{summary.pay_on_delivery_orders[index].count}</td>
                <td>{summary.cash_orders[index].count}</td>
                <td>{summary.card_orders[index].count}</td>
                <td>{summary.multi_payment_orders[index].count}</td>
                <td>{summary.total_orders[index].count}</td>
                <td>
                  <Price>{summary.total_cash[index].count}</Price>
                </td>
                <td>
                  <Price>{summary.total_card[index].count}</Price>
                </td>
                <td>
                  <Price>{summary.total_sales[index].count}</Price>
                </td>
              </tr>
            );
          })}
          <tr className="grand-total">
            <td>Total</td>
            <td>{totalTakeaway}</td>
            <td>{totalDelivery}</td>
            <td>{totalPayOnCollection}</td>
            <td>{totalPayOnDelivery}</td>
            <td>{totalCashOrders}</td>
            <td>{totalCardOrders}</td>
            <td>{totalMulti}</td>
            <td>{totalOrders}</td>
            <td>
              <Price>{totalCash}</Price>
            </td>
            <td>
              <Price>{totalCard}</Price>
            </td>
            <td>
              <Price>{totalSales}</Price>
            </td>
          </tr>
        </tbody>
      </table>
    </Col>
  );
}

export default More;
