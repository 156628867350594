import {
  ADD_BOOKING,
  CREATE_BOOKING,
  CREATE_BOOKING_ERROR,
  CREATE_BOOKING_SUCCESS,
  DELETE_LOCAL_BOOKING,
  DELETE_SYNCED_BOOKING,
  EDIT_BOOKING,
  FETCH_BOOKINGS,
  FETCH_BOOKINGS_ERROR,
  FETCH_BOOKINGS_SUCCESS,
  SYNC_BOOKING,
  SYNC_BOOKING_ERROR,
  SYNC_BOOKING_SUCCESS,
  TOGGLE_ONLINE_STATUS,
  UPDATE_BOOKING_STATUS,
  UPDATE_BOOKING,
  UPDATE_BOOKING_ERROR,
  UPDATE_BOOKING_SUCCESS,
} from './constants';

export const addBooking = (booking) => ({
  type: ADD_BOOKING,
  booking,
});

export const syncBooking = (booking) => ({
  type: SYNC_BOOKING,
  booking,
});

export const syncBookingSuccess = (bookingUnique, booking) => ({
  type: SYNC_BOOKING_SUCCESS,
  bookingUnique,
  booking,
});

export const syncBookingError = (status) => ({
  type: SYNC_BOOKING_ERROR,
  status,
});

export const createBooking = (booking) => ({
  type: CREATE_BOOKING,
  booking,
});

export const createBookingSuccess = (bookingUnique, booking) => ({
  type: CREATE_BOOKING_SUCCESS,
  bookingUnique,
  booking,
});

export const createBookingError = (status, bookingUnique) => ({
  type: CREATE_BOOKING_ERROR,
  status,
  bookingUnique,
});

export const fetchBookings = () => ({
  type: FETCH_BOOKINGS,
});

export const fetchBookingsSuccess = (bookings) => ({
  type: FETCH_BOOKINGS_SUCCESS,
  bookings,
});

export const fetchBookingsError = (status) => ({
  type: FETCH_BOOKINGS_ERROR,
  status,
});

export const toggleOnlineStatus = (status) => ({
  type: TOGGLE_ONLINE_STATUS,
  status,
});

export const deleteLocalBooking = (unique) => ({
  type: DELETE_LOCAL_BOOKING,
  unique,
});

export const deleteSyncedBooking = (bookingId) => ({
  type: DELETE_SYNCED_BOOKING,
  bookingId,
});

export const updateBookingStatus = (unique, status) => ({
  type: UPDATE_BOOKING_STATUS,
  unique,
  status,
});

export const editBooking = (
  products,
  payments,
  discount,
  notes,
  customer,
  checkoutMode,
  bookingId,
) => ({
  type: EDIT_BOOKING,
  products,
  payments,
  discount,
  notes,
  customer,
  checkoutMode,
  bookingId,
});

export const updateBooking = (booking) => ({
  type: UPDATE_BOOKING,
  booking,
});

export const updateBookingSuccess = (bookingUnique, booking) => ({
  type: UPDATE_BOOKING_SUCCESS,
  booking,
  bookingUnique,
});

export const updateBookingError = (bookingUnique, status) => ({
  type: UPDATE_BOOKING_ERROR,
  status,
  bookingUnique,
});
