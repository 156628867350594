import {fromJS} from 'immutable';

import {
  SET_CHANGE,
  REMOVE_PAYMENT,
  SET_DISPLAY_VALUE,
  SET_PAYMENT,
  SPLIT_ORDER,
  SET_PAYMENTS,
  SET_SPLITING,
} from './constants';
import {CLEAR_CART} from '../../Components/Cart/constants';
import {EDIT_BOOKING} from '../Bookings/constants';

const initialState = fromJS({
  change: 0,
  displayValue: '0',
  payments: [],
  splitOrder: false,
  spliting: false,
});

export default function checkoutReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CHANGE:
      return state.set('change', action.change);
    case SET_PAYMENT:
      return state
        .update('payments', (payments) => payments.push(fromJS(action.payment)))
        .set('change', action.change);
    case REMOVE_PAYMENT:
      return state.deleteIn(['payments', action.index]);
    case SET_PAYMENTS:
      return state
        .set('payments', fromJS(action.payments))
        .set('change', action.change);
    case SET_DISPLAY_VALUE:
      return state.set('displayValue', action.value);
    case SPLIT_ORDER:
      return state
        .set('splitOrder', action.status)
        .set('spliting', action.status);
    case SET_SPLITING:
      return state.set('spliting', action.status);
    case EDIT_BOOKING:
      return state.set('payments', fromJS(action.payments));
    case CLEAR_CART:
      return initialState;
    default:
      return state;
  }
}
