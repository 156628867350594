import {LOGOUT, LOGIN, CLOCKIN, CLOCKOUT, UNAUTHORIZED} from './constants';

export const login = (token, user, hotel) => ({
  type: LOGIN,
  token,
  user,
  hotel,
});

export const logout = () => ({
  type: LOGOUT,
});

export const clockIn = (user, hotel) => ({
  type: CLOCKIN,
  user,
  hotel,
});

export const clockOut = () => ({
  type: CLOCKOUT,
});

export const unauthorized = () => ({
  type: UNAUTHORIZED,
});
