import {all, call, put, takeLatest, fork, select} from 'redux-saga/effects';
import apiUrl from '../Configs/ApiUrl';

import {FETCH_MENU} from '../Constants';
import {fetchMenuSuccess, fetchMenuError, setSingleMenu} from '../Actions/Menu';
import apiCall from '../Services/ApiCall';
import GetHeaders from '../Configs/GetHeaders';
import {makeSelectHotel} from '../Pages/Authentication/selectors';

function* fetchMenu() {
  const {branch} = yield select(makeSelectHotel());
  const url = `${apiUrl}/posMenus/byBranchSlugWithProducts/${branch.slug}`;
  try {
    const menu = yield call(apiCall, url, GetHeaders);
    yield put(fetchMenuSuccess(menu || []));
    yield put(setSingleMenu(menu[0] || {id: null, products: []}));
  } catch (error) {
    yield put(fetchMenuError(error));
  }
}

export function* fetchMenuDetails() {
  yield takeLatest(FETCH_MENU, fetchMenu);
}

export default function* rootSaga() {
  yield all([fork(fetchMenuDetails)]);
}
