import {
  OPEN_TILL,
  CLOSE_TILL,
  FETCH_TILL,
  FETCH_TILL_SUCCESS,
  FETCH_TILL_ERROR,
  CLEAR_TILL,
} from './constants';

export const openTill = (total, time) => ({
  type: OPEN_TILL,
  total,
  time,
});

export const closeTill = (total, time) => ({
  type: CLOSE_TILL,
  total,
  time,
});

export const fetchTill = () => ({
  type: FETCH_TILL,
});

export const fetchTillSuccess = (till) => ({
  type: FETCH_TILL_SUCCESS,
  till,
});

export const fetchTillError = (status) => ({
  type: FETCH_TILL_ERROR,
  status,
});

export const clearTill = () => ({
  type: CLEAR_TILL,
});
