import {createSelector} from 'reselect';

export const selectCheckout = (state) => state.get('checkout');

export const makeSelectPayments = () =>
  createSelector(selectCheckout, (CheckoutState) =>
    CheckoutState.get('payments').toJS(),
  );

export const makeSelectSplitOrder = () =>
  createSelector(selectCheckout, (CheckoutState) =>
    CheckoutState.get('splitOrder'),
  );

export const makeSelectDisplayValue = () =>
  createSelector(selectCheckout, (CheckoutState) =>
    CheckoutState.get('displayValue'),
  );

export const makeSelectChange = () =>
  createSelector(selectCheckout, (CheckoutState) =>
    CheckoutState.get('change'),
  );

export const makeSelectSpliting = () =>
  createSelector(selectCheckout, (CheckoutState) =>
    CheckoutState.get('spliting'),
  );
