import {
  ADD_PRINTER,
  REMOVE_PRINTER,
  UPDATE_PRINTER,
  GET_PRINTERS,
  GET_PRINTERS_ERROR,
  GET_PRINTERS_SUCCESS,
  UPDATE_PRINTER_SUCCESS,
  UPDATE_PRINTER_ERROR,
  ADD_PINTER_SUCCESS,
  ADD_PINTER_ERROR,
  REMOVE_PRINTER_SUCCESS,
  REMOVE_PRINTER_ERROR,
} from './constants';

export const addPrinter = (printer, isDefault) => ({
  type: ADD_PRINTER,
  printer,
  isDefault,
});

export const addPrinterSuccess = (printer, isDefault) => ({
  type: ADD_PINTER_SUCCESS,
  printer,
  isDefault,
});

export const addPrinterError = (status) => ({
  type: ADD_PINTER_ERROR,
  status,
});

export const removePrinter = (printerId, index) => ({
  type: REMOVE_PRINTER,
  printerId,
  index,
});

export const removePrinterSuccess = (index) => ({
  type: REMOVE_PRINTER_SUCCESS,
  index,
});

export const removePrinterError = (status) => ({
  type: REMOVE_PRINTER_ERROR,
  status,
});

export const updatePrinter = (printer, index, isDefault) => ({
  type: UPDATE_PRINTER,
  printer,
  index,
  isDefault,
});

export const updatePrinterSuccess = (printer, index, isDefault) => ({
  type: UPDATE_PRINTER_SUCCESS,
  index,
  printer,
  isDefault,
});

export const updatePrinterError = (status) => ({
  type: UPDATE_PRINTER_ERROR,
  status,
});

export const getPrinters = () => ({
  type: GET_PRINTERS,
});

export const getPrintersSuccess = (defaultPrinter, printers) => ({
  type: GET_PRINTERS_SUCCESS,
  defaultPrinter,
  printers,
});

export const getPrintersError = (status) => ({
  type: GET_PRINTERS_ERROR,
  status,
});
