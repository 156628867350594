import React from 'react';
import {v4 as uid} from 'uuid';
import {Row, Col, ListGroup, ListGroupItem, Button} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory, Redirect} from 'react-router-dom';
import Price from '../Price';
import CheckoutMode from '../CheckoutMode';
import {
  cartTotal,
  getDeliveryCharges,
  getDiscountPrice,
  getMinOrderAmount,
  priceByMode,
  productPrice,
} from '../../Libs/prices';
import {
  makeSelectCheckoutMode,
  makeSelectCustomer,
  makeSelectDiscount,
  makeSelectOperation,
  makeSelectProducts,
  makeSelectSelectedIndex,
} from './selectors';
import {checkIfObjectEmpty} from '../../Libs/object';
import {
  clearCart,
  decreaseQuantity,
  increaseQuantity,
  removeItem,
  selectItem,
} from './actions';
import {
  makeSelectClockedIn,
  makeSelectHotel,
} from '../../Pages/Authentication/selectors';

const SideBar = () => {
  const dispatch = useDispatch();
  const {push} = useHistory();
  const {branch} = useSelector(makeSelectHotel());
  const operation = useSelector(makeSelectOperation());
  const isClockedIn = useSelector(makeSelectClockedIn());
  const products = useSelector(makeSelectProducts());
  const discount = useSelector(makeSelectDiscount());
  const customer = useSelector(makeSelectCustomer());
  const mode = useSelector(makeSelectCheckoutMode());
  const selectedIndex = useSelector(makeSelectSelectedIndex());
  const minOrderAmount = getMinOrderAmount(branch, mode);
  const cartTotalAmount = cartTotal(products, mode);
  const subTotal =
    minOrderAmount > cartTotalAmount ? minOrderAmount : cartTotalAmount;
  const discountedPrice = getDiscountPrice(discount, subTotal);
  const deliveryCharges = getDeliveryCharges(operation, mode, subTotal);

  const updateQuantity = (index, item) => {
    const quantity = item.quantity + 1;
    dispatch(increaseQuantity(index, quantity));
  };

  const removeQuantity = (index, item) => {
    if (item.quantity > 1) {
      const quantity = item.quantity - 1;
      dispatch(decreaseQuantity(index, quantity));
    }
  };

  const removeItemHandler = (index) => {
    dispatch(removeItem(index));
    dispatch(selectItem(-1));
  };

  const handleClearCart = () => {
    dispatch(clearCart());
    dispatch(selectItem(-1));
  };

  const navigateToEdit = (index) => {
    push(`/edit/${index}`);
  };

  const renderCartItems = () => {
    return products.map((product, i) => (
      <ListGroupItem key={uid()} className="py-1">
        <Row className="m-0">
          <div className="cart-actions">
            <button
              type="button"
              onClick={() => removeItemHandler(i)}
              className="btn btn-sm btn-danger mr-1"
            >
              <i className="fas fa-trash" />
            </button>
            {product.tiers.length > 0 ? (
              <button
                type="button"
                onClick={() => navigateToEdit(i)}
                className="btn btn-sm btn-info mt-2"
              >
                <i className="fas fa-edit" />
              </button>
            ) : null}
          </div>
          <div
            className="cart-content"
            onClick={() => dispatch(selectItem(i, product))}
          >
            <div
              className={`row m-0 justify-content-between align-items-center px-1 ${
                selectedIndex === i ? 'cart-active' : ''
              }`}
            >
              <strong className="item-title">{product.title}</strong>
              <strong className="item-price">
                <Price>{productPrice(product, mode)}</Price>
              </strong>
            </div>
            <Row className="m-0">
              <ul className="tree-view m-0 px-1">
                {product.tiers.length > 0 &&
                  product.tiers.map((tier) => {
                    if (checkIfObjectEmpty(tier)) {
                      return null;
                    }
                    if (tier.type === 'Addon') {
                      return (
                        <div className="tier" key={tier.id + i}>
                          <h2 className="tier-title">{tier.basket_title}</h2>
                          <ul className="tier-value addon">
                            {tier.selected.free &&
                              tier.selected.free.map((addon) => (
                                <li key={addon.id}>
                                  <strong>{addon.quantity}x</strong>{' '}
                                  {addon.title} <strong>(free)</strong>
                                </li>
                              ))}
                            {tier.selected.paid &&
                              tier.selected.paid.map((addon) => (
                                <li key={addon.id}>
                                  <strong>{addon.quantity}x</strong>{' '}
                                  {addon.title} {'  '}
                                  <strong>
                                    <Price>
                                      {priceByMode(addon.price, mode)}
                                    </Price>
                                  </strong>
                                </li>
                              ))}
                          </ul>
                        </div>
                      );
                    }
                    return (
                      <li key={uid()}>
                        <b>{tier.basket_title}</b> : {tier.selected.title}{' '}
                        {'  '}
                        <strong>
                          <Price>
                            {priceByMode(tier.selected.price, mode)}
                          </Price>
                        </strong>
                      </li>
                    );
                  })}

                {product.extras?.length > 0 && (
                  <div className="tier">
                    <h2 className="tier-title">Extras</h2>
                    <ul className="tier-value addon">
                      {product.extras?.map((item, index) => (
                        <li key={item.id}>
                          {item.title}{' '}
                          {product.hold.length > index ? (
                            <strong>(free)</strong>
                          ) : (
                            <strong>
                              (<Price>{item.price}</Price>)
                            </strong>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {product.hold?.length > 0 && (
                  <div className="tier">
                    <h2 className="tier-title">Hold</h2>
                    <ul className="tier-value addon">
                      {product.hold?.map((item) => (
                        <li key={item}>{item} </li>
                      ))}
                    </ul>
                  </div>
                )}
              </ul>
            </Row>
          </div>
          <div className="cart-quantity">
            <i
              className="fas fa-plus-circle plus-icon"
              onClick={() => updateQuantity(i, product)}
            />
            <input
              type="text"
              className="form-control input-number"
              defaultValue={product.quantity}
              min="1"
              max="100"
            />
            <i
              className="fas fa-minus-circle minus-icon btn-danger"
              onClick={() => removeQuantity(i, product)}
            />
          </div>
        </Row>
      </ListGroupItem>
    ));
  };

  if (!isClockedIn) {
    return <Redirect to="/clock-in" />;
  }

  return (
    <div className="cart">
      <nav id="sidebar">
        <div className="sidebar-header">
          <Row>
            <Col md="4">
              <CheckoutMode />
            </Col>
            <Col md="8">
              <Link
                to="/customer"
                type="button"
                className="cart-footer"
                style={{float: 'right'}}
              >
                {checkIfObjectEmpty(customer) ? 'ADD CUSTOMER' : customer.name}
              </Link>
            </Col>
          </Row>
        </div>
        <div className="cart-items">
          <ListGroup flush>
            {products.length > 0 ? (
              renderCartItems()
            ) : (
              <ListGroupItem>
                <center>
                  <small>NO ITEM IN CART..</small>
                </center>
              </ListGroupItem>
            )}
          </ListGroup>
        </div>

        <div className="sub-total">
          <span>SUB TOTAL</span>
          <span className="subAmount">
            <Price>{cartTotalAmount}</Price>
          </span>
        </div>
        <div className="sub-total">
          <span>DELIVERY CHARGES</span>
          <span className="subAmount">
            <Price>{deliveryCharges}</Price>
          </span>
        </div>
        <div className="discount">
          <span>DISCOUNT</span>
          <span className="subAmount">
            <Price>{discountedPrice}</Price>
          </span>
        </div>
        <div className="payable">
          <span>PAYABLE</span>
          <span className="subAmount">
            <Price>{subTotal - discountedPrice + deliveryCharges}</Price>
          </span>
        </div>
        <Row className="cart-btns" style={{marginLeft: '0', marginRight: '0'}}>
          <Col md="3" className="p-1">
            <Button
              className="input-checkout"
              color="danger"
              size="sm"
              onClick={handleClearCart}
            >
              CLEAR
            </Button>
          </Col>
          <Col md="3" className="p-1">
            <Button
              disabled={selectedIndex === -1}
              className="input-checkout bg-info"
              onClick={() => push('/extras')}
              color="info"
              size="sm"
            >
              EXTRAS
            </Button>
          </Col>
          <Col md="3" className="p-1">
            <Button
              disabled={selectedIndex === -1}
              onClick={() => push('/hold')}
              className="input-checkout bg-secondary"
              color="secondary"
              size="sm"
            >
              HOLD
            </Button>
          </Col>
          <Col md="3" className="p-1">
            <Button
              onClick={() => push('/notes')}
              className="input-checkout bg-primary"
              color="primary"
              size="sm"
            >
              NOTES
            </Button>
          </Col>
        </Row>
        <Row style={{marginLeft: '0', marginRight: '0'}}>
          <Col md="12" className="px-0">
            <Link
              to="/checkout"
              className="checkout-btn bg-success"
              color="success"
              size="sm"
            >
              CHECKOUT
            </Link>
          </Col>
        </Row>
      </nav>
    </div>
  );
};

export default SideBar;
