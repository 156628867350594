import React, {useState} from 'react';
import {Col, Row} from 'reactstrap';
import {fromJS} from 'immutable';
import {useSelector} from 'react-redux';
import {getProperPrice, priceByMode} from '../../Libs/prices';
import {addAddonToTier, getAddonQuantity} from '../../Libs/product';
import FreeAddons from './FreeAddons';
import PaidAddons from './PaidAddons';
import Price from '../../Components/Price';
import {makeSelectCheckoutMode} from '../../Components/Cart/selectors';

function Addons({addons, size, setTier, tierDetails}) {
  const mode = useSelector(makeSelectCheckoutMode());
  const [tier, setAddons] = useState(
    fromJS({
      ...tierDetails,
      selected: {
        free: [],
        paid: [],
      },
    }),
  );

  const addAddon = (addon) => {
    setAddons(addAddonToTier(tier, addon, size));
  };

  const removeAddon = (tag, i) => {
    setAddons(tier.deleteIn(['selected', tag, i]));
  };
  return (
    <div className="addons">
      <div className="header">
        <div className="max-addons">
          <p>Maximum Addons Allowed:&nbsp;</p>
          <strong>{tier.get('max_items')}</strong>
        </div>
        <div className="free-addons">
          <p>Free Addons Allowed:&nbsp;</p>
          <strong>{tier.get('free_items')}</strong>
        </div>
      </div>
      <FreeAddons
        addons={tier.getIn(['selected', 'free']).toJS()}
        removeAddon={removeAddon}
      />
      <PaidAddons
        addons={tier.getIn(['selected', 'paid']).toJS()}
        removeAddon={removeAddon}
      />
      <Row className="mt-2">
        {tier.get('max_items') >
          getAddonQuantity(tier.get('selected').toJS()) &&
          addons.map((addon) => {
            const prices = getProperPrice(addon, size);
            return (
              <Col
                key={addon.id}
                md="3"
                className="paddingLeft0"
                onClick={() => addAddon({...addon, price: prices})}
              >
                <div className="prod-list">
                  <div className="prod-title">
                    <h6>{addon.title}</h6>
                  </div>
                  <div className="prod-price">
                    <h6>
                      <Price>{priceByMode(prices, mode)}</Price>
                    </h6>
                  </div>
                </div>
              </Col>
            );
          })}
      </Row>
      <Row className="justify-content-center">
        <div className="w-50">
          <button
            type="button"
            className="btn btn-lg btn-block btn-info py-3"
            onClick={() => setTier(tier.toJS())}
          >
            Add Addons
          </button>
        </div>
      </Row>
    </div>
  );
}

export default Addons;
