import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import {Carousel} from 'react-responsive-carousel';

function Slider() {
  return (
    <Carousel
      infiniteLoop
      autoPlay
      interval={5000}
      renderThumbs={() => null}
      renderIndicator={() => null}
    >
      <div>
        <img alt="Promotion" src={require('../../Assets/images/1.png')} />
      </div>
      <div>
        <img alt="Promotion" src={require('../../Assets/images/2.png')} />
      </div>
    </Carousel>
  );
}

export default Slider;
