import {call, put, takeLatest, select} from 'redux-saga/effects';

import apiUrl from '../../Configs/ApiUrl';
import apiCall from '../../Services/ApiCall';
import {FETCH_TILL} from './constants';
import {clearTill, fetchTillError, fetchTillSuccess} from './actions';
import AuthorizedGetHeaders from '../../Configs/AuthorizedGetHeaders';
import {makeSelectHotel, makeSelectToken} from '../Authentication/selectors';

export function* fetchTill() {
  const token = yield select(makeSelectToken());
  const {branch} = yield select(makeSelectHotel());
  const requestUrl = `${apiUrl}/pos/tillSession/${branch.id}`;
  try {
    const response = yield call(
      apiCall,
      requestUrl,
      AuthorizedGetHeaders(token),
    );
    if (response === null) {
      yield put(clearTill());
    } else {
      yield put(
        fetchTillSuccess({
          closingTotal: response.closing_total || '',
          closingTime:
            response.closing_total === null ? '' : response.updated_at,
          openingTotal: response.opening_total || '',
          openingTime: response.created_at,
          status: response.closing_total === null ? 'open' : 'close',
        }),
      );
    }
  } catch (e) {
    yield put(fetchTillError(e.message));
    // console.log('err occurred', e);
  }
}

export default function* tillSaga() {
  yield takeLatest(FETCH_TILL, fetchTill);
}
