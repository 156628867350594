import React from 'react';
import KeyboardedInput from '../Libs/react-touch-screen-keyboard/src/index';
import 'react-touch-screen-keyboard/lib/Keyboard.css'; // if you just want css
import 'react-touch-screen-keyboard/lib/Keyboard.scss'; // if you've got sass-loader

const TextInput = (props) => (
  <KeyboardedInput
    enabled
    required
    isFirstLetterUppercase
    {...props}
    autoComplete="off"
    defaultKeyboard="us" // optional, default is `false`
    uppercaseAfterSpace // optional, default is `false`
    isDraggable={false} // optional, default is `true`
    opacity={0.9} // optional
  />
);
export default TextInput;
