import React from 'react';
import {useSelector} from 'react-redux';
import {makeSelectState} from '../Bookings/selectors';
import Content from '../../Components/Content';
import CustomerCart from './CustomerCart';
import Slider from './Slider';

function CustomerView() {
  const state = useSelector(makeSelectState());
  if (state === 'created') {
    return (
      <div className="customer-wrapper">
        <CustomerCart />
        <Content>
          <div className="fluid-container view-container d-flex justify-content-center align-items-center">
            <h2>Thank you for choosing Favorite Chicken</h2>
          </div>
        </Content>
      </div>
    );
  }
  return (
    <div className="customer-wrapper">
      <CustomerCart />
      <Content>
        <div className="fluid-container view-container d-flex justify-content-center align-items-center">
          <Slider />
        </div>
      </Content>
    </div>
  );
}

export default CustomerView;
