import React from 'react';
import Price from '../../Components/Price';
import {priceByMode} from '../../Libs/prices';

const Option = ({option, title, mode}) => (
  <div className="size">
    <div className="left">
      <p className="basket-title">{title}: &nbsp;</p>
      <p className="extras-text">{option.title}</p>
    </div>
    <div className="right">
      <Price className="extras-text">
        {priceByMode(
          {
            price_takeaway: option.price_takeaway,
            price_delivery: option.price_delivery,
          },
          mode,
        )}
      </Price>
    </div>
  </div>
);

export default Option;
