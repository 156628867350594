import React from 'react';
import {useSelector} from 'react-redux';
import moment from 'moment';
import Row from 'reactstrap/es/Row';
import {makeSelectCustomerNotes, makeSelectState} from './selectors';
import Loader from '../../Components/Loader';

function CustomerNotes() {
  const state = useSelector(makeSelectState());
  const notes = useSelector(makeSelectCustomerNotes());
  if (notes.length === 0) {
    return null;
  }
  if (state === 'loading') {
    return (
      <Row className="justify-content-center align-items-center mt-4 p-3 w-100">
        <Loader color="black" />
      </Row>
    );
  }
  return (
    <>
      <h5 className="section-header">PREVIOUS NOTES</h5>
      <div className="customer-notes">
        {notes.map((note) => (
          <div key={note.id} className="note">
            <strong>{note.note} </strong>
            <span>{moment(note.created_at).format('YYYY-MM-DD HH:mm')}</span>
          </div>
        ))}
      </div>
    </>
  );
}

export default CustomerNotes;
