/* eslint-disable */
import {toCents} from './prices';

const initialForm = {
  checkout_mode: '',
  reference_no: '',
  address: '',
  postcode: '',
  payment_method: '',
};

export default function validateTask(inputs = initialForm) {
  if (inputs.checkout_mode.trim().length === 0) {
    return 'Select a checkout method.';
  }
  if (inputs.payment_method.trim().length === 0) {
    return 'Please select payment method.';
  }
  if (inputs.address.trim().length === 0) {
    return 'Address field is required';
  }
  if (inputs.reference_no.trim().length === 0) {
    return 'Reference Number field is required';
  }
  if (inputs.postcode.trim().length === 0) {
    return 'Post Code field is required';
  }
  return 'validated';
}
