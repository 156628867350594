import React from 'react';
import AutoScalingText from './AutoScalingText';

const NumpadDisplay = ({value, selected, ...props}) => {
  const language = navigator.language || 'en-US';
  let formattedValue = parseFloat(value).toLocaleString(language, {
    useGrouping: true,
    maximumFractionDigits: 6,
  });

  // Add back missing .0 in e.g. 12.0
  const match = value.match(/\.\d*?(0*)$/);

  if (match) formattedValue += /[1-9]/.test(match[0]) ? match[1] : match[0];

  return (
    <div {...props} className="numpad-display">
      <AutoScalingText>
        <span className={selected ? 'selected' : ''}>£{formattedValue}</span>
      </AutoScalingText>
    </div>
  );
};

export default NumpadDisplay;
