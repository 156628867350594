import {toCents} from './prices';

export const getPaymentMode = (payments) => {
  const temp = payments[0].type || payments[0].mode;
  for (let i = 1; i < payments.length; i += 1) {
    if (temp !== payments[i].type) {
      return 'MIXED';
    }
  }
  return temp.toUpperCase();
};

export const paidStatus = (payments) => {
  if (payments.length > 1) {
    return `PAID ${getPaymentMode(payments) || ''}`;
  }
  if (payments.length === 1) {
    if (
      payments[0].transaction_status?.toLowerCase() === 'completed' ||
      payments[0].type === 'cash' ||
      payments[0].type === 'card' ||
      payments[0].mode === 'cash' ||
      payments[0].mode === 'card'
    ) {
      return `PAID ${getPaymentMode(payments)}`;
    }
    return 'NOT PAID';
  }
  return 'NOT PAID';
};

// eslint-disable-next-line camelcase
const syncBookingSearch = ({user_address, booking}, q) => {
  return (
    booking.address.toLowerCase().includes(q) ||
    booking.postcode.toLowerCase().replace(' ', '').includes(q) ||
    booking.total_charges === toCents(q) ||
    user_address.telephone.includes(q)
  );
};

// eslint-disable-next-line camelcase
const localBookingSearch = ({formData, sub_total}, q) => {
  return (
    formData.address.toLowerCase().includes(q) ||
    formData.postcode.toLowerCase().replace(' ', '').includes(q) ||
    // eslint-disable-next-line camelcase
    sub_total === toCents(q) ||
    formData.telephone.includes(q)
  );
};

export const searchBookings = (bookings, q) => {
  if (q) {
    return bookings.filter((booking) => {
      if (booking.syncState === 'syncing' || booking.syncState === 'local') {
        return localBookingSearch(booking, q);
      }
      return syncBookingSearch(booking, q);
    });
  }
  return bookings;
};

export const searchTasks = (tasks, q) => {
  if (q) {
    return tasks.filter((task) => task.checkout_mode === q);
  }
  return tasks;
};
const filterLocalBooking = (booking, filter) =>
  booking.checkout_mode.toLowerCase() === filter;

const filterSyncedBooking = (booking, filter) =>
  booking.booking.checkout_mode.toLowerCase() === filter;

export const filterBookings = (bookings, filter) => {
  if (filter === 'all') {
    return bookings;
  }
  if (['pos', 'web'].includes(filter)) {
    return bookings.filter((booking) => {
      if (booking.syncState === 'syncing' || booking.syncState === 'local') {
        return filter === 'pos';
      }
      return booking.booking_mode.mode === filter;
    });
  }
  return bookings.filter((booking) => {
    if (booking.syncState === 'syncing' || booking.syncState === 'local') {
      return filterLocalBooking(booking, filter);
    }
    return filterSyncedBooking(booking, filter);
  });
};

export const filterTasks = (tasks, filter) => {
  if (filter === 'all') {
    return tasks;
  }
  return tasks.filter((task) => task.checkout_mode.toLowerCase() === filter);
};

export const bookingByUnique = (bookings, unique) => {
  const index = bookings.findIndex(
    (booking) => booking.booking?.id === parseInt(unique, 10),
  );
  return bookings[index] || {};
};

export const checkIfOfflineBookingsExists = (bookings) => {
  return (
    bookings.findIndex((booking) => booking.syncState === 'syncing') !== -1
  );
};

const bookingExtrasToExtras = (extras) =>
  extras.map((extra) => ({
    price: extra.price,
    title: extra.title,
    id: extra.extra_id,
    quantity: extra.quantity,
  }));

const bookingHoldToHold = (holds) => holds.map((hold) => hold.title);

const bookingOptionToOption = (option) => ({
  id: option.option_id,
  price: {
    price_takeaway: option.price_takeaway,
    price_delivery: option.price_delivery,
  },
  quantity: option.quantity,
  title: option.title,
});

const bookingSizeToSize = (size) => ({
  id: size.size_id,
  price: {
    price_takeaway: size.price_takeaway,
    price_delivery: size.price_delivery,
  },
  quantity: size.quantity,
  title: size.title,
});

const bookingProductToProduct = (product) => ({
  id: product.product_id,
  price: {
    price_takeaway: product.price_takeaway,
    price_delivery: product.price_delivery,
  },
  quantity: product.quantity,
  title: product.title,
});

const bookingAddonsToAddons = (addons) => {
  const free = [];
  const paid = [];
  for (let i = 0; i < addons.length; i += 1) {
    if (addons[i].free === 0) {
      paid.push({
        id: addons[i].addon_id,
        price: {
          price_takeaway: addons[i].price_takeaway,
          price_delivery: addons[i].price_delivery,
        },
        quantity: addons[i].quantity,
        title: addons[i].title,
      });
    } else {
      free.push({
        id: addons[i].addon_id,
        price: {
          price_takeaway: addons[i].price_takeaway,
          price_delivery: addons[i].price_delivery,
        },
        quantity: addons[i].quantity,
        title: addons[i].title,
      });
    }
  }
  return {free, paid};
};

const bookingTiersToTiers = (tiers) => {
  return tiers.map((tier) => {
    if (tier.type === 'Option') {
      return {
        basket_title: tier.basket_title,
        free_items: tier.free_items,
        max_items: tier.max_items,
        id: tier.tier_id,
        title: tier.title,
        type: tier.type,
        type_id: tier.type_id,
        required: tier.required,
        selected: bookingOptionToOption(tier.booking_tier_options),
      };
    }
    if (tier.type === 'Size') {
      return {
        basket_title: tier.basket_title,
        free_items: tier.free_items,
        max_items: tier.max_items,
        id: tier.tier_id,
        title: tier.title,
        type: tier.type,
        type_id: tier.type_id,
        required: tier.required,
        selected: bookingSizeToSize(tier.booking_tier_sizes),
      };
    }
    if (tier.type === 'Product') {
      return {
        basket_title: tier.basket_title,
        free_items: tier.free_items,
        max_items: tier.max_items,
        id: tier.tier_id,
        title: tier.title,
        type: tier.type,
        type_id: tier.type_id,
        required: tier.required,
        selected: bookingProductToProduct(tier.booking_tier_products),
      };
    }
    if (tier.type === 'Addon') {
      return {
        basket_title: tier.basket_title,
        free_items: tier.free_items,
        max_items: tier.max_items,
        id: tier.tier_id,
        title: tier.title,
        type: tier.type,
        type_id: tier.type_id,
        required: tier.required,
        selected: bookingAddonsToAddons(tier.booking_tier_addons || []),
      };
    }
    return null;
  });
};

export const bookingProductsToProducts = (products = []) => {
  return products.map((product) => {
    return {
      title: product.title,
      id: product.product_id,
      price_takeaway: product.price_takeaway,
      price_delivery: product.price_delivery,
      quantity: product.quantity,
      menu_id: product.pos_menu?.pop()?.id,
      extras: bookingExtrasToExtras(product.booking_extra),
      hold: bookingHoldToHold(product.booking_hold),
      tiers: bookingTiersToTiers(product.booking_tiers),
    };
  });
};

export const bookingPaymentsToPayments = (payments) => {
  return payments.map((payment) => ({
    mode: payment.type,
    amount: payment.amount,
    terminal: payment.terminal,
  }));
};

export const bookingNotesToNotes = (notes = '') => {
  return notes?.split(',') || [];
};
