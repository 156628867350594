import {createSelector} from 'reselect';

export const selectHydrate = (state) => state.get('hydrate');

export const selectRouter = (state) => state.get('router');

export const makeSelectHydrated = () =>
  createSelector(selectHydrate, (HydrateState) => HydrateState.get('hydrated'));

export const makeSelectLocation = () =>
  createSelector(selectRouter, (RouterState) =>
    RouterState.get('location').toJS(),
  );
