import React from 'react';
import {Col} from 'reactstrap';
import {useSelector} from 'react-redux';
import {getProperPrice, priceByMode} from '../../Libs/prices';
import Price from '../../Components/Price';
import {makeSelectCheckoutMode} from '../../Components/Cart/selectors';

function EditTierProduct({products, selectedProduct, updateProduct}) {
  const mode = useSelector(makeSelectCheckoutMode());
  return products.map((product) => {
    const prices = getProperPrice(product);
    const itemPrice = priceByMode(prices, mode);
    return (
      <Col key={product.id} md="3" onClick={() => updateProduct(product)}>
        <div
          className={`prod-list ${
            selectedProduct.selected?.id === product.id ? 't-selected' : ''
          }`}
        >
          <div className="prod-title">
            <h6>{product.title}</h6>
          </div>
          <div className="prod-price">
            <strong>
              <Price>{itemPrice}</Price>
            </strong>
          </div>
        </div>
      </Col>
    );
  });
}

export default EditTierProduct;
