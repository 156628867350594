import React from 'react';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';
import {useSelector} from 'react-redux';
import {checkIfObjectEmpty, deformatTelephoneNumber} from '../../Libs/object';
import {
  makeSelectCheckoutMode,
  makeSelectCustomer,
} from '../../Components/Cart/selectors';

function CustomerAddress() {
  const customer = useSelector(makeSelectCustomer());
  const mode = useSelector(makeSelectCheckoutMode());
  console.log('customer', customer);
  if (mode === 'Takeaway' || checkIfObjectEmpty(customer)) {
    return null;
  }
  return (
    <div className="address">
      <h6>Customer Address</h6>
      <Row>
        <Col md={6}>
          <h4>
            Telephone:{' '}
            <strong>{deformatTelephoneNumber(customer.telephone)}</strong>
          </h4>
        </Col>
        <Col md={6}>
          <h4>
            Name: <strong>{customer.name}</strong>
          </h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4>
            Address: <strong>{customer.address}</strong>
          </h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4>
            Postcode: <strong>{customer.postcode}</strong>
          </h4>
        </Col>
      </Row>
    </div>
  );
}

export default CustomerAddress;
