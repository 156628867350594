import React, {useEffect, useState} from 'react';
import moment from 'moment';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';
import {useSelector} from 'react-redux';
import {Link, Redirect} from 'react-router-dom';
import qz from 'qz-tray';
import FormGroup from 'reactstrap/es/FormGroup';
import Label from 'reactstrap/es/Label';
import Input from 'reactstrap/es/Input';
import Alert from 'reactstrap/es/Alert';
import {
  makeSelectClockedIn,
  makeSelectHotel,
  makeSelectToken,
  makeSelectUserPermissions,
} from '../Authentication/selectors';
import apiCall from '../../Services/ApiCall';
import apiUrl from '../../Configs/ApiUrl';
import QzTray from '../../Configs/QZTray';
import {printSalesReport} from '../../Libs/printSalesReport';
import {makeSelectDefaultPrinter} from '../Management/selectors';
import More from './More';
import Less from './Less';
import AuthorizedPostHeaders from '../../Configs/AuthorizedPostHeaders';
import Loader from '../../Components/Loader';

function Sales() {
  const {branch} = useSelector(makeSelectHotel());
  const token = useSelector(makeSelectToken());
  const defaultPrinter = useSelector(makeSelectDefaultPrinter());
  const clockedIn = useSelector(makeSelectClockedIn());
  const permissions = useSelector(makeSelectUserPermissions());
  const [more, setMore] = useState(true);
  const [fromDate, setFromDate] = useState(moment().format('YYYY-MM-DD'));
  const [fromTime, setFromTime] = useState('09:00:00');
  const [toDate, setToDate] = useState(
    moment().add(1, 'days').format('YYYY-MM-DD'),
  );
  const [toTime, setToTime] = useState('04:00:00');
  const [state, setState] = useState('initial');
  const [status, setStatus] = useState('');

  const [summary, setSummary] = useState({});

  useEffect(() => {
    setState('loading');
    setStatus('');
    apiCall(
      `${apiUrl}/tasks/dailySummaryByBranchIDAndTimestamps`,
      AuthorizedPostHeaders(
        {
          branch_id: branch.id,
          start_date: `${fromDate} ${fromTime}`,
          end_date: `${toDate} ${toTime}`,
        },
        token,
      ),
    )
      .then((response) => {
        // console.log('response', response);
        setSummary(response);
        setState('success');
      })
      .catch((e) => {
        setState('error');
        setStatus(e.message);
        // console.log('error', e);
      });
  }, [fromDate, fromTime, toDate, toTime]);

  async function handlePrintSalesReport() {
    try {
      const printString = printSalesReport(
        summary,
        branch,
        'END OF THE DAY REPORT',
        more,
      );
      const config = await QzTray(defaultPrinter);
      await qz.print(config, printString);
      await qz.websocket.disconnect();
    } catch (e) {
      console.log('offline print error', e);
    }
  }

  const incrementDate = (type) => {
    if (type === 'to') {
      setToDate(moment(toDate).add(1, 'days').format('YYYY-MM-DD'));
    } else {
      setFromDate(moment(fromDate).add(1, 'days').format('YYYY-MM-DD'));
    }
  };

  const decrementDate = (type) => {
    if (type === 'to') {
      setToDate(moment(toDate).subtract(1, 'days').format('YYYY-MM-DD'));
    } else {
      setFromDate(moment(fromDate).subtract(1, 'days').format('YYYY-MM-DD'));
    }
  };

  if (!clockedIn || !permissions.summary_access === true) {
    return <Redirect to="/" />;
  }

  if (state === 'initial' || state === 'loading') {
    return (
      <div className="sales">
        <Link to="/" className="go-back">
          Go Back
        </Link>
        <Row>
          <Col>
            <h2 className="title">{branch.official_title}</h2>
          </Col>
        </Row>
        <div className="loading-wrapper">
          <Loader color="black" />
        </div>
      </div>
    );
  }

  if (state === 'error') {
    return (
      <div className="sales">
        <Link to="/" className="go-back">
          Go Back
        </Link>
        <Row>
          <Col>
            <h2 className="title">{branch.official_title}</h2>
          </Col>
        </Row>

        <Row>
          <Col className="from-datetime">
            <h3>From Date</h3>
            <div className="datetime">
              <FormGroup>
                <Label for="fromDate">Date</Label>
                <Input
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  type="date"
                  name="date"
                  id="fromDate"
                  placeholder="date placeholder"
                />
                <div className="arrow">
                  <i
                    className="fa fa-arrow-left"
                    onClick={() => decrementDate('from')}
                  />
                  <i
                    className="fa fa-arrow-right"
                    onClick={() => incrementDate('from')}
                  />
                </div>
              </FormGroup>
              <FormGroup className="time">
                <Label for="fromTime">Time</Label>
                <Input
                  value={fromTime}
                  onChange={(e) => setFromTime(`${e.target.value}:00`)}
                  type="time"
                  name="time"
                  id="fromTime"
                  placeholder="time placeholder"
                />
              </FormGroup>
            </div>
          </Col>
          <Col className="to-datetime">
            <h3>To Date</h3>
            <div className="datetime">
              <FormGroup>
                <Label for="toDate">Date</Label>
                <Input
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  type="date"
                  name="date"
                  id="toDate"
                  placeholder="date placeholder"
                />
                <div className="arrow">
                  <i
                    className="fa fa-arrow-left"
                    onClick={() => decrementDate('to')}
                  />
                  <i
                    className="fa fa-arrow-right"
                    onClick={() => incrementDate('to')}
                  />
                </div>
              </FormGroup>
              <FormGroup className="time">
                <Label for="toTime">Time</Label>
                <Input
                  value={toTime}
                  onChange={(e) => setToTime(`${e.target.value}:00`)}
                  type="time"
                  name="time"
                  id="toTime"
                  placeholder="time placeholder"
                />
              </FormGroup>
            </div>
          </Col>
        </Row>
        <div className="loading-wrapper">
          <Alert color="danger">{status}</Alert>
        </div>
      </div>
    );
  }

  return (
    <div className="sales">
      <Link to="/" className="go-back">
        Go Back
      </Link>
      <Row>
        <Col>
          <h2 className="title">{branch.official_title}</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="header">END OF DAY SALE</h3>
        </Col>
        <Col>
          <button
            className="show-details"
            type="button"
            onClick={() => setMore(!more)}
          >
            {more ? 'SHOW LESS' : 'SHOW MORE'}
          </button>
        </Col>
      </Row>
      <Row>
        <Col className="from-datetime">
          <h3>From Date</h3>
          <div className="datetime">
            <FormGroup>
              <Label for="fromDate">Date</Label>
              <Input
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                type="date"
                name="date"
                id="fromDate"
                placeholder="date placeholder"
              />
              <div className="arrow">
                <i
                  className="fa fa-arrow-left"
                  onClick={() => decrementDate('from')}
                />
                <i
                  className="fa fa-arrow-right"
                  onClick={() => incrementDate('from')}
                />
              </div>
            </FormGroup>
            <FormGroup className="time">
              <Label for="fromTime">Time</Label>
              <Input
                value={fromTime}
                onChange={(e) => setFromTime(`${e.target.value}:00`)}
                type="time"
                name="time"
                id="fromTime"
                placeholder="time placeholder"
              />
            </FormGroup>
          </div>
        </Col>
        <Col className="to-datetime">
          <h3>To Date</h3>
          <div className="datetime">
            <FormGroup>
              <Label for="toDate">Date</Label>
              <Input
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                type="date"
                name="date"
                id="toDate"
                placeholder="date placeholder"
              />
              <div className="arrow">
                <i
                  className="fa fa-arrow-left"
                  onClick={() => decrementDate('to')}
                />
                <i
                  className="fa fa-arrow-right"
                  onClick={() => incrementDate('to')}
                />
              </div>
            </FormGroup>
            <FormGroup className="time">
              <Label for="toTime">Time</Label>
              <Input
                value={toTime}
                onChange={(e) => setToTime(`${e.target.value}:00`)}
                type="time"
                name="time"
                id="toTime"
                placeholder="time placeholder"
              />
            </FormGroup>
          </div>
        </Col>
      </Row>
      <Row>
        {more ? <More summary={summary} /> : <Less summary={summary} />}
      </Row>
      <Row>
        <Col className="print-btn">
          <button
            className="print"
            type="button"
            onClick={handlePrintSalesReport}
          >
            Print
          </button>
        </Col>
      </Row>
    </div>
  );
}

export default Sales;
