import React from 'react';
import {Col, Row} from 'reactstrap';
import {useSelector} from 'react-redux';
import {getProperPrice, priceByMode} from '../../Libs/prices';
import {getAddonQuantity} from '../../Libs/product';
import Price from '../../Components/Price';
import FreeAddons from '../Product/FreeAddons';
import PaidAddons from '../Product/PaidAddons';
import {makeSelectCheckoutMode} from '../../Components/Cart/selectors';

function EditAddons({tier, selectedAddons, addAddon, removeAddon, size}) {
  const mode = useSelector(makeSelectCheckoutMode());
  return (
    <div className="addons">
      <div className="header">
        <div className="max-addons">
          <p>Maximum Addons Allowed:&nbsp;</p>
          <strong>{tier.max_items}</strong>
        </div>
        <div className="free-addons">
          <p>Free Addons Allowed:&nbsp;</p>
          <strong>{tier.free_items}</strong>
        </div>
      </div>
      <FreeAddons
        addons={selectedAddons.selected?.free || []}
        removeAddon={removeAddon}
      />
      <PaidAddons
        addons={selectedAddons.selected?.paid || []}
        removeAddon={removeAddon}
      />
      <Row className="mt-2">
        {tier.max_items > getAddonQuantity(selectedAddons.selected || []) &&
          tier.addons.map((addon) => {
            const prices = getProperPrice(addon, size);
            const addonPrice = priceByMode(prices, mode);
            return (
              <Col
                key={addon.id}
                md="3"
                className="paddingLeft0"
                onClick={() => addAddon({...addon, price: prices})}
              >
                <div className="prod-list">
                  <div className="prod-title">
                    <h6>{addon.title}</h6>
                  </div>
                  <div className="prod-price">
                    <h6>
                      <Price>{addonPrice}</Price>
                    </h6>
                  </div>
                </div>
              </Col>
            );
          })}
      </Row>
    </div>
  );
}

export default EditAddons;
