/* eslint-disable */
// printing rolls have two sizes (approx width: 80mm) and (approx width: 88mm)
// approx width 80mm: maximum number of characters is 42
// approx width 88mm: maximum number of characters is 48
// find out how much do you need on the left side.
// for ex: In case of 80mm, 35 characters on the left side, rest character will be on the right. i.e 7 characters
// for totalColsPerPage = 40, leftCols = 28
// for totalColsPerPage = 42, leftCols = 30
// for totalColsPerPage = 48, leftCols = 36
import moment
  from 'moment';
import {paidStatus} from './bookings';
import {priceByMode} from './prices';

const totalColsPerPage = 48;
const leftCols = 43;

const init = '\x1B\x40';
const alignCenter = '\x1B\x61\x31';
const alignLeft = '\x1B\x61\x30';
const alignRight = '\x1B\x61\x32';
const newLine = '\x0A';
const bold_on = '\x1B\x45\x0D';
const bold_off = '\x1B\x45\x0A';
const em_mode_on = '\x1B\x21\x30';
const em_mode_off = '\x1B\x21\x0A\x1B\x45\x0A';
const small_text = '\x1B\x4D\x31';
const normal_text = '\x1B\x4D\x25';
const norm_text = '\x1B\x4D\x25';
const big_text = '\x1B\x21\x25';
const bigger_text = '\x1B\x21\x20';
const cutPaper = '\x1B\x69';
const openCashDrawer = '\x10' + '\x14' + '\x01' + '\x00' + '\x05';
const total_amount = 0;
const voucher_amount = 0;

// Pound sign
// \u00a35

/// //////////////////////////////////////////////////////////////////
//
// Server Side method for verifying the certificate
// Recommended: Because the private key is on server
//
/// //////////////////////////////////////////////////////////////////

export const getTaskReceipt = function(task, branch, printer) {
  return [
    init +
    getReceiptTitle(branch.official_title, branch.mobile, printer.title) +
    getVendor(task.vendor) +
    getCheckout(task.checkout_mode, branch.official_title, task.reference_no, task.created_at, printer.checkout) +
    getPaymentStatus(task.payment_method, printer.paid) +
    getCustomerDetails(task, printer.customer) +
    getThankYou(branch.official_title, printer.thankYou) +
    getStoreDetails(branch, task.created_at, printer.store) +
    newLine +
    newLine +
    newLine +
    newLine +
    newLine +
    newLine +
    newLine +
    cutPaper,
  ];
};

function getReceiptTitle(officialTitle, mobile, shouldPrint) {
  if (shouldPrint) {
    return em_mode_on +
      alignCenter +
      getHotelTitle(officialTitle) +
      em_mode_off +
      newLine +
      getHotelMobile(mobile) +
      newLine +
      newLine +
      dottedLine();
  }
  return '';
}


function getVendor(vendor) {
  return newLine +
    newLine +
    em_mode_on +
    alignCenter +
    vendor.title.toUpperCase() +
    em_mode_off +
    newLine +
    newLine +
    dottedLine();
}

function getCheckout(checkoutMode, officialTitle, unique, createdAt, shouldPrint) {
  if (shouldPrint) {
    return newLine +
      newLine +
      normal_text +
      em_mode_on +
      getCheckoutMode(checkoutMode) +
      em_mode_off +
      newLine +
      newLine +
      getBookingNo(officialTitle, unique) +
      newLine +
      getBookingDue(createdAt) +
      newLine +
      dottedLine();
  }
  return '';
}

function getPaymentStatus(payment, shouldPrint) {
  if (shouldPrint) {
    return newLine +
      em_mode_on +
      alignCenter +
      payment.toUpperCase() +
      em_mode_off +
      newLine +
      dottedLine();
  }
  return '';
}

function kickDrawer(openDrawer) {
  if (openDrawer) {
    return openCashDrawer;
  }
  return '';
}

function getHotelTitle(title) {
  return title;
}

function getHotelMobile(mobile) {
  return mobile;
}

function getCheckoutMode(mode) {
  if (mode.toLowerCase() === 'takeaway') {
    return 'Collection';
  }
  return 'Delivery';

}

function getBookingNo(title, unique) {
  return `Booking No: ${getHotelAcronym(title)}-${
    unique
  }`;
}

function getHotelAcronym(str) {
  const matches = str.match(/\b(\w)/g); // ['J','S','O','N']
  const acronym = matches.join(''); // JSON
  return acronym;
}

function getBookingDue(bDate) {
  return `Booking Due: ${moment(bDate).format('HH:mm')}`;
}

// Custom Details
function getCustomerDetails(task, shouldPrint) {
  if (shouldPrint) {
    return (
      newLine +
      alignCenter +
      bold_on +
      bigger_text +
      maxRound('Customer Details') +
      bold_off +
      newLine +
      alignLeft +
      big_text +
      maxRound(task.address) +
      newLine +
      maxRound(`${task.postcode}`) +
      newLine +
      normal_text +
      newLine +
      em_mode_off +
      alignCenter +
      dottedLine()
    );
  }
  return '';
}

// Thankyou
function getThankYou(branchTitle, shouldPrint) {
  if (shouldPrint) {
    return (
      newLine +
      alignLeft +
      getItemRowFull(`Thank you for shopping at ${branchTitle}`, '') +
      newLine +
      dottedLine()
    );
  }
  return '';
}

// Store Details
function getStoreDetails(branch, createdAt, shouldPrint) {
  if (shouldPrint) {
    return (
      newLine +
      alignLeft +
      alignLeft +
      bold_on +
      getItemRowFull('Store Details: ', '') +
      bold_off +
      newLine +
      getItemRowFull(branch.official_title, '') +
      newLine +
      getItemRowFull(`${branch.address}, ${branch.zipcode}`, '') +
      newLine +
      getItemRowFull(`Reg No: ${branch.registration_no}`, '') +
      newLine +
      getItemRowFull(`VAT No: ${branch.vat_no}`, '') +
      newLine +
      newLine +
      getBoookingCreatedAt(createdAt) +
      getBookingProcessedAt(createdAt)
    );
  }
  return '';
}

// Booking created at & Booking processed at
function getBoookingCreatedAt(bDate) {
  return (
    getItemRowFull(`Booking created: ${getTimestamp(bDate)}`, '') +
    newLine
  );
}

function getLocalBookingCreatedAt(bDate) {
  return (
    getItemRowFull(`Booking created: ${bDate}`, '') +
    newLine
  );
}

function getBookingProcessedAt(bDate) {
  return (
    getItemRowFull(
      `Booking processed: ${getTimestamp(bDate)}`,
      '',
    ) + newLine
  );
}

function getLocalBookingProcessedAt(bDate) {
  return (
    getItemRowFull(
      `Booking processed: ${bDate}`,
      '',
    ) + newLine
  );
}

function getTimestamp(booking) {
  return new Date(booking).toLocaleString();
}

// operations
function checkIfObjectEmpty(obj = {}) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

function checkIfNull(params = {}) {
  return params === null;
}

function getItemRow(itemTitle, itemPrice) {
  if (itemTitle.length > leftCols) {
    let recieptRow = itemTitle.substr(0, leftCols);
    recieptRow += newLine;
    recieptRow +=
      itemTitle
        .substr(leftCols)
        .padEnd(totalColsPerPage - itemPrice.length, ' ') + itemPrice;
    return recieptRow;
  }

  return itemTitle.padEnd(totalColsPerPage - itemPrice.length, ' ') + itemPrice;
}

function getItemRowFull(itemTitle, itemPrice = '') {
  const price = itemPrice === '0.00' ? '' : itemPrice;
  const marginSpace = totalColsPerPage === 48 ? '' : '   ';

  let colsLeft = leftCols;
  if (itemTitle.length > leftCols) {
    colsLeft = !price ? totalColsPerPage - 5 : colsLeft;
    let rightCols = !price ? 5 : price.length;

    const itemTitleLength = itemTitle.length;
    let receipt = '';
    let startFrom = 0;
    let remainingCharacters = itemTitle.length;

    while (startFrom < itemTitleLength) {
      receipt +=
        /* alignLeft + */ marginSpace +
        itemTitle
          .substr(startFrom, colsLeft)
          .padEnd(totalColsPerPage - rightCols, ' ') +
        (startFrom === 0 ? price : '');
      receipt += newLine;
      remainingCharacters =
        remainingCharacters + itemTitleLength - (itemTitleLength + colsLeft);
      startFrom += colsLeft;
    }
    return receipt;
  }

  return (
    marginSpace +
    itemTitle.padEnd(totalColsPerPage - price.length, '    ') +
    (price === 0 ? '' : price)
  );
}

function maxRound(text) {
  const re = new RegExp(`.{1,${leftCols}}`, 'g');
  const chunk = text.match(re);
  const spaced = chunk.map((i) => `  ${i}`);
  return spaced.join(newLine);
}


function getPrice(price, mode) {
  return parseFloat(price / 100).toFixed(2);
}

function dottedLine() {
  if (totalColsPerPage === 48) {
    return `${alignCenter}------------------------------------------------`;
  }
  return `${alignCenter}------------------------------------------`;
}

function checkIfProductPrintable(posMenu, categories) {
  let shouldPrint = false;
  for (let i = 0; i < posMenu.length; i += 1) {
    if (categories.includes(posMenu[i].id)) {
      return true;
    }
  }
  return shouldPrint;
}
