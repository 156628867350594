// FETCH MENUS
export const FETCH_MENU = 'FETCH_MENU';
export const FETCH_MENU_SUCCESS = 'FETCH_MENU_SUCCESS';
export const FETCH_MENU_ERROR = 'FETCH_MENU_ERROR';
export const SET_SINGE_MENU = 'SET_SINGE_MENU';
export const SET_ITEM = 'SET_ITEM';
export const SET_CART_ITEM = 'SET_CART_ITEM';

export const CHECKOUT = 'Favorite/Checkout/CHECKOUT';
export const CHECKOUT_SUCCESS = 'Favorite/Checkout/CHECKOUT_SUCCESS';
export const CHECKOUT_ERROR = 'Favorite/Checkout/CHECKOUT_ERROR';

export const SET_CLICKABLE = 'Favorite/Menu/SET_CLICKABLE';
