import React from 'react';
import {Link} from 'react-router-dom';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';

function Panel() {
  return (
    <>
      <Row>
        <Col>
          <h4>Management Panel</h4>
        </Col>
      </Row>
      <Row>
        <Link to="/management/printers" className="panel-btn">
          Printers
        </Link>
      </Row>
    </>
  );
}

export default Panel;
