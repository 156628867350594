import {fromJS} from 'immutable';

import {LOGOUT, CLOCKOUT, CLOCKIN, LOGIN, UNAUTHORIZED} from './constants';

const initialState = fromJS({
  user: {},
  hotel: {},
  isClockedIn: false,
  token: '',
});

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return state
        .set('token', action.token)
        .set('user', fromJS(action.user))
        .set('hotel', fromJS(action.hotel));
    case LOGOUT:
      return initialState;
    case CLOCKIN:
      return state
        .set('isClockedIn', true)
        .set('user', fromJS(action.user))
        .set('hotel', fromJS(action.hotel));
    case CLOCKOUT:
      return state.set('isClockedIn', false);
    case UNAUTHORIZED:
      return initialState;
    default:
      return state;
  }
}
