import React from 'react';
import {useSelector} from 'react-redux';
import {getProperPrice, priceByMode} from '../../Libs/prices';
import Price from '../../Components/Price';
import {makeSelectCheckoutMode} from '../../Components/Cart/selectors';

function EditSize({sizes, selectedSize, updateSize}) {
  const mode = useSelector(makeSelectCheckoutMode());
  return sizes.map((size) => {
    const prices = getProperPrice(size);
    const itemPrice = priceByMode(prices, mode);
    return (
      <div
        key={size.id}
        className={`prod-list ${
          selectedSize.selected.id === size.id ? 't-selected' : ''
        }`}
        onClick={() => updateSize(size)}
      >
        <div className="prod-title">
          <h6>{size.title}</h6>
        </div>
        <div className="prod-price">
          <strong>
            <Price>{itemPrice}</Price>
          </strong>
        </div>
      </div>
    );
  });
}

export default EditSize;
