import React from 'react';
import Row from 'reactstrap/es/Row';
import {useSelector} from 'react-redux';
import Col from 'reactstrap/es/Col';
import {makeSelectState} from './selectors';
import Loader from '../../Components/Loader';
import {deformatTelephoneNumber} from '../../Libs/object';

function DbCustomer({customer, selectCustomer, mode}) {
  const state = useSelector(makeSelectState());
  if (state === 'loading') {
    return (
      <Row className="justify-content-center align-items-center mt-4 p-3 w-100">
        <Loader color="black" />
      </Row>
    );
  }
  if (state === 'error') {
    return (
      <Row className="d-flex justify-content-center align-items-center row w-100">
        <p className="alert alert-danger">User Not Found</p>
      </Row>
    );
  }
  if (mode === 'customer') {
    return (
      customer.user_addresses?.length > 0 && (
        <div className="addresses">
          {state === 'error' ? (
            <p className="alert alert-danger">{state}</p>
          ) : (
            <>
              <h3 className="section-header">SELECT ADDRESS</h3>
              {customer.user_addresses.map((address) => (
                <div
                  key={address.id}
                  className="address"
                  onClick={() => selectCustomer(address)}
                >
                  <Row>
                    <Col md={6}>
                      <h4>
                        Telephone:{' '}
                        <strong>
                          {deformatTelephoneNumber(address.telephone)}
                        </strong>
                      </h4>
                    </Col>
                    <Col md={6}>
                      <h4>
                        Name: <strong>{address.name}</strong>
                      </h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h4>
                        Address: <strong>{address.address}</strong>
                      </h4>
                    </Col>
                  </Row>
                </div>
              ))}
            </>
          )}
        </div>
      )
    );
  }
  return null;
}

export default DbCustomer;
