export const SYNC_BOOKING = 'Favorite/Bookings/SYNC_BOOKING';

export const SYNC_BOOKING_SUCCESS = 'Favorite/Bookings/SYNC_BOOKING_SUCCESS';

export const SYNC_BOOKING_ERROR = 'Favorite/Bookings/SYNC_BOOKING_ERROR';

export const ADD_BOOKING = 'Favorite/Bookings/ADD_BOOKING';

export const CREATE_BOOKING = 'Favorite/Bookings/CREATE_BOOKING';

export const CREATE_BOOKING_SUCCESS =
  'Favorite/Bookings/CREATE_BOOKING_SUCCESS';

export const CREATE_BOOKING_ERROR = 'Favorite/Bookings/CREATE_BOOKING_ERROR';

export const FETCH_BOOKINGS = 'Favorite/Bookings/FETCH_BOOKINGS';

export const FETCH_BOOKINGS_SUCCESS =
  'Favorite/Bookings/FETCH_BOOKINGS_SUCCESS';

export const FETCH_BOOKINGS_ERROR = 'Favorite/Bookings/FETCH_BOOKINGS_ERROR';

export const TOGGLE_ONLINE_STATUS = 'Favorite/Bookings/TOGGLE_ONLINE_STATUS';

export const DELETE_LOCAL_BOOKING = 'Favorite/Bookings/DELETE_LOCAL_BOOKING';

export const DELETE_SYNCED_BOOKING = 'Favorite/Bookings/DELETE_SYNCED_BOOKING';

export const UPDATE_BOOKING_STATUS = 'Favorite/Bookings/UPDATE_BOOKING_STATUS';

export const EDIT_BOOKING = 'Favorite/Bookings/EDIT_BOOKING';

export const UPDATE_BOOKING = 'Favorite/Booking/UPDATE_BOOKING';

export const UPDATE_BOOKING_SUCCESS = 'Favorite/Booking/UPDATE_BOOKING_SUCCESS';

export const UPDATE_BOOKING_ERROR = 'Favorite/Booking/UPDATE_BOOKING_ERROR';
