import {
  SET_CHANGE,
  SPLIT_ORDER,
  SET_PAYMENT,
  SET_DISPLAY_VALUE,
  REMOVE_PAYMENT,
  SET_PAYMENTS,
  SET_SPLITING,
} from './constants';

export const setChange = (change) => ({
  type: SET_CHANGE,
  change,
});

export const setPayment = (payment, change) => ({
  type: SET_PAYMENT,
  payment,
  change,
});

export const setPayments = (payments, change) => ({
  type: SET_PAYMENTS,
  payments,
  change,
});

export const removePayment = (index) => ({
  type: REMOVE_PAYMENT,
  index,
});

export const setDisplayValue = (value) => ({
  type: SET_DISPLAY_VALUE,
  value,
});

export const splitOrder = (status) => ({
  type: SPLIT_ORDER,
  status,
});

export const setSpliting = (status) => ({
  type: SET_SPLITING,
  status,
});
