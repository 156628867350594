import {all} from 'redux-saga/effects';
import menuSagas from '../Sagas/Menu';
import bookingsSaga from '../Pages/Bookings/saga';
import customerSaga from '../Pages/Customer/saga';
import driversSaga from '../Pages/Drivers/saga';
import tasksSaga from '../Pages/Tasks/saga';
import tillSaga from '../Pages/TillManagement/saga';
import managementSaga from '../Pages/Management/saga';

export default function* rootSaga() {
  yield all([
    menuSagas(),
    bookingsSaga(),
    customerSaga(),
    driversSaga(),
    tasksSaga(),
    tillSaga(),
    managementSaga(),
  ]);
}
