import React from 'react';

function Clickable({
  clickables = [],
  handleClickable,
  selectedClickable,
  handleRemoveClickable,
}) {
  if (clickables.length === 0) {
    return null;
  }
  return (
    <div className="clickables">
      {clickables.map((clickable) => (
        <div className="clickable" key={clickable.id}>
          <button
            type="button"
            className="m-0"
            onClick={() => handleClickable(clickable)}
          >
            {clickable.title}
          </button>
          {selectedClickable?.id === clickable.id && (
            <a href="#" onClick={handleRemoveClickable}>
              <i className="fa fa-times" />{' '}
            </a>
          )}
        </div>
      ))}
    </div>
  );
}

export default Clickable;
