import React from 'react';

function Price({children}) {
  if (children === 0) {
    return <span />;
  }
  return <span>£{parseFloat(children / 100).toFixed(2)}</span>;
}

export default Price;
