import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import checkOnlineStatus from '../Libs/checkOnlineStatus';
import {
  toggleOnlineStatus,
  syncBooking,
  fetchBookings,
} from '../Pages/Bookings/actions';
import {
  makeSelectBookings,
  makeSelectOnline,
} from '../Pages/Bookings/selectors';
import {checkIfOfflineBookingsExists} from '../Libs/bookings';
import {makeSelectLocation} from '../Redux/selectors';
import IdleTimer from '../Libs/IdleTimer';
import {clockOut} from '../Pages/Authentication/actions';
import {makeSelectClockedIn} from '../Pages/Authentication/selectors';

function OnlineStatus() {
  const dispatch = useDispatch();
  const clockedIn = useSelector(makeSelectClockedIn());
  const bookings = useSelector(makeSelectBookings());
  const isOnline = useSelector(makeSelectOnline());
  const location = useSelector(makeSelectLocation());

  useEffect(() => {
    setInterval(async () => {
      const online = await checkOnlineStatus();
      dispatch(toggleOnlineStatus(online));
    }, 30000);
  }, []);

  // useEffect(() => {
  //   const timer = new IdleTimer({
  //     timeout: 60 * 5, // expire after 10 seconds
  //     onTimeout: () => {
  //       if (clockedIn) {
  //         console.log('time out');
  //         dispatch(clockOut());
  //         window.location.href = '/';
  //       }
  //     },
  //     onExpired: () => {
  //       if (clockedIn) {
  //         // do something if expired on load
  //         console.log('expired');
  //         dispatch(clockOut());
  //         window.location.href = '/';
  //       }
  //     },
  //   });
  //
  //   return () => {
  //     timer.cleanUp();
  //   };
  // }, [location]);
  useEffect(() => {
    if (isOnline) {
      if (checkIfOfflineBookingsExists(bookings)) {
        bookings.forEach(async (booking) => {
          if (booking.syncState === 'syncing') {
            dispatch(syncBooking(booking));
          }
        });
      } else {
        dispatch(fetchBookings());
      }
    }
  }, [isOnline]);
  return null;
}

export default OnlineStatus;
