import React, {useState} from 'react';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';
import Label from 'reactstrap/es/Label';
import {Button, FormGroup} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import TextInput from '../../Components/TextInput';
import {makeSelectNotes} from './selectors';
import {addNote, createNote, deleteNote} from './actions';

function ManageNotes({mode, handleSaveNote}) {
  const dispatch = useDispatch();
  const notes = useSelector(makeSelectNotes());
  const [note, setNote] = useState('');

  const handleCreateNote = () => {
    dispatch(createNote(note));
    setNote('');
  };

  const handleAddNote = (n) => {
    if (mode === 'customer') {
      handleSaveNote(n);
    } else {
      dispatch(addNote(n));
    }
  };

  const handleDeleteNote = (index) => {
    dispatch(deleteNote(index));
  };
  return (
    <Row className="manage-notes">
      <Row className="create-notes">
        <Col md="10">
          <FormGroup>
            <Label>Create Note</Label>
            <TextInput
              autoComplete="off"
              inputClassName="form-control"
              type="text"
              value={note}
              onChange={(value) => setNote(value)}
              placeholder="Enter Notes"
            />
          </FormGroup>
          <Button
            onClick={handleCreateNote}
            className="input-checkout bg-dark"
            color="primary"
            size="sm"
          >
            Create
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className="selected-wrapper">
          {notes.map((n, i) => (
            <div className="selected-note" key={n + i}>
              <div className="value" onClick={() => handleAddNote(n)}>
                {n}
              </div>
              <div className="action" onClick={() => handleDeleteNote(i)}>
                X
              </div>
            </div>
          ))}
        </Col>
      </Row>
    </Row>
  );
}

export default ManageNotes;
