/* eslint-disable */
import {fromCents} from './prices';
import moment
  from 'moment';

const totalColsPerPage = 48;
const leftCols = 43;

const init = '\x1B\x40';
const alignCenter = '\x1B\x61\x31';
const alignLeft = '\x1B\x61\x30';
const alignRight = '\x1B\x61\x32';
const newLine = '\x0A';
const bold_on = '\x1B\x45\x0D';
const bold_off = '\x1B\x45\x0A';
const em_mode_on = '\x1B\x21\x30';
const em_mode_off = '\x1B\x21\x0A\x1B\x45\x0A';
const small_text = '\x1B\x4D\x31';
const normal_text = '\x1B\x4D\x25';
const norm_text = '\x1B\x4D\x25';
const big_text = '\x1B\x21\x25';
const bigger_text = '\x1B\x21\x20';
const cutPaper = '\x1B\x69';
const openCashDrawer = '\x10' + '\x14' + '\x01' + '\x00' + '\x05';
const total_amount = 0;
const voucher_amount = 0;

// Pound sign
// \u00a35

/// //////////////////////////////////////////////////////////////////
//
// Server Side method for verifying the certificate
// Recommended: Because the private key is on server
//
/// //////////////////////////////////////////////////////////////////

export const printDriverClockOutSummary = function(taskSummary, session, driver) {
  return [
    init +
    em_mode_on +
    alignCenter +
    getTitle(driver.name) +
    em_mode_off +
    newLine +
    newLine +
    dottedLine() +
    newLine +
    newLine +
    getDescription('Driver Wages Report') +
    newLine +
    newLine +
    dottedLine() +
    newLine +
    newLine +
    getLogReport(session) +
    newLine +
    newLine +
    getDescription('No of Delivery') +
    newLine +
    newLine +
    getDeliveries(taskSummary) +
    em_mode_off +
    alignCenter +
    dottedLine() +
    newLine +
    alignLeft +
    newLine +
    newLine +
    newLine +
    newLine +
    newLine +
    newLine +
    newLine +
    cutPaper,
  ];
};

function getDescription(description) {
  return em_mode_on +
    normal_text +
    description +
    em_mode_off;
}

function getTitle(title) {
  return title;
}


function getLogReport(session) {
  let summaryString = '';
  summaryString += bold_on + getItemRowFull('Date', session.date) + bold_off;
  summaryString += getItemRowFull('Clock In', session.clock_in) + newLine;
  summaryString += getItemRowFull('Clock Out', session.clock_out) + newLine;
  summaryString += getItemRowFull('Total Hours', session.total_hours) + newLine;
  summaryString += getItemRowFull('Total Pay', fromCents(session.total_pay)) + newLine + newLine;
  summaryString += dottedLine() + newLine;
  return summaryString;
}


function getHeaderInitialize() {
  let header = '';
  header = header.padEnd(22, ' ');
  header += 'Deliveries';
  header = header.padEnd(36, ' ');
  header += 'Card';
  header = header.padEnd(42, ' ');
  header += 'Cash';
  header = header.padEnd(48, ' ');
  header += newLine;
  return header;
}

function getDeliveries(taskSummary) {
  let summaryString = '';
  let totalDeliveries = 0;
  let totalCash = 0;
  let totalCard = 0;
  summaryString += alignLeft;
  summaryString += getHeaderInitialize();
  for (let i = 0; i < taskSummary.vendor_names.length; i += 1) {
    totalDeliveries += taskSummary.delivery[i].count;
    totalCash += taskSummary.cash_orders[i].count;
    totalCard += taskSummary.card_orders[i].count;
    summaryString += taskSummary.vendor_names[i].title.padEnd(22, ' ');
    let deliveries = taskSummary.delivery[i].count + '';
    summaryString += deliveries.padEnd(14, ' ');
    let card = taskSummary.card_orders[i].count + '';
    summaryString += card.padEnd(6, ' ');
    let cash = taskSummary.cash_orders[i].count + '';
    summaryString += cash.padEnd(6, ' ');
    summaryString += newLine;
  }
  totalDeliveries = totalDeliveries + '';
  totalCard = totalCard + '';
  totalCash = totalCash + '';
  summaryString += dottedLine() + newLine;
  summaryString += bold_on;
  summaryString += 'Total'.padEnd(22, ' ');
  summaryString += totalDeliveries.padEnd(14, ' ');
  summaryString += totalCard.padEnd(6, ' ');
  summaryString += totalCash.padEnd(6, ' ');
  summaryString += bold_off;
  summaryString += newLine;
  return summaryString;
}


// Custom Details
function getDriverInfo(userAddress) {
  return (
    alignCenter +
    bold_on +
    bigger_text +
    maxRound('Driver Info') +
    bold_off +
    newLine +
    alignLeft +
    big_text +
    maxRound(userAddress.name) +
    newLine +
    maxRound(userAddress.address) +
    newLine +
    maxRound(`${userAddress.town}, ${userAddress.postcode}`) +
    newLine +
    maxRound(`P: ${userAddress.telephone || userAddress.mobile}`) +
    newLine +
    normal_text
  );
}

function getItemRowFull(itemTitle, itemPrice = '') {
  const price = itemPrice.toString();
  const marginSpace = totalColsPerPage === 48 ? '' : '   ';

  let colsLeft = leftCols;
  if (itemTitle.length > leftCols) {
    colsLeft = !price ? totalColsPerPage - 5 : colsLeft;
    let rightCols = !price ? 5 : price.length;

    const itemTitleLength = itemTitle.length;
    let receipt = '';
    let startFrom = 0;
    let remainingCharacters = itemTitle.length;

    while (startFrom < itemTitleLength) {
      receipt +=
        /* alignLeft + */ marginSpace +
        itemTitle
          .substr(startFrom, colsLeft)
          .padEnd(totalColsPerPage - rightCols, ' ') +
        (startFrom === 0 ? price : '');
      receipt += newLine;
      remainingCharacters =
        remainingCharacters + itemTitleLength - (itemTitleLength + colsLeft);
      startFrom += colsLeft;
    }
    return receipt;
  }
  return (
    marginSpace +
    itemTitle.padEnd(totalColsPerPage - price.length, ' ') + price
  );
}

function maxRound(text) {
  const re = new RegExp(`.{1,${leftCols}}`, 'g');
  const chunk = text.match(re);
  const spaced = chunk.map((i) => `  ${i}`);
  return spaced.join(newLine);
}


function getPrice(price) {
  return parseFloat(price / 100).toFixed(2);
}

function dottedLine() {
  if (totalColsPerPage === 48) {
    return `${alignCenter}------------------------------------------------`;
  }
  return `${alignCenter}------------------------------------------`;
}
