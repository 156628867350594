import React from 'react';
import Col from 'reactstrap/es/Col';
import Row from 'reactstrap/es/Row';
import Master from '../Master';
import ManageNotes from './ManageNotes';
import SelectedNotes from './SelectedNotes';

function Notes() {
  return (
    <Master>
      <div className="customer-container notes">
        <Row>
          <Col className="p-0">
            <SelectedNotes />
          </Col>
          <Col className="p-0">
            <ManageNotes />
          </Col>
        </Row>
      </div>
    </Master>
  );
}

export default Notes;
