import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';
import TopNav from '../Components/TopNav';
import Cart from '../Components/Cart';
import Content from '../Components/Content';
import FooterNav from '../Components/FooterNav';
import Firebase from '../Components/Firebase';
import {
  makeSelectClockedIn,
  makeSelectUserPermissions,
} from './Authentication/selectors';

const Master = ({children}) => {
  const clockedIn = useSelector(makeSelectClockedIn());
  const permissions = useSelector(makeSelectUserPermissions());
  if (!clockedIn || permissions.dashboard_access === false) {
    return <Redirect to="/" />;
  }
  return (
    <>
      <TopNav />
      <Firebase />
      <div className="wrapper">
        <Cart />
        <Content>
          {children}
          <FooterNav />
        </Content>
      </div>
    </>
  );
};

export default Master;
