import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';
import {makeSelectDrivers} from './selectors';
import {makeSelectHotel} from '../Authentication/selectors';
import {getOnlineDrivers} from '../../Libs/users';
import {fetchDrivers} from './actions';

function Drivers() {
  const dispatch = useDispatch();
  const {branch} = useSelector(makeSelectHotel());
  const drivers = useSelector(makeSelectDrivers());

  useEffect(() => {
    dispatch(fetchDrivers());
  }, []);
  return (
    <div className="drivers">
      <Link to="/" className="go-back">
        Go Back
      </Link>
      <Row>
        <Col>
          <h2 className="title">{branch.official_title}</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="header">DRIVERS</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="driver-wrapper p-0">
            <Link to="/members/clock-in" className="clock-in-btn" type="button">
              Clock In ({getOnlineDrivers(drivers)})
            </Link>
            <Link
              to="/members/clock-out"
              className="clock-out-btn"
              type="button"
            >
              Clock Out
            </Link>
          </div>
        </Col>
      </Row>
      <div className="driver-wrapper">
        {drivers.map((driver) => (
          <Link
            className={driver.status === 'clocked-in' ? 'online' : ''}
            to={`/members/sales/${driver.id}`}
            key={driver.id}
          >
            {driver.name}
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Drivers;
