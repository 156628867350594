import React from 'react';
import {useSelector} from 'react-redux';
import Price from '../../Components/Price';
import printOfflineBooking from '../../Libs/printOfflineBooking';
import {makeSelectHotel} from '../Authentication/selectors';
import {paidStatus} from '../../Libs/bookings';
import {makeSelectDefaultPrinter} from '../Management/selectors';

function LocalBooking({booking}) {
  const {branch} = useSelector(makeSelectHotel());
  const defaultPrinter = useSelector(makeSelectDefaultPrinter());
  return (
    <div className="booking offline">
      <div className="booking-info">
        <div className="platform">
          <span className="platform-button bg-dark">POS</span>
        </div>
        <div className="address-details">
          <div className="address">{booking.formData.address}</div>
          <div className="postcode">{booking.formData.postcode}</div>
          <div className="order-id">{booking.formData.telephone}</div>
        </div>
      </div>
      <div className="payment-info">
        <div className="amount">
          <Price>{booking.sub_total}</Price>
        </div>
        <div className="status">{paidStatus(booking.payments)}</div>
      </div>
      <div className="time-info">
        <p className="status">
          <strong className={booking.checkout_mode.toLowerCase()}>
            {booking.checkout_mode === 'Delivery' ? 'Delivery' : 'Collection'}
          </strong>
        </p>
        <p className="value">{booking.checkout_time}</p>
      </div>
      <button
        type="button"
        onClick={() =>
          printOfflineBooking(booking, branch, false, [defaultPrinter])
        }
        className="payment-method"
      >
        <p>PRINT</p>
      </button>
    </div>
  );
}

export default LocalBooking;
