import {createSelector} from 'reselect';

export const selectAuth = (state) => state.get('auth');

export const makeSelectUser = () =>
  createSelector(selectAuth, (AuthState) => AuthState.get('user').toJS());

export const makeSelectHotel = () =>
  createSelector(selectAuth, (AuthState) => AuthState.get('hotel').toJS());

export const makeSelectToken = () =>
  createSelector(selectAuth, (AuthState) => AuthState.get('token'));

export const makeSelectClockedIn = () =>
  createSelector(selectAuth, (AuthState) => AuthState.get('isClockedIn'));

export const makeSelectWalkInUser = () =>
  createSelector(selectAuth, (AuthState) =>
    AuthState.getIn([
      'hotel',
      'branch',
      'walk_in_user',
      'walk_in_user_address',
    ])?.toJS(),
  );

export const makeSelectUserPermissions = () =>
  createSelector(
    selectAuth,
    (AuthState) => AuthState.getIn(['user', 'permissions'])?.toJS() || [],
  );
