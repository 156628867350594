import React from 'react';
import {Switch, Route, Link, Redirect} from 'react-router-dom';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';
import {useSelector} from 'react-redux';
import Create from './Create';
import TasksList from './TasksList';
import {
  makeSelectClockedIn,
  makeSelectHotel,
} from '../Authentication/selectors';
import Firebase from '../../Components/Firebase';
import Edit from './Edit';

function Tasks() {
  const {branch} = useSelector(makeSelectHotel());
  const clockedIn = useSelector(makeSelectClockedIn());

  if (!clockedIn) {
    return <Redirect to="/" />;
  }
  return (
    <div className="tasks">
      <Firebase />
      <Link to="/" className="go-back">
        Go Back
      </Link>
      <Row>
        <Col>
          <h2 className="title">{branch.official_title}</h2>
        </Col>
      </Row>
      <Switch>
        <Route exact path="/tasks" component={TasksList} />
        <Route exact path="/tasks/create" component={Create} />
        <Route exact path="/tasks/:id" component={Edit} />
      </Switch>
    </div>
  );
}

export default Tasks;
