import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Master from '../Master';
import SearchCustomers from './SearchCustomers';
import AddNotes from './AddNotes';

import {makeSelectUserPermissions} from '../Authentication/selectors';

function Customers() {
  const permissions = useSelector(makeSelectUserPermissions());
  if (!permissions.dashboard_access === true) {
    return <Redirect to="/" />;
  }
  return (
    <Master>
      <Switch>
        <Route exact path="/customers" component={SearchCustomers} />
        <Route exact path="/customers/:id/add-notes" component={AddNotes} />
      </Switch>
    </Master>
  );
}

export default Customers;
