import React, {Component} from 'react';
import {connect} from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/database';
import {createStructuredSelector} from 'reselect';
import {withRouter} from 'react-router-dom';
import {firebaseRef, config} from '../Configs/Firebase';
import {clearCustomer} from '../Pages/Customer/actions';
import {
  makeSelectHotel,
  makeSelectToken,
} from '../Pages/Authentication/selectors';
import {QzCertificate} from '../Configs/QZTray';
import {checkIfObjectEmpty} from '../Libs/object';
import IncomingCall from './IncomingCall';

firebase.initializeApp(config);

class Firebase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calls: [],
    };
  }

  componentDidMount() {
    const {
      token,
      hotel: {branch},
    } = this.props;
    QzCertificate(token);

    // this.addCall({
    //   branch_id: 1,
    //   callId:
    //     '54566B150B0C0D3A5D50425B52567D5C57525056565D5C5D7C5852564550455647444C5E5A57',
    //   direction: 'in',
    //   event: 'newCall',
    //   from: '449999999999',
    //   fullUserId: ['3175093w0'],
    //   origCallId:
    //     '54566B150B0C0D3A5D50425B52567D5C57525056565D5C5D7C5852564550455647444C5E5A57',
    //   status: 'call_answered',
    //   timestamp: '2021-05-16T20:34:47.431701Z',
    //   to: '441293730112',
    //   user: ['Naseer Ishaq'],
    //   userId: ['w0'],
    //   xcid: '073e4ee15d22a0fd',
    // });

    this.unsubscribe = firebase
      .database()
      .ref(firebaseRef)
      .on('child_changed', (snapshot) => {
        if (!checkIfObjectEmpty(snapshot)) {
          const response = snapshot.val();
          if (
            !checkIfObjectEmpty(response) &&
            response.branch_id === branch.id
          ) {
            this.addCall(response);
          }
        }
      });
  }

  onProcessCall = (call, status) => {
    // const {onClearCustomer} = this.props;
    firebase
      .database()
      .ref(`${firebaseRef}/${call.from}`)
      .set({
        ...call,
        status,
      });
    // onClearCustomer();
  };

  componentWillUnmount() {
    this.unsubscribe();
  }

  addCall = (response) => {
    const {calls} = this.state;
    const index = calls.findIndex((call) => call.from === response.from);
    if (index === -1) {
      this.setState({
        calls: [...calls, response],
      });
    } else {
      this.setState({
        calls: calls.map((call) => {
          if (call.from === response.from) {
            return response;
          }
          return call;
        }),
      });
    }
  };

  render() {
    const {calls} = this.state;
    // console.log('calls', calls);
    return (
      <div className="incoming-calls">
        {calls.map((call) => (
          <IncomingCall
            key={call.callId}
            call={call}
            onProcessCall={this.onProcessCall}
          />
        ))}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onClearCustomer: () => dispatch(clearCustomer()),
});

const mapStateToProps = createStructuredSelector({
  token: makeSelectToken(),
  hotel: makeSelectHotel(),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Firebase));
