import React from 'react';
import {Col} from 'reactstrap';
import {useSelector} from 'react-redux';
import {getProperPrice, priceByMode} from '../../Libs/prices';
import Price from '../../Components/Price';
import {makeSelectCheckoutMode} from '../../Components/Cart/selectors';

function Sizes({sizes, setTier, tierDetails}) {
  const mode = useSelector(makeSelectCheckoutMode());
  return sizes.map((size) => {
    const prices = getProperPrice(size);
    return (
      <Col
        md="3"
        className="paddingLeft0"
        key={size.id}
        onClick={() =>
          setTier({
            ...tierDetails,
            selected: {
              ...size,
              price: prices,
              quantity: 1,
            },
          })
        }
      >
        <div className="prod-list">
          <div className="prod-title">
            <h6>{size.title}</h6>
          </div>
          <div className="prod-price">
            <h6>
              <Price>{priceByMode(prices, mode)}</Price>
            </h6>
          </div>
        </div>
      </Col>
    );
  });
}

export default Sizes;
