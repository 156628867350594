import React from 'react';
import {v4 as uid} from 'uuid';
import {Row, Col, ListGroup, ListGroupItem, DropdownToggle} from 'reactstrap';
import {useSelector} from 'react-redux';
import Button from 'reactstrap/es/Button';
import {cartTotal, getDiscountPrice, productPrice} from '../../Libs/prices';
import {
  makeSelectCheckoutMode,
  makeSelectCustomer,
  makeSelectDiscount,
  makeSelectProducts,
  makeSelectSelectedIndex,
} from '../../Components/Cart/selectors';
import Price from '../../Components/Price';
import {checkIfObjectEmpty} from '../../Libs/object';

const CustomerCart = () => {
  const products = useSelector(makeSelectProducts());
  const checkoutMode = useSelector(makeSelectCheckoutMode());
  const discount = useSelector(makeSelectDiscount());
  const customer = useSelector(makeSelectCustomer());
  const mode = useSelector(makeSelectCheckoutMode());
  const selectedIndex = useSelector(makeSelectSelectedIndex());
  const subTotal = cartTotal(products, mode);
  const discountedPrice = getDiscountPrice(discount, subTotal);

  const renderCartItems = () => {
    return products.map((product, i) => (
      <ListGroupItem key={uid()}>
        <Row>
          <div className="cart-content">
            <div
              className={`row m-0 justify-content-between align-items-center p-1 mb-1 ${
                selectedIndex === i ? 'cart-active' : ''
              }`}
            >
              <strong className="item-title">
                <strong>{product.quantity}x </strong>
                {product.title}
              </strong>
              <strong className="item-price">
                <Price>{productPrice(product, mode)}</Price>
              </strong>
            </div>
            <Row>
              <ul className="tree-view">
                {product.extras?.length > 0 && (
                  <div className="tier">
                    <h2 className="tier-title">Extras</h2>
                    <ul className="tier-value addon">
                      {product.extras?.map((item, index) => (
                        <li key={item.id}>
                          {item.title}{' '}
                          {product.hold.length > index ? (
                            <strong>(free)</strong>
                          ) : (
                            <strong>
                              (<Price>{item.price}</Price>)
                            </strong>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {product.hold?.length > 0 && (
                  <div className="tier">
                    <h2 className="tier-title">Hold</h2>
                    <ul className="tier-value addon">
                      {product.hold?.map((item) => (
                        <li key={item}>{item} </li>
                      ))}
                    </ul>
                  </div>
                )}
                {product.tiers.length > 0 &&
                  product.tiers.map((tier) => {
                    if (checkIfObjectEmpty(tier)) {
                      return null;
                    }
                    if (tier.type === 'Addon') {
                      return (
                        <div className="tier" key={tier.id + i}>
                          <h2 className="tier-title">{tier.basket_title}</h2>
                          <ul className="tier-value addon">
                            {tier.selected.free &&
                              tier.selected.free.map((addon) => (
                                <li key={addon.id}>
                                  <strong>{addon.quantity}x</strong>{' '}
                                  {addon.title} <strong>(free)</strong>
                                </li>
                              ))}
                            {tier.selected.paid &&
                              tier.selected.paid.map((addon) => (
                                <li key={addon.id}>
                                  <strong>{addon.quantity}x</strong>{' '}
                                  {addon.title} {'  '}
                                  <strong>
                                    <Price>{addon.price}</Price>
                                  </strong>
                                </li>
                              ))}
                          </ul>
                        </div>
                      );
                    }
                    return (
                      <li key={uid()}>
                        <b>{tier.basket_title}</b> : {tier.selected.title}{' '}
                        {'  '}
                        <strong>
                          <Price>{tier.selected.price}</Price>
                        </strong>
                      </li>
                    );
                  })}
              </ul>
            </Row>
          </div>
        </Row>
      </ListGroupItem>
    ));
  };

  return (
    <div className="cart">
      <nav id="sidebar">
        <div className="sidebar-header">
          <Row>
            <Col md="3">
              <button type="button" className="cartHeader">
                {checkoutMode}
              </button>
            </Col>
            <Col md="9">
              <Button
                type="button"
                className="cartHeader"
                style={{float: 'right'}}
              >
                {checkIfObjectEmpty(customer) ? '' : customer.name}
              </Button>
            </Col>
          </Row>
        </div>
        <div className="cart-items">
          <ListGroup flush>
            {products.length > 0 ? (
              renderCartItems()
            ) : (
              <ListGroupItem>
                <center>
                  <small>No item in cart..</small>
                </center>
              </ListGroupItem>
            )}
          </ListGroup>
        </div>
        <div className="sub-total">
          <span>SUB TOTAL</span>
          <span className="subAmount">
            <Price>{subTotal}</Price>
          </span>
        </div>
        <div className="discount">
          <span>DISCOUNT</span>
          <span className="subAmount">
            <Price>{discountedPrice}</Price>
          </span>
        </div>
        <div className="payable">
          <span>PAYABLE</span>
          <span className="subAmount">
            <Price>{subTotal - discountedPrice}</Price>
          </span>
        </div>
      </nav>
    </div>
  );
};

export default CustomerCart;
