import React from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.css';
import './Assets/styles/favorite.scss';
import {Provider} from 'react-redux';
import Root from './Pages/Root';

import configureStore from './Redux/Store';
import OnlineStatus from './Components/OnlineStatus';

const {store, persistedStore} = configureStore({});

ReactDOM.render(
  <Provider store={store}>
    <OnlineStatus />
    <Root />
  </Provider>,
  document.getElementById('root'),
);
