import {fromJS} from 'immutable';

import {
  OPEN_TILL,
  CLOSE_TILL,
  CLEAR_TILL,
  FETCH_TILL,
  FETCH_TILL_SUCCESS,
  FETCH_TILL_ERROR,
} from './constants';

const initialState = fromJS({
  status: 'close',
  state: 'initial',
  openingTotal: '',
  closingTotal: '',
  openingTime: '',
  closingTime: '',
});

export default function tillReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_TILL:
      return state
        .set('status', 'open')
        .set('openingTotal', action.total)
        .set('openingTime', action.time);
    case CLOSE_TILL:
      return state
        .set('status', 'close')
        .set('closingTotal', action.total)
        .set('closingTime', action.time);
    case FETCH_TILL:
      return state.set('state', 'loading', 'status', '');
    case FETCH_TILL_SUCCESS:
      return state
        .set('state', 'success')
        .set('status', action.till.status)
        .set('openingTotal', action.till.openingTotal)
        .set('openingTime', action.till.openingTime)
        .set('closingTotal', action.till.closingTotal)
        .set('closingTime', action.till.closingTime);
    case FETCH_TILL_ERROR:
      return state.set('state', 'error');
    case CLEAR_TILL:
      return initialState;
    default:
      return state;
  }
}
