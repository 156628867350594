import React from 'react';
import Option from './Option';
import Addons from './Addons';
import Product from './Product';
import Size from './Size';
import {checkIfObjectEmpty} from '../../Libs/object';

const Tier = ({tier, mode}) => {
  const returnTierType = () => {
    switch (tier.type) {
      case 'Option':
        return (
          <Option
            mode={mode}
            title={tier.basket_title}
            option={tier.booking_tier_options}
          />
        );
      case 'Addon':
        return (
          <Addons
            mode={mode}
            title={tier.basket_title}
            addons={tier.booking_tier_addons}
          />
        );
      case 'Product':
        return (
          <Product
            mode={mode}
            title={tier.basket_title}
            product={tier.booking_tier_products}
          />
        );
      case 'Size':
        return (
          <Size
            mode={mode}
            title={tier.basket_title}
            size={tier.booking_tier_sizes}
          />
        );
      default:
        return null;
    }
  };
  if (checkIfObjectEmpty(tier)) {
    return null;
  }
  return <div className="tier">{returnTierType()}</div>;
};

export default Tier;
