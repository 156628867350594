import {
  FETCH_DRIVERS,
  FETCH_DRIVERS_ERROR,
  FETCH_DRIVERS_SUCCESS,
} from './constants';

export const fetchDrivers = () => ({
  type: FETCH_DRIVERS,
});

export const fetchDriversSuccess = (drivers) => ({
  type: FETCH_DRIVERS_SUCCESS,
  drivers,
});

export const fetchDriversError = (status) => ({
  type: FETCH_DRIVERS_ERROR,
  status,
});
