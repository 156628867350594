export const FETCH_VENDORS = 'Favorite/Tasks/FETCH_VENDORS';

export const FETCH_VENDORS_SUCCESS = 'Favorite/Tasks/FETCH_VENDORS_SUCCESS';

export const FETCH_VENDORS_ERROR = 'Favorite/Tasks/FETCH_VENDORS_ERROR';

export const FETCH_TASKS = 'Favorite/Tasks/FETCH_TASKS';

export const FETCH_TASKS_SUCCESS = 'Favorite/Tasks/FETCH_TASKS_SUCCESS';

export const FETCH_TASKS_ERROR = 'Favorite/Tasks/FETCH_TASKS_ERROR';
