import {call, put, takeLatest, select} from 'redux-saga/effects';

import apiUrl from '../../Configs/ApiUrl';
import apiCall from '../../Services/ApiCall';
import {FETCH_DRIVERS} from './constants';
import {fetchDriversError, fetchDriversSuccess} from './actions';
import {makeSelectHotel, makeSelectToken} from '../Authentication/selectors';
import AuthorizedGetHeaders from '../../Configs/AuthorizedGetHeaders';

export function* fetchDrivers() {
  const {branch} = yield select(makeSelectHotel());
  const token = yield select(makeSelectToken());
  if (!branch) {
    return;
  }
  const requestUrl = `${apiUrl}/drivers/byBranchID/${branch.id}`;
  try {
    const drivers = yield call(
      apiCall,
      requestUrl,
      AuthorizedGetHeaders(token),
    );
    yield put(fetchDriversSuccess(drivers || []));
  } catch (e) {
    yield put(fetchDriversError(e.message));
    // console.log('err occurred', e);
  }
}

export default function* driversSaga() {
  yield takeLatest(FETCH_DRIVERS, fetchDrivers);
}
