import {createSelector} from 'reselect';

export const selectCart = (state) => state.get('cart');

export const makeSelect = () =>
  createSelector(selectCart, (CartState) => CartState.get('').toJS());

export const makeSelectCustomer = () =>
  createSelector(selectCart, (CartState) => CartState.get('customer').toJS());

export const makeSelectCheckoutMode = () =>
  createSelector(selectCart, (CartState) => CartState.get('checkoutMode'));

export const makeSelectDiscount = () =>
  createSelector(selectCart, (CartState) => CartState.get('discount').toJS());

export const makeSelectProducts = () =>
  createSelector(selectCart, (CartState) => CartState.get('products').toJS());

export const makeSelectSelectedNotes = () =>
  createSelector(selectCart, (CartState) => CartState.get('notes').toJS());

export const makeSelectSelectedIndex = () =>
  createSelector(selectCart, (CartState) => CartState.get('selectedIndex'));

export const makeSelectSelectedProduct = () =>
  createSelector(selectCart, (CartState) =>
    CartState.get('selectedProduct').toJS(),
  );

export const makeSelectOperation = () =>
  createSelector(selectCart, (CartState) => CartState.get('operation').toJS());

export const makeSelectBookingMode = () =>
  createSelector(selectCart, (CartState) => CartState.get('bookingMode'));

export const makeSelectBookingId = () =>
  createSelector(selectCart, (CartState) => CartState.get('bookingId'));
