import React, {useState} from 'react';
import Row from 'reactstrap/es/Row';
import FormGroup from 'reactstrap/es/FormGroup';
import Button from 'reactstrap/es/Button';
import Alert from 'reactstrap/es/Alert';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Loader from '../../Components/Loader';
import TextInput from '../../Components/TextInput';
import {makeSelectToken} from '../Authentication/selectors';
import apiCall from '../../Services/ApiCall';
import apiUrl from '../../Configs/ApiUrl';
import AuthorizedGetHeaders from '../../Configs/AuthorizedGetHeaders';
import {stripZero} from '../../Libs/object';

function SearchCustomers() {
  const token = useSelector(makeSelectToken());
  const [q, setQ] = useState('');
  const [state, setState] = useState('initial');
  const [status, setStatus] = useState('');
  const [customers, setCustomers] = useState([]);

  const handleSearchCustomers = () => {
    setState('loading');
    setStatus('');
    apiCall(
      `${apiUrl}/pos/users/search/${encodeURI(stripZero(q))}`,
      AuthorizedGetHeaders(token),
    )
      .then((users) => {
        setState('success');
        setCustomers(users);
        // console.log('users', users);
      })
      .catch((e) => {
        setState('error');
        setStatus(e.message);
        console.log('error', e);
      });
  };
  return (
    <div className="container-fluid customer-container">
      <div className="customers">
        <Row className="px-3">
          <FormGroup className="search-bookings w-100 d-flex">
            <TextInput
              isFirstLetterUppercase={false}
              inputClassName="form-control"
              onChange={(value) => setQ(value)}
              value={q}
              type="text"
              placeholder="name, postcode or telephone"
            />
            <Button className="btn-refresh" onClick={handleSearchCustomers}>
              Search
            </Button>
          </FormGroup>
        </Row>
        {state === 'error' ? (
          <Row>
            <Alert color="default">{status}</Alert>
          </Row>
        ) : null}
        {state === 'loading' ? (
          <div className="loading-wrapper">
            <Loader color="black" />
          </div>
        ) : null}
        {state !== 'loading' && customers.length
          ? customers.map((customer) => (
              <div className="customer" key={customer.id}>
                <div className="user-info">
                  <div className="email">Email: {customer.email}</div>
                  <Link
                    className="add-notes"
                    to={`/customers/${customer.id}/add-notes`}
                  >
                    ADD NOTES
                  </Link>
                </div>
                <div className="addresses">
                  {customer.user_addresses.map((address) => (
                    <div className="address" key={address.id}>
                      <div className="name">
                        Name: <strong>{address.name}</strong>
                      </div>
                      <div className="telephone">
                        Telephone: <strong>{address.telephone}</strong>
                      </div>
                      <div className="location">
                        Address: <strong>{address.address}</strong>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))
          : null}
      </div>
    </div>
  );
}

export default SearchCustomers;
