import React from 'react';
import Addons from './Addons';
import Sizes from './Sizes';
import Options from './Options';
import TierProducts from './TierProducts';
import Clickables from './Clickables';
import {checkIfObjectEmpty} from '../../Libs/object';

function Tier({
  tier,
  // selectedTier,
  setClickable,
  setTier,
  selectedSize,
  clickables,
}) {
  if (checkIfObjectEmpty(tier)) {
    return null;
  }
  const tierDetails = {
    id: tier.id,
    product_id: tier.product_id,
    type_id: tier.type_id,
    type: tier.type,
    title: tier.title,
    basket_title: tier.basket_title,
    free_items: tier.free_items,
    max_items: tier.max_items,
    required: tier.required,
    selected: {},
  };
  switch (tier.type) {
    case 'Size':
      return (
        <Sizes setTier={setTier} tierDetails={tierDetails} sizes={tier.sizes} />
      );
    case 'Option':
      return (
        <Options
          tierDetails={tierDetails}
          setTier={setTier}
          options={tier.options}
          selectedSize={selectedSize}
        />
      );
    case 'Addon':
      return (
        <Addons
          addons={tier.addons}
          setTier={setTier}
          size={selectedSize}
          tierDetails={tierDetails}
        />
      );
    case 'Product':
      return (
        <TierProducts
          selectedSize={selectedSize}
          setTier={setTier}
          tierDetails={tierDetails}
          products={tier.products}
        />
      );
    case 'Clickable':
      return <Clickables clickables={clickables} setClickable={setClickable} />;
    default:
      return null;
  }
}

export default Tier;
