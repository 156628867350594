import React from 'react';
import EditSize from './EditSize';
import EditOption from './EditOption';
import EditTierProduct from './EditTierProduct';
import EditAddons from './EditAddons';

function EditTier({
  tier,
  size,
  selected,
  updateOption,
  updateSize,
  updateProduct,
  addAddon,
  removeAddon,
}) {
  switch (tier.type) {
    case 'Option':
      return (
        <EditOption
          options={tier.options}
          selectedOption={selected ? selected.toJS() : {}}
          size={size}
          updateOption={updateOption}
        />
      );
    case 'Size': {
      return (
        <EditSize
          sizes={tier.sizes}
          updateSize={updateSize}
          selectedSize={selected ? selected.toJS() : {}}
        />
      );
    }
    case 'Product':
      return (
        <EditTierProduct
          products={tier.products}
          selectedProduct={selected ? selected.toJS() : {}}
          updateProduct={updateProduct}
        />
      );
    case 'Addon':
      return (
        <EditAddons
          tier={tier}
          selectedAddons={selected ? selected.toJS() : {}}
          addAddon={addAddon}
          removeAddon={removeAddon}
          size={size}
        />
      );
    default:
      return null;
  }
}

export default EditTier;
