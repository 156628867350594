import {
  CLEAR_CUSTOMER,
  FIND_CUSTOMER,
  FIND_CUSTOMER_ERROR,
  FIND_CUSTOMER_SUCCESS,
} from './constants';

export const findCustomer = (q, mode) => ({
  type: FIND_CUSTOMER,
  q,
  mode,
});

export const findCustomerSuccess = (customer, notes, products) => ({
  type: FIND_CUSTOMER_SUCCESS,
  customer,
  notes,
  products,
});

export const findCustomerError = (status) => ({
  type: FIND_CUSTOMER_ERROR,
  status,
});

export const clearCustomer = () => ({
  type: CLEAR_CUSTOMER,
});
