import React from 'react';
import {useSelector} from 'react-redux';
import {makeSelectDrivers} from '../Drivers/selectors';

function Drivers({handleAssign, handleCancel}) {
  const drivers = useSelector(makeSelectDrivers());

  const handleAssignDriver = (driver) => {
    if (driver.status !== 'clocked-out') {
      handleAssign(driver);
    }
  };

  return (
    <div className="task-drivers">
      {handleCancel && (
        <i onClick={handleCancel} className="cancel-action fa fa-times" />
      )}
      {drivers.map((driver) => (
        <button
          type="button"
          disabled={driver.state === 'clocked-out'}
          className={driver.status === 'clocked-in' ? 'online' : ''}
          key={driver.id}
          onClick={() => handleAssignDriver(driver)}
        >
          {driver.name}
        </button>
      ))}
    </div>
  );
}

export default Drivers;
