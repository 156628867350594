import {call, all, put, takeLatest, select} from 'redux-saga/effects';

import apiUrl from '../../Configs/ApiUrl';
import apiCall from '../../Services/ApiCall';
import {
  ADD_PRINTER,
  GET_PRINTERS,
  REMOVE_PRINTER,
  UPDATE_PRINTER,
} from './constants';
import {
  addPrinterError,
  addPrinterSuccess,
  getPrintersError,
  getPrintersSuccess,
  removePrinterError,
  removePrinterSuccess,
  updatePrinterError,
  updatePrinterSuccess,
} from './actions';
import AuthorizedPostHeaders from '../../Configs/AuthorizedPostHeaders';
import {makeSelectHotel, makeSelectToken} from '../Authentication/selectors';
import AuthorizedDeleteHeaders from '../../Configs/AuthorizedDeleteHeaders';
import AuthorizedPutHeaders from '../../Configs/AuthorizedPutHeaders';

export function* getPrinters() {
  const token = yield select(makeSelectToken());
  const requestUrl = `${apiUrl}/settings/allSettingsCustomByModuleID`;
  try {
    const printers = yield call(
      apiCall,
      requestUrl,
      AuthorizedPostHeaders(
        {
          module_id: '2',
        },
        token,
      ),
    );
    yield put(getPrintersSuccess(printers.default || {}, printers.printers));
  } catch (e) {
    // console.log('err occurred', e);
    yield put(getPrintersError(e.message));
  }
}

export function* addPrinter(action) {
  console.log('action', action);
  const token = yield select(makeSelectToken());
  const {branch} = yield select(makeSelectHotel());
  const requestUrl = `${apiUrl}/settings`;
  try {
    const printer = yield call(
      apiCall,
      requestUrl,
      AuthorizedPostHeaders(
        {
          module_id: '2',
          branch_id: branch.id,
          printer: action.printer,
        },
        token,
      ),
    );
    yield put(addPrinterSuccess(printer, action.isDefault));
  } catch (e) {
    // console.log('err occurred', e);
    yield put(addPrinterError(e.message));
  }
}

export function* updatePrinter(action) {
  const token = yield select(makeSelectToken());
  const {branch} = yield select(makeSelectHotel());
  const requestUrl = `${apiUrl}/settings/${action.printer.id}`;
  try {
    const printer = yield call(
      apiCall,
      requestUrl,
      AuthorizedPutHeaders(
        {
          branch_id: branch.id,
          module_id: '2',
          printer: action.printer,
        },
        token,
      ),
    );
    yield put(updatePrinterSuccess(printer, action.index, action.isDefault));
  } catch (e) {
    // console.log('err occurred', e);
    yield put(updatePrinterError(e.message));
  }
}

export function* removePrinter(action) {
  const token = yield select(makeSelectToken());
  const {branch} = yield select(makeSelectHotel());
  const requestUrl = `${apiUrl}/settings/delete/${action.printerId}`;
  try {
    yield call(
      apiCall,
      requestUrl,
      AuthorizedDeleteHeaders(
        {
          branch_id: branch.id,
        },
        token,
      ),
    );
    yield put(removePrinterSuccess(action.index));
  } catch (e) {
    // console.log('err occurred', e);
    yield put(removePrinterError(e.message));
  }
}

export function* getPrintersSaga() {
  yield takeLatest(GET_PRINTERS, getPrinters);
}

export function* addPrinterSaga() {
  yield takeLatest(ADD_PRINTER, addPrinter);
}

export function* updatePrinterSaga() {
  yield takeLatest(UPDATE_PRINTER, updatePrinter);
}

export function* removePrinterSaga() {
  yield takeLatest(REMOVE_PRINTER, removePrinter);
}

export default function* managementSaga() {
  yield all([
    getPrintersSaga(),
    addPrinterSaga(),
    updatePrinterSaga(),
    removePrinterSaga(),
  ]);
}
