import {createSelector} from 'reselect';

export const selectCustomer = (state) => state.get('customer');

export const makeSelectState = () =>
  createSelector(selectCustomer, (CustomerState) => CustomerState.get('state'));

export const makeSelectCustomer = () =>
  createSelector(selectCustomer, (CustomerState) =>
    CustomerState.get('customer').toJS(),
  );

export const makeSelectCustomerNotes = () =>
  createSelector(selectCustomer, (CustomerState) =>
    CustomerState.get('customerNotes').toJS(),
  );

export const makeSelectStatus = () =>
  createSelector(selectCustomer, (CustomerState) =>
    CustomerState.get('status'),
  );

export const makeSelectCustomerNumber = () =>
  createSelector(selectCustomer, (CustomerState) =>
    CustomerState.get('customerNumber'),
  );
