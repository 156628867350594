import React, {useState} from 'react';
import Row from 'reactstrap/es/Row';
import {useDispatch, useSelector} from 'react-redux';
import Col from 'reactstrap/es/Col';
import Label from 'reactstrap/es/Label';
import {Button, FormGroup} from 'reactstrap';
import TextInput from '../../Components/TextInput';
import {makeSelectSelectedNotes} from '../../Components/Cart/selectors';
import {addNote, removeNote} from './actions';

function SelectedNotes({mode, notes, handleUnSaveNote, handleSaveNote}) {
  const dispatch = useDispatch();
  const selectedNotes = useSelector(makeSelectSelectedNotes());
  const [note, setNote] = useState('');
  const handleAddNotes = () => {
    if (mode === 'customer') {
      handleSaveNote(note);
    } else {
      dispatch(addNote(note));
    }
    setNote('');
  };
  const handleRemoveNotes = (index) => {
    dispatch(removeNote(index));
  };
  return (
    <Row className="selected-notes">
      <Row className="add-notes">
        <Col md="12">
          <FormGroup>
            <Label>Add Note</Label>
            <TextInput
              autoComplete="off"
              inputClassName="form-control"
              type="text"
              value={note}
              onChange={(value) => setNote(value)}
              placeholder="Enter Notes"
            />
            <Button
              onClick={handleAddNotes}
              className="input-checkout bg-dark"
              color="primary"
              size="sm"
            >
              ADD
            </Button>
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="p-0">
          <h6>Selected Notes</h6>
        </Col>
      </Row>
      <Row>
        <Col className="selected-wrapper">
          {mode === 'customer'
            ? notes.map((n, i) => (
                <div className="selected-note" key={n + i}>
                  <div className="value">{n}</div>
                  <div className="action" onClick={() => handleUnSaveNote(i)}>
                    X
                  </div>
                </div>
              ))
            : selectedNotes.map((n, i) => (
                <div className="selected-note" key={n + i}>
                  <div className="value">{n}</div>
                  <div className="action" onClick={() => handleRemoveNotes(i)}>
                    X
                  </div>
                </div>
              ))}
        </Col>
      </Row>
    </Row>
  );
}

export default SelectedNotes;
