import {call, put, takeLatest, select, all} from 'redux-saga/effects';

import apiUrl from '../../Configs/ApiUrl';
import apiCall from '../../Services/ApiCall';
import {FETCH_TASKS, FETCH_VENDORS} from './constants';
import {
  fetchTasksError,
  fetchTasksSuccess,
  fetchVendorsError,
  fetchVendorsSuccess,
} from './actions';
import {makeSelectHotel, makeSelectToken} from '../Authentication/selectors';
import AuthorizedGetHeaders from '../../Configs/AuthorizedGetHeaders';

export function* fetchVendors() {
  const {branch} = yield select(makeSelectHotel());
  const token = yield select(makeSelectToken());
  const requestUrl = `${apiUrl}/vendors/byBranchID/${branch.id}`;
  try {
    const vendors = yield call(
      apiCall,
      requestUrl,
      AuthorizedGetHeaders(token),
    );
    yield put(fetchVendorsSuccess(vendors || []));
  } catch (e) {
    yield put(fetchVendorsError(e.message));
    // console.log('err occurred', e);
  }
}

export function* fetchTasks() {
  const {branch} = yield select(makeSelectHotel());
  const token = yield select(makeSelectToken());
  const requestUrl = `${apiUrl}/tasks/todayByBranchID/${branch.id}`;
  try {
    const tasks = yield call(apiCall, requestUrl, AuthorizedGetHeaders(token));
    yield put(fetchTasksSuccess(tasks || []));
  } catch (e) {
    yield put(fetchTasksError(e.message));
    // console.log('err occurred', e);
  }
}

export function* fetchVendorsSaga() {
  yield takeLatest(FETCH_VENDORS, fetchVendors);
}

export function* fetchTasksSaga() {
  yield takeLatest(FETCH_TASKS, fetchTasks);
}

export default function* tasksSaga() {
  yield all([fetchTasksSaga(), fetchVendorsSaga()]);
}
