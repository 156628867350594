import React, {useState} from 'react';
import Row from 'reactstrap/es/Row';
import {Button, Col, ModalBody, ModalHeader} from 'reactstrap';
import {useSelector} from 'react-redux';
import Modal from 'reactstrap/es/Modal';
import {makeSelectHotel} from '../Authentication/selectors';

function CardPayment({handleAddPayment}) {
  const {branch} = useSelector(makeSelectHotel());
  const [modal, setModal] = useState(false);

  const toggleModal = (e) => {
    e.preventDefault();
    setModal(!modal);
  };
  return (
    <>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Modal title</ModalHeader>
        <ModalBody>
          <Row className="checkout-form">
            <Col md="12" className="terminals">
              {branch.terminals?.map((t) => (
                <Button
                  key={t.id}
                  onClick={() => {
                    setModal(!modal);
                    handleAddPayment('card', t.title);
                  }}
                  className="terminal-button"
                  color="light"
                  size="sm"
                >
                  {t.title}
                </Button>
              ))}
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Button className="action-button bg-danger" onClick={toggleModal}>
        <i className="fa fa-credit-card" />
        <span>CARD</span>
      </Button>
    </>
  );
}

export default CardPayment;
