import {createSelector} from 'reselect';

export const selectManagement = (state) => state.get('management');

export const makeSelectPrinters = () =>
  createSelector(selectManagement, (ManagementState) =>
    ManagementState.get('printers').toJS(),
  );

export const makeSelectDefaultPrinter = () =>
  createSelector(selectManagement, (ManagementState) =>
    ManagementState.get('default').toJS(),
  );

export const makeSelectState = () =>
  createSelector(selectManagement, (ManagementState) =>
    ManagementState.get('state'),
  );

export const makeSelectStatus = () =>
  createSelector(selectManagement, (ManagementState) =>
    ManagementState.get('status'),
  );
