import React from 'react';
import {getExtraTiers} from '../../Libs/product';

function Steps({
  product,
  setCurrentTier,
  currentTier,
  clickable,
  setPreviousStep,
}) {
  const handleNavigate = (i) => {
    if (i < currentTier) {
      setPreviousStep(currentTier);
      setCurrentTier(i);
    }
  };

  const extraTiers = getExtraTiers(product.specific_tiers, clickable);
  if (!product.tiers) {
    return null;
  }
  return (
    <div className="steps">
      {product.tiers.map((tier, i) => (
        <div
          key={tier.id}
          className={`step ${i === parseInt(currentTier, 10) ? 'active' : ''} ${
            i < currentTier ? 'done' : ''
          } ${currentTier === 'clickable' ? 'done' : ''}`}
          onClick={() => handleNavigate(i)}
        >
          <span className="number">{i + 1}</span>
          <svg width="8" height="22" className="divider" viewBox="0 0 14 40">
            <a transform="translate(-19.368 -906.672) matrix(.40597 0 0 .43303 -11.844 491.14)">
              <path
                fill="#EEE"
                fillRule="evenodd"
                d="M85.618 1052.222l-8.735-.03 26.205-46.283-26.205-46.313h8.735l26.205 46.313z"
              />
            </a>
          </svg>
          <span className="tier-status">{tier.title}</span>
        </div>
      ))}
      {extraTiers.map((tier, i) => (
        <div
          key={tier.id}
          className={`step ${
            i + product.tiers.length === parseInt(currentTier, 10)
              ? 'active'
              : ''
          } ${i + product.tiers.length < currentTier ? 'done' : ''} ${
            currentTier === 'clickable' ? 'done' : ''
          }`}
          onClick={() => handleNavigate(i + product.tiers.length)}
        >
          <span className="number">{i + product.tiers.length + 1}</span>
          <svg width="8" height="22" className="divider" viewBox="0 0 14 40">
            <a transform="translate(-19.368 -906.672) matrix(.40597 0 0 .43303 -11.844 491.14)">
              <path
                fill="#EEE"
                fillRule="evenodd"
                d="M85.618 1052.222l-8.735-.03 26.205-46.283-26.205-46.313h8.735l26.205 46.313z"
              />
            </a>
          </svg>
          <span className="tier-status">{tier.title}</span>
        </div>
      ))}
    </div>
  );
}

export default Steps;
