export const checkIfObjectEmpty = (obj = {}) =>
  Object.keys(obj).length === 0 && obj.constructor === Object;

export const applySentenceCase = (str) =>
  str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
  );

export const trimSlashes = (s) => s.replace(/\/$/, '');

export const getResponseRoute = (r) => {
  const route = trimSlashes(r.trim()).split('/');
  if (route.length === 4) {
    route.pop();
    return `${route.join('/')}/checkout/response`;
  }
  if (route.length === 5) {
    route.splice(-2, 2);
    return `${route.join('/')}/checkout/response`;
  }
  return '/';
};

export function strip(key) {
  if (key.indexOf('-----') !== -1) {
    return key.split('-----')[2].replace(/\r?\n|\r/g, '');
  }
  return key;
}

export const reformatTelephoneNumber = (telephone) => {
  let updatedTelephone = telephone;
  if (telephone.startsWith('0044')) {
    updatedTelephone = telephone;
  } else if (telephone.startsWith('0')) {
    updatedTelephone = `0044${telephone.substring(1).replaceAll(' ', '')}`;
  } else {
    updatedTelephone = `0044${telephone.replaceAll(' ', '')}`;
  }
  return updatedTelephone.padEnd(14, '3');
};

export const stripZero = (q) => {
  if (q.startsWith('0')) {
    return q.substring(1).replaceAll(' ', '');
  }
  return q;
};

export const deformatTelephoneNumber = (telephone) => {
  if (telephone.length === 0) {
    return telephone;
  }
  let updatedTelephone = telephone;
  if (telephone.startsWith('44')) {
    updatedTelephone = telephone.substring(2);
  }
  if (telephone.startsWith('0044')) {
    updatedTelephone = telephone.substring(4);
  }
  if (updatedTelephone.startsWith('0')) {
    updatedTelephone = updatedTelephone.padEnd(11, '3');
  } else {
    updatedTelephone = updatedTelephone.padEnd(10, '3');
  }
  return updatedTelephone;
};
