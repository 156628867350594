import React, {useEffect} from 'react';
import {Row} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import ProductItem from './ProductItem';
import Loader from './Loader';
import {makeSelectSelectedMenu} from '../Selectors/Menu';
import {selectItem} from './Cart/actions';

export default function ProductList() {
  const dispatch = useDispatch();
  const selectedMenu = useSelector(makeSelectSelectedMenu());

  useEffect(() => {
    dispatch(selectItem(-1));
  });
  if ((selectedMenu.products?.length || 0) === 0) {
    return (
      <div className="container-fluid customer-container">
        <h1>Items not found.</h1>
      </div>
    );
  }
  return selectedMenu.products.length > 0 ? (
    <div className="container-fluid customer-container">
      <Row>
        <ProductItem
          category={selectedMenu.slug}
          productItems={selectedMenu.products}
        />
      </Row>
    </div>
  ) : (
    <div className="container-fluid product-container">
      <Loader />
    </div>
  );
}
