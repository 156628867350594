/* eslint-disable */
// printing rolls have two sizes (approx width: 80mm) and (approx width: 88mm)
// approx width 80mm: maximum number of characters is 42
// approx width 88mm: maximum number of characters is 48
// find out how much do you need on the left side.
// for ex: In case of 80mm, 35 characters on the left side, rest character will be on the right. i.e 7 characters
// for totalColsPerPage = 40, leftCols = 28
// for totalColsPerPage = 42, leftCols = 30
// for totalColsPerPage = 48, leftCols = 36
import moment
  from 'moment';
import {paidStatus} from './bookings';
import {priceByMode} from './prices';
import domainUrl
  from '../Configs/Domain';

const totalColsPerPage = 48;
const leftCols = 43;

const init = '\x1B\x40';
const alignCenter = '\x1B\x61\x31';
const alignLeft = '\x1B\x61\x30';
const alignRight = '\x1B\x61\x32';
const newLine = '\x0A';
const bold_on = '\x1B\x45\x0D';
const bold_off = '\x1B\x45\x0A';
const em_mode_on = '\x1B\x21\x30';
const em_mode_off = '\x1B\x21\x0A\x1B\x45\x0A';
const small_text = '\x1B\x4D\x31';
const normal_text = '\x1B\x4D\x25';
const norm_text = '\x1B\x4D\x25';
const big_text = '\x1B\x21\x25';
const bigger_text = '\x1B\x21\x20';
const cutPaper = '\x1B\x69';
const openCashDrawer = '\x10' + '\x14' + '\x01' + '\x00' + '\x05';

// Pound sign
// \u00a35

/// //////////////////////////////////////////////////////////////////
//
// Server Side method for verifying the certificate
// Recommended: Because the private key is on server
//
/// //////////////////////////////////////////////////////////////////
export const openDrawer = function() {
  return [
    init +
    kickDrawer(true) +
    cutPaper,
  ];
};

export const getLocalBookingReceipt = function(booking, branch, openDrawer, printer) {
  const total = booking.sub_total +
    booking.delivery_charges -
    booking.discount_amount;
  return [
    init +
    getStoreDetails(branch, booking.checkout_time, printer.store) +
    getCheckout(booking.checkout_mode, branch.official_title, booking.checkout_time, printer.checkout, true) +
    getNotes(booking.notes, printer.notes) +
    getLocalProductListings(booking, total, printer.categories, printer.summary, printer.vat) +
    getPaymentStatus(booking.payments, printer.paid) +
    getCustomerDetails(booking.formData, printer.customer) +
    getThankYou(branch.official_title, printer.thankYou) +
    getLocalBookingCreatedAt(booking.checkout_time) +
    getLocalBookingProcessedAt(booking.checkout_time) +
    newLine +
    newLine +
    newLine +
    newLine +
    newLine +
    newLine +
    newLine +
    kickDrawer(openDrawer) +
    cutPaper,
  ];
};

export const getSyncBookingReceipt = function(booking, branch, openDrawer, printer) {
  return [
    init +
    getStoreDetails(branch, booking.booking.created_at, printer.store) +
    getCheckout(booking.booking.checkout_mode, branch.official_title, booking.booking.created_at, printer.checkout) +
    getNotes(booking.booking.notes, printer.notes) +
    getSyncedProductListings(booking, printer.categories, printer.summary) +
    getPaymentStatus(booking.payment, printer.paid) +
    getCustomerDetails(booking.user_address, printer.customer) +
    getThankYou(branch.official_title, printer.thankYou) +
    getBoookingCreatedAt(booking.booking.created_at) +
    getBookingProcessedAt(booking.booking.created_at) +
    newLine +
    newLine +
    newLine +
    newLine +
    newLine +
    newLine +
    newLine +
    kickDrawer(openDrawer) +
    kickDrawer(openDrawer) +
    cutPaper,
  ];
};

function getCheckout(checkoutMode, officialTitle, createdAt, shouldPrint, local = false) {
  if (shouldPrint) {
    return newLine +
      newLine +
      em_mode_on +
      getCheckoutMode(checkoutMode) +
      em_mode_off +
      newLine +
      newLine +
      getBookingDue(createdAt, local) +
      newLine +
      dottedLine();
  }
  return '';
}

function getNotes(n, shouldPrint) {
  if (shouldPrint) {
    return newLine +
      notes(n) +
      newLine +
      em_mode_off +
      dottedLine();
  }
  return '';
}

function getSyncedProductListings(booking, categories, shouldPrintSummary) {
  return newLine +
    getBookingInitialize() +
    getSyncedBookingProducts(booking.booking_products, booking.booking.checkout_mode, categories) +
    getSyncedSummary(booking.booking, shouldPrintSummary) +
    dottedLine();
}

function getLocalProductListings(booking, total, categories, shouldPrintSummary, shouldPrintVat) {
  return newLine +
    getBookingInitialize() +
    getOfflineBookingProducts(booking.cart, booking.checkout_mode, categories) +
    getLocalSummary(booking, total, shouldPrintSummary, shouldPrintVat) +
    dottedLine();
}

function getLocalSummary(booking, total, shouldPrint, shouldPrintVat) {
  let vat = '';
  if (shouldPrintVat) {
    vat = getVAT(total);
  }
  if (shouldPrint) {
    return getSubTotalPriceWithDiscount(booking.sub_total) +
      getPOSDiscount(booking.discount_amount) +
      getDeliveryCharges(booking.delivery_charges) +
      vat +
      getTotal(total) +
      getChange(booking.change);
  }
}

function getChange(change) {
  if (!!change) {
    return (
      alignLeft +
      getItemRowFull('Change', parseFloat(change / 100).toFixed(2)) +
      newLine
    );
  }
  return '';
}

function getSyncedSummary(booking, shouldPrint, shouldPrintVat) {
  let vat = '';
  if (shouldPrintVat) {
    vat = getVAT(booking.total_charges);
  }
  if (shouldPrint) {
    return getSubTotalPriceWithDiscount(booking.sub_total_charges) +
      getPOSDiscount(booking.discount_amount) +
      getDeliveryCharges(booking.delivery_charges) +
      vat +
      getTotal(booking.total_charges);
  }
  return '';
}

function getPaymentStatus(payment, shouldPrint) {
  if (shouldPrint) {
    return newLine +
      em_mode_on +
      alignCenter +
      getOrderType(payment) +
      em_mode_off +
      newLine +
      dottedLine();
  }
  return '';
}

function kickDrawer(openDrawer) {
  if (openDrawer) {
    return openCashDrawer;
  }
  return '';
}

function getHotelTitle(title) {
  return title;
}

function getHotelMobile(mobile) {
  return mobile;
}

function getCheckoutMode(mode) {
  if (mode.toLowerCase() === 'takeaway') {
    return 'Collection';
  }

  return mode;
}

function getBookingNo(title, unique) {
  return `Booking No: ${getHotelAcronym(title)}-${
    unique
  }`;
}

function getHotelAcronym(str) {
  const matches = str.match(/\b(\w)/g); // ['J','S','O','N']
  const acronym = matches.join(''); // JSON
  return acronym;
}

function getBookingDue(bDate, local) {
  if (local) {
    return `Booking Due: ASAP`;
  }
  return `Booking Due: ${moment(bDate).format('HH:mm')}`;
}

function getLocalBookingDue() {
  return `Booking Due: ASAP`;
}

function notes(notes) {
  if (notes) {
    return `${
      alignCenter + bigger_text
    }Notes${newLine}${alignLeft}${em_mode_off}${big_text}${bold_on}${maxRound(
      notes,
    )}${bold_off}${normal_text}`;
  }

  return `${
    alignCenter + bigger_text
  }Notes${newLine}${alignLeft}${em_mode_off}${big_text}${bold_on}${bold_off}${normal_text}`;
}

function getBookingInitialize() {
  return (
    alignLeft +
    bold_on +
    getItemRowFull('Description', 'GBP-Price') +
    bold_off +
    newLine
  );
}

function getSyncedBookingProducts(products, mode, categories) {
  return products.reduce((cString, product) => cString += getSyncedBookingProduct(product, mode, categories), '');
}

function getSyncedBookingProduct(product, mode, categories) {
  const printable = checkIfProductPrintable(product.pos_menu, categories);
  if (!printable) {
    return '';
  }
  const prices = {
    price_takeaway: product.price_takeaway,
    price_delivery: product.price_delivery,
  };
  return (
    alignLeft +
    getItemRowFull(
      `${bold_on}${product.quantity} x ${product.title}${bold_off}`,
      getPrice(priceByMode(prices, mode)),
    ) +
    getBookingTiers(product.booking_tiers, mode, false) +
    getSyncBookingHold(product.booking_hold) +
    getBookingExtras(product.booking_extra, product.booking_hold?.length || 0)
  );
}

function getOfflineBookingProducts(products, mode, categories) {
  return products.reduce((cString, product) => cString += getOfflineBookingProduct(product, mode, categories), '');
}

function getOfflineBookingProduct(product, mode, categories) {
  if (categories.includes(product.menu_id) || 1) {
    return (
      alignLeft +
      getItemRowFull(
        `${bold_on}${product.quantity} x ${product.title}${bold_off}`,
        getPrice(priceByMode({
          price_takeaway: product.price_takeaway,
          price_delivery: product.price_delivery,
        }, mode)),
      ) +
      getBookingTiers(product.tiers, mode, true) +
      getOfflineBookingHold(product.hold) +
      getBookingExtras(product.extras, product.hold?.length || 0)
    );
  }
  return '';
}

function getBookingTiers(tiers, mode, isLocal) {
  if (tiers.length > 0) {
    return tiers.reduce((tiersString, tier) => tiersString += getBookingTier(tier, mode, isLocal), newLine);
  }
  return '';
}

function getBookingTier(bookingTier, mode, isLocal) {
  if (checkIfObjectEmpty(bookingTier)) {
    return '';
  }
  const getBookingTierItems = isLocal ? getLocalBookingTierItems : getSyncedBookingTierItems;
  return (
    alignLeft +
    getBookingTierItems(bookingTier, mode)
  );
}

function getLocalBookingTierItems(bookingTier, mode) {
  if (['Size', 'Option', 'Product'].includes(bookingTier.type)) {
    return getLocalBookingTierItem(bookingTier.selected, mode);
  }
  if (bookingTier.type === 'Addon') {
    const freeString = (bookingTier.selected.free.reduce(
      function(bookingTierItemString, bookingAddon) {
        return getLocalBookingTierItem(bookingAddon, mode, true);
      },
      '',
    ));
    const paidString = (bookingTier.selected.paid.reduce(
      function(bookingTierItemString, bookingAddon) {
        return bookingTierItemString + ' ' + getLocalBookingTierItem(bookingAddon, mode, false);
      },
      '',
    ));
    return freeString + paidString;
  }
  return '';
}

function getSyncedBookingTierItems(bookingTier, mode) {
  if (bookingTier.type === 'Size') {
    return getSyncedBookingTierItem(bookingTier.booking_tier_sizes, mode);
  }
  if (bookingTier.type === 'Option') {
    return getSyncedBookingTierItem(bookingTier.booking_tier_options, mode);
  }
  if (bookingTier.type === 'Product') {
    return getSyncedBookingTierItem(bookingTier.booking_tier_products, mode);
  }
  if (bookingTier.type === 'Addon') {
    return bookingTier.booking_tier_addons?.reduce(
      function(bookingTierItemString, bookingAddon) {
        return bookingTierItemString + getSyncedBookingTierItem(bookingAddon, mode, bookingAddon.free);
      },
      '',
    );
  }
  return '';
}

function getSyncedBookingTierItem(bookingTierItem, mode, free = false) {
  return (
    alignLeft +
    getItemRowFull(
      `    ${bookingTierItem.quantity} x ${bookingTierItem.title}`,
      free ? '' : getPrice(priceByMode({
        price_takeaway: bookingTierItem.price_takeaway,
        price_delivery: bookingTierItem.price_delivery,
      }, mode)),
    ) +
    newLine
  );
}

function getLocalBookingTierItem(bookingTierItem, mode, free = false) {
  return (
    alignLeft +
    getItemRowFull(
      `    ${bookingTierItem.quantity} x ${bookingTierItem.title}`,
      free ? '' : getPrice(priceByMode(bookingTierItem.price, mode)),
    ) +
    newLine
  );
}

function getSyncBookingHold(holds = []) {
  if (holds.length !== 0) {
    const holdString = holds.reduce((str, hold) => `${str} ${hold.title}`, `${newLine} Hold : `);
    return getItemRowFull(holdString, '');
  }
  return '';
}

function getOfflineBookingHold(hold = []) {
  if (hold.length !== 0) {
    const holdString = `${newLine} Hold: (${hold.join(', ')})`;
    return getItemRowFull(holdString, '');
  }
  return '';
}

function getBookingExtras(extras = [], holdLength) {
  let extrasString = newLine;
  for (let i = 0; i < extras.length; i++) {
    extrasString += getBookingExtra(extras[i], i < holdLength);
  }
  return extrasString;
}

function getBookingExtra(bookingExtra, free = false) {
  return (
    alignLeft +
    getItemRowFull(
      ` Extras: ${bookingExtra.quantity} x ${bookingExtra.title}`,
      free ? '' : getPrice(bookingExtra.price),
    ) +
    newLine
  );
}

function getSubTotalPriceWithDiscount(total) {
  return (
    newLine +
    alignLeft +
    getItemRowFull(
      'Subtotal',
      parseFloat(total / 100).toFixed(2),
    ) +
    newLine
  );
}

function getPOSDiscount(amount) {
  if (amount) {
    return (
      alignLeft +
      getItemRowFull(
        'POS Discount: ',
        parseFloat(amount / 100).toFixed(2),
      ) +
      newLine
    );
  }

  return '';
}

function getDeliveryCharges(amount) {
  return (
    alignLeft +
    getItemRowFull(
      'Delivery Charges',
      parseFloat(amount / 100).toFixed(2),
    ) +
    newLine
  );
}

function getTotal(total) {
  return (
    alignLeft +
    getItemRowFull('Total', parseFloat(total / 100).toFixed(2)) +
    newLine
  );
}

function getVAT(total) {
  return (
    alignLeft +
    getItemRowFull('Applied VAT', parseFloat(total / 100 / 6).toFixed(2)) +
    newLine
  );
}

// Order Type
function getOrderType(payments) {
  return paidStatus(payments);
}

// Custom Details
function getCustomerDetails(userAddress, shouldPrint) {
  if (shouldPrint) {
    return (
      newLine +
      alignCenter +
      bold_on +
      bigger_text +
      maxRound('Customer Details') +
      bold_off +
      newLine +
      alignLeft +
      big_text +
      maxRound(userAddress.name) +
      getStructuredAddress(userAddress.address)+
      newLine +
      maxRound(`${userAddress.postcode}`) +
      newLine +
      maxRound(`P: ${userAddress.telephone || userAddress.mobile}`) +
      newLine +
      normal_text +
      newLine +
      em_mode_off +
      alignCenter +
      dottedLine()
    );
  }
  return '';
}

function getStructuredAddress(address) {
  let structuredAddress = '';
  address.split(',').map((subAddress) => {
    if(subAddress) {
      structuredAddress += newLine;
      structuredAddress += bigger_text;
      structuredAddress += maxRound(subAddress.trim());
      structuredAddress += normal_text;
    }
  });
  return structuredAddress;
}

// Thankyou
function getThankYou(branchTitle, shouldPrint) {
  if (shouldPrint) {
    return (
      newLine +
      newLine +
      alignCenter +
      'Thank You for shopping at ' +
      newLine +
      bold_on +
      bigger_text +
      branchTitle +
      bold_off +
      normal_text +
      em_mode_off +
      alignLeft +
      newLine +
      dottedLine()
    );
  }
  return '';
}

// Store Details
function getStoreDetails(branch, shouldPrint) {
  if (shouldPrint) {
    return (
      newLine +
      newLine +
      alignCenter +
      bold_on +
      bigger_text +
      branch.official_title +
      bold_off +
      normal_text +
      newLine +
      small_text +
      domainUrl +
      normal_text +
      newLine +
      normal_text +
      big_text +
      `${branch.address.toUpperCase()}` +
      newLine +
      branch.mobile +
      newLine +
      em_mode_off +
      alignLeft +
      newLine +
      dottedLine()
    );
  }
  return '';
}

// Booking created at & Booking processed at
function getBoookingCreatedAt(bDate) {
  console.log('date', bDate);
  return (
    getItemRowFull(`Booking created: ${getTimestamp(bDate)}`, '') +
    newLine
  );
}

function getLocalBookingCreatedAt(bDate) {
  return (
    getItemRowFull(`Booking created: ${bDate}`, '') +
    newLine
  );
}

function getBookingProcessedAt(bDate) {
  return (
    getItemRowFull(
      `Booking processed: ${getTimestamp(bDate)}`,
      '',
    ) + newLine
  );
}

function getLocalBookingProcessedAt(bDate) {
  return (
    getItemRowFull(
      `Booking processed: ${bDate}`,
      '',
    ) + newLine
  );
}

function getTimestamp(booking) {
  return new Date(booking).toLocaleString();
}

// operations
function checkIfObjectEmpty(obj = {}) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

function checkIfNull(params = {}) {
  return params === null;
}

function getItemRow(itemTitle, itemPrice) {
  if (itemTitle.length > leftCols) {
    let recieptRow = itemTitle.substr(0, leftCols);
    recieptRow += newLine;
    recieptRow +=
      itemTitle
        .substr(leftCols)
        .padEnd(totalColsPerPage - itemPrice.length, ' ') + itemPrice;
    return recieptRow;
  }

  return itemTitle.padEnd(totalColsPerPage - itemPrice.length, ' ') + itemPrice;
}

function getItemRowFull(itemTitle, itemPrice = '') {
  const price = itemPrice === '0.00' ? '' : itemPrice;
  const marginSpace = totalColsPerPage === 48 ? '' : '   ';

  let colsLeft = leftCols;
  if (itemTitle.length > leftCols) {
    colsLeft = !price ? totalColsPerPage - 5 : colsLeft;
    let rightCols = !price ? 5 : price.length;

    const itemTitleLength = itemTitle.length;
    let receipt = '';
    let startFrom = 0;
    let remainingCharacters = itemTitle.length;

    while (startFrom < itemTitleLength) {
      receipt +=
        /* alignLeft + */ marginSpace +
        itemTitle
          .substr(startFrom, colsLeft)
          .padEnd(totalColsPerPage - rightCols, ' ') +
        (startFrom === 0 ? price : '');
      receipt += newLine;
      remainingCharacters =
        remainingCharacters + itemTitleLength - (itemTitleLength + colsLeft);
      startFrom += colsLeft;
    }
    return receipt;
  }

  return (
    marginSpace +
    itemTitle.padEnd(totalColsPerPage - price.length, '    ') +
    (price === 0 ? '' : price)
  );
}

function maxRound(text) {
  const re = new RegExp(`.{1,${leftCols}}`, 'g');
  const chunk = text.match(re);
  const spaced = chunk.map((i) => `  ${i}`);
  return spaced.join(newLine);
}


function getPrice(price, mode) {
  return parseFloat(price / 100).toFixed(2);
}

function dottedLine() {
  if (totalColsPerPage === 48) {
    return `${alignCenter}------------------------------------------------`;
  }
  return `${alignCenter}------------------------------------------`;
}

function checkIfProductPrintable(posMenu, categories) {
  let shouldPrint = false;
  for (let i = 0; i < posMenu.length; i += 1) {
    if (categories.includes(posMenu[i].id)) {
      return true;
    }
  }
  return shouldPrint;
}
