import React from 'react';
import moment from 'moment';

const Time = () => {
  return (
    <p className="bottomRight">{moment().format('YYYY-MM-DD HH:mm:ss')}</p>
  );
};

export default Time;
