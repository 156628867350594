import {fromJS} from 'immutable';

import {
  FETCH_DRIVERS_ERROR,
  FETCH_DRIVERS,
  FETCH_DRIVERS_SUCCESS,
} from './constants';

const initialState = fromJS({
  drivers: [],
  status: '',
  state: 'initial',
});

export default function driversReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DRIVERS:
      return state.set('state', 'loading').set('status', '');
    case FETCH_DRIVERS_SUCCESS:
      return state
        .set('drivers', fromJS(action.drivers))
        .set('state', 'success');
    case FETCH_DRIVERS_ERROR:
      return state.set('state', 'error').set('status', action.status);
    default:
      return state;
  }
}
