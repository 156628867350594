export const SPLIT_ORDER = 'Favorite/Checkout/SPLIT_ORDER';

export const SET_REMAINING = 'Favorite/Checkout/SET_REMAINING';

export const SET_CHANGE = 'Favorite/Checkout/SET_CHANGE';

export const SET_DISPLAY_VALUE = 'Favorite/Checkout/SET_DISPLAY_VALUE';

export const SET_PAYMENT = 'Favorite/Checkout/SET_PAYMENT';

export const REMOVE_PAYMENT = 'Favorite/Checkout/REMOVE_PAYMENT';

export const SET_PAYMENTS = 'Favorite/Checkout/SET_PAYMENTS';

export const SET_SPLITING = 'Favorite/Checkout/SET_SPLITING';
