import React, {useState} from 'react';
import {Button, Col, Form, FormGroup, Row} from 'reactstrap';
import Input from 'reactstrap/es/Input';
import Alert from 'reactstrap/es/Alert';
import {useDispatch, useSelector} from 'react-redux';
import {Link, Redirect, useHistory} from 'react-router-dom';
import Loadable from '../../Components/Loadable';
import {makeSelectHotel, makeSelectToken} from '../Authentication/selectors';
import {unauthorized} from '../Authentication/actions';
import apiCall from '../../Services/ApiCall';
import apiUrl from '../../Configs/ApiUrl';
import AuthorizedPostHeaders from '../../Configs/AuthorizedPostHeaders';
import {fetchDrivers} from '../Drivers/actions';

function ClockIn() {
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSelector(makeSelectToken());
  const {branch} = useSelector(makeSelectHotel());
  const [state, setState] = useState('initial');
  const [status, setStatus] = useState('');
  const [code, setCode] = useState('');
  const handleKeypadClick = (ev, num) => {
    ev.preventDefault();
    if (code.length <= 3) {
      setCode(`${code}${num}`);
    }
  };

  const resetKeypadHandler = (ev) => {
    ev.preventDefault();
    setCode('');
  };
  const handleClockIn = async (evt) => {
    evt.preventDefault();
    setState('loading');
    setStatus('');
    try {
      await apiCall(
        `${apiUrl}/drivers/clocked`,
        AuthorizedPostHeaders(
          {
            branch_id: branch.id,
            status: 'clock-in',
            pin: code,
          },
          token,
        ),
      );
      setState('success');
      dispatch(fetchDrivers());
      history.push('/members');
    } catch (e) {
      // console.log('clock in error', e);

      if (e.message === 'Unauthorized') {
        dispatch(unauthorized());
        history.push('/login');
      } else {
        setStatus(e.message);
        setState('error');
      }
    }
  };

  if (token.length === 0) {
    return <Redirect to="/login" />;
  }

  return (
    <div className="clock-in">
      <Link to="/" className="go-back">
        Go Back
      </Link>
      <Row>
        <Col>
          <h1 className="title">{branch.official_title}</h1>
          <p className="text-center m-0">
            <small className="small-title">Enter Your Login Pin</small>
          </p>
        </Col>
      </Row>
      <Form className="form m-0" onSubmit={handleClockIn}>
        <div className="num">
          <Row>
            <Col>
              <center>
                <FormGroup>
                  <Input
                    type="password"
                    className="pin-input"
                    name="pin"
                    placeholder="Enter your pin"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </FormGroup>
              </center>
            </Col>
          </Row>
          {state === 'error' ? (
            <Row>
              <Col>
                <center>
                  <Alert color="danger">{status}</Alert>
                </center>
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col md="4" sm="4" xs="4">
              <Button
                color="primary"
                onClick={(ev) => handleKeypadClick(ev, 1)}
                className="numeric-input"
                size="sm"
              >
                1
              </Button>
            </Col>
            <Col md="4" sm="4" xs="4">
              <Button
                color="primary"
                onClick={(ev) => handleKeypadClick(ev, 2)}
                className="numeric-input"
                size="sm"
              >
                2
              </Button>
            </Col>
            <Col md="4" sm="4" xs="4">
              <Button
                color="primary"
                onClick={(ev) => handleKeypadClick(ev, 3)}
                className="numeric-input"
                size="sm"
              >
                3
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md="4" sm="4" xs="4">
              <Button
                color="primary"
                onClick={(ev) => handleKeypadClick(ev, 4)}
                className="numeric-input"
                size="sm"
              >
                4
              </Button>
            </Col>
            <Col md="4" sm="4" xs="4">
              <Button
                color="primary"
                onClick={(ev) => handleKeypadClick(ev, 5)}
                className="numeric-input"
                size="sm"
              >
                5
              </Button>
            </Col>
            <Col md="4" sm="4" xs="4">
              <Button
                color="primary"
                onClick={(ev) => handleKeypadClick(ev, 6)}
                className="numeric-input"
                size="sm"
              >
                6
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md="4" sm="4" xs="4">
              <Button
                color="primary"
                onClick={(ev) => handleKeypadClick(ev, 7)}
                className="numeric-input"
                size="sm"
              >
                7
              </Button>
            </Col>
            <Col md="4" sm="4" xs="4">
              <Button
                color="primary"
                onClick={(ev) => handleKeypadClick(ev, 8)}
                className="numeric-input"
                size="sm"
              >
                8
              </Button>
            </Col>
            <Col md="4" sm="4" xs="4">
              <Button
                color="primary"
                onClick={(ev) => handleKeypadClick(ev, 9)}
                className="numeric-input"
                size="sm"
              >
                9
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md="4" sm="4" xs="4">
              <Button
                color="primary"
                onClick={(ev) => handleKeypadClick(ev, 0)}
                className="numeric-input"
                size="sm"
              >
                0
              </Button>
            </Col>
            <Col md="8" sm="8" xs="8">
              <Button
                color="primary"
                onClick={(ev) => resetKeypadHandler(ev)}
                className="numeric-input-2"
                size="sm"
              >
                C
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md="12" sm="12" xs="12">
              <button
                onClick={handleClockIn}
                className="btn btn-primary btn-sm input-signin"
                type="submit"
              >
                <Loadable color="white" active={state === 'loading'}>
                  CLOCK IN
                </Loadable>
              </button>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
}

export default ClockIn;
