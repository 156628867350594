import React, {useEffect} from 'react';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';
import {useDispatch, useSelector} from 'react-redux';
import Alert from 'reactstrap/es/Alert';
import {addPrinter, getPrinters} from './actions';
import AddPrinter from './AddPrinter';
import {
  makeSelectDefaultPrinter,
  makeSelectPrinters,
  makeSelectState,
  makeSelectStatus,
} from './selectors';
import Loader from '../../Components/Loader';
import {checkIfObjectEmpty} from '../../Libs/object';

function Printers() {
  const dispatch = useDispatch();
  const defaultPrinter = useSelector(makeSelectDefaultPrinter());
  const printers = useSelector(makeSelectPrinters());
  const status = useSelector(makeSelectStatus());
  const state = useSelector(makeSelectState());

  useEffect(() => {
    dispatch(getPrinters());
  }, []);

  if (state === 'loading') {
    return (
      <>
        <Row>
          <Col>
            <h4>Printer Settings</h4>
          </Col>
          <Col>
            <button
              className="btn add-printer-btn"
              type="submit"
              onClick={() =>
                dispatch(
                  addPrinter(
                    {
                      type: 'usb',
                      host: '',
                      port: '',
                      prints: 1,
                      name: 'zebra',
                      checkout: true,
                      notes: true,
                      paid: true,
                      customer: true,
                      thankYou: true,
                      store: true,
                      summary: true,
                      vat: true,
                      categories: [],
                    },
                    checkIfObjectEmpty(defaultPrinter),
                  ),
                )
              }
            >
              ADD NEW PRINTER
            </button>
          </Col>
        </Row>
        <div className="printer-settings justify-content-center align-items-center">
          <Loader color="black" />
        </div>
      </>
    );
  }

  if (state === 'error') {
    return (
      <>
        <Row>
          <Col>
            <h4>Printer Settings</h4>
          </Col>
          <Col>
            <button
              className="btn add-printer-btn"
              type="submit"
              onClick={() =>
                dispatch(
                  addPrinter({
                    type: 'usb',
                    host: '',
                    port: '',
                    prints: 1,
                    name: 'zebra',
                    checkout: true,
                    notes: true,
                    paid: true,
                    customer: true,
                    thankYou: true,
                    store: true,
                    summary: true,
                    vat: true,
                    categories: [],
                  }),
                )
              }
            >
              ADD NEW PRINTER
            </button>
          </Col>
        </Row>
        <div className="printer-settings">
          <Alert color="danger">{status}</Alert>
        </div>
      </>
    );
  }

  return (
    <>
      <Row>
        <Col>
          <h4>Printer Settings</h4>
        </Col>
        <Col>
          <button
            className="btn add-printer-btn"
            type="submit"
            onClick={() =>
              dispatch(
                addPrinter({
                  type: 'usb',
                  host: '',
                  port: '',
                  prints: 1,
                  name: 'zebra',
                  checkout: true,
                  notes: true,
                  paid: true,
                  customer: true,
                  thankYou: true,
                  store: true,
                  summary: true,
                  vat: true,
                  categories: [],
                }),
              )
            }
          >
            ADD NEW PRINTER
          </button>
        </Col>
      </Row>
      <div className="printer-settings">
        <AddPrinter printer={defaultPrinter} isDefault />
        {printers.map((printer, index) => (
          <AddPrinter printer={printer} key={index} index={index} />
        ))}
      </div>
    </>
  );
}

export default Printers;
