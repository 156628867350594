import qz from 'qz-tray';
import QzTray from '../Configs/QZTray';
import {openDrawer} from './print';

async function openTill(defaultPrinter) {
  try {
    const printString = openDrawer();
    const config = await QzTray(defaultPrinter);
    await qz.print(config, printString);
    await qz.websocket.disconnect();
  } catch (e) {
    console.log('open drawer error', e);
  }
}

export default openTill;
