import {fromJS} from 'immutable';

import {CREATE_NOTE, DELETE_NOTE} from './constants';

const initialState = fromJS({notes: []});

export default function notesReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_NOTE:
      return state.update('notes', (notes) => notes.push(action.note));
    case DELETE_NOTE:
      return state.deleteIn(['notes', action.index]);
    default:
      return state;
  }
}
