import {ADD_NOTE, CREATE_NOTE, DELETE_NOTE, REMOVE_NOTE} from './constants';

export const addNote = (note) => ({
  type: ADD_NOTE,
  note,
});

export const createNote = (note) => ({
  type: CREATE_NOTE,
  note,
});

export const removeNote = (index) => ({
  type: REMOVE_NOTE,
  index,
});

export const deleteNote = (index) => ({
  type: DELETE_NOTE,
  index,
});
