import React from 'react';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';
import {useSelector} from 'react-redux';
import Numpad from '../Checkout/Numpad';
import {makeSelectState} from './selectors';
import Loader from '../../Components/Loader';

function TotalCharges({setTotal, total, setStep}) {
  const state = useSelector(makeSelectState());
  const handleDone = () => {
    setStep('verify');
  };
  if (state === 'loading') {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loader color="black" />
      </div>
    );
  }
  return (
    <>
      <Row>
        <Col>
          <h3 className="header">Please enter current till amount.</h3>
        </Col>
      </Row>
      <Row>
        <div className="numpad-wrapper">
          <Numpad
            remaining={total}
            displayValue={total}
            setDisplayValue={(value) => setTotal(value)}
            setSpliting={false}
          />
        </div>
      </Row>
      <Row>
        <Col>
          <button type="button" onClick={handleDone}>
            Done
          </button>
        </Col>
      </Row>
    </>
  );
}

export default TotalCharges;
