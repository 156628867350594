import {createSelector} from 'reselect';

export const selectTasks = (state) => state.get('tasks');

export const makeSelectVendors = () =>
  createSelector(selectTasks, (DriversState) =>
    DriversState.get('vendors').toJS(),
  );

export const makeSelectState = () =>
  createSelector(selectTasks, (DriversState) => DriversState.get('state'));

export const makeSelectStatus = () =>
  createSelector(selectTasks, (DriversState) => DriversState.get('status'));

export const makeSelectTasks = () =>
  createSelector(selectTasks, (TasksState) => TasksState.get('tasks').toJS());
