/* eslint-disable */
const initialForm = {
  name: '',
  telephone: '',
  address: '',
  postcode: '',
};
export default function validateCustomer(inputs = initialForm) {
  if (inputs.postcode.trim().length === 0) {
    return 'Post Code field is required';
  }
  if (!inputs.postcode.match(/^[a-zA-Z0-9 ,.'-]+$/)) {
    return 'Post Code contains invalid characters.';
  }
  if (inputs.telephone.trim().length === 0) {
    return 'Mobile field is required.';
  }
  if(inputs.telephone.startsWith('0')) {
    if(!(inputs.telephone.trim().length === 11)) {
      return 'Mobile number must be 11 digits if starting from 0.';
    }
  } else {
    if(!(inputs.telephone.trim().length === 10)) {
      return 'Mobile number must be 10 digits.';
    }
  }
  if (!inputs.telephone.match(/^(\(?[0-9]{1}\d{1,4}?\)?\s?\d{3,4}\s?\d{3,4})$/)) {
    return 'Invalid mobile number.';
  }
  if (inputs.address.trim().length === 0) {
    return 'Address  field is required.';
  }
  if (!inputs.address.match(/^[a-zA-Z0-9 ,.'-]+$/)) {
    return 'Address contains invalid characters.';
  }
  return 'validated';
}
