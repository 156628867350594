import React from 'react';
import {Col} from 'reactstrap';

function Clickables({clickables, setClickable}) {
  return clickables.map((clickable) => {
    return (
      <Col
        key={clickable.id}
        md="3"
        className="paddingLeft0"
        onClick={() => setClickable(clickable)}
      >
        <div className="prod-list">
          <div className="prod-title">
            <h6>{clickable.title}</h6>
          </div>
          <div className="prod-price">
            <h6 />
          </div>
        </div>
      </Col>
    );
  });
}

export default Clickables;
