import React from 'react';

import {Redirect, Route, Switch} from 'react-router-dom';
import {useSelector} from 'react-redux';
import ClockIn from './ClockIn';
import ClockOut from './ClockOut';
import Sales from './Sales';
import Log from './Log';
import Drivers from '../Drivers';
import Firebase from '../../Components/Firebase';
import {
  makeSelectClockedIn,
  makeSelectUserPermissions,
} from '../Authentication/selectors';

function Members() {
  const permissions = useSelector(makeSelectUserPermissions());
  const clockedIn = useSelector(makeSelectClockedIn());
  if (!clockedIn || !permissions.drivers_access === true) {
    return <Redirect to="/" />;
  }
  return (
    <div className="members">
      <Firebase />
      <Switch>
        <Route exact path="/members" component={Drivers} />
        <Route exact path="/members/sales/:id" component={Sales} />
        <Route exact path="/members/log/:id" component={Log} />
        <Route exact path="/members/clock-in" component={ClockIn} />
        <Route exact path="/members/clock-out" component={ClockOut} />
      </Switch>
    </div>
  );
}

export default Members;
