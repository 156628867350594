export const TOGGLE_CHECKOUT = 'Favorite/Cart/TOGGLE_CHECKOUT';

export const ADD_CUSTOMER = 'Favorite/Cart/ADD_CUSTOMER';

export const ADD_TO_CART = 'Favorite/Cart/ADD_TO_CART';

export const ADD_CART = 'Favorite/Cart/ADD_CART';

export const INCREASE_QUANTITY = 'Favorite/Cart/INCREASE_QUANTITY';

export const DECREASE_QUANTITY = 'Favorite/Cart/DECREASE_QUANTITY';

export const REMOVE_ITEM = 'Favorite/Cart/REMOVE_ITEM';

export const CLEAR_CART = 'Favorite/Cart/CLEAR_CART';

export const ADD_DISCOUNT = 'Favorite/Cart/ADD_DISCOUNT';

export const REMOVE_DISCOUNT = 'Favorite/Cart/REMOVE_DISCOUNT';

export const ADD_PAYMENT = 'Favorite/Cart/ADD_PAYMENT';

export const REMOVE_PAYMENT = 'Favorite/Cart/REMOVE_PAYMENT';

export const ADD_NOTES = 'Favorite/Cart/ADD_NOTES';

export const REMOVE_NOTES = 'Favorite/Cart/REMOVE_NOTES';

export const SELECT_ITEM = 'Favorite/Cart/SELECT_ITEM';

export const ADD_HOLD_ITEMS = 'Favorite/Cart/ADD_HOLD_ITEMS';

export const ADD_EXTRAS = 'Favorite/Cart/ADD_EXTRAS';

export const UPDATE_CART_ITEM = 'Favorite/Cart/UPDATE_CART_ITEM';

export const SAVE_OPERATION = 'Favorite/Cart/SAVE_OPERATION';
