import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Alert from 'reactstrap/es/Alert';
import Loader from '../../Components/Loader';
import {
  makeSelectState,
  makeSelectStatus,
  makeSelectVendors,
} from './selectors';
import {fetchVendors} from './actions';

function Vendors({setVendor, vendor = {}}) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchVendors());
  }, []);

  const vendors = useSelector(makeSelectVendors());
  const state = useSelector(makeSelectState());
  const status = useSelector(makeSelectStatus());

  if (state === 'loading' && vendors.length === 0) {
    return (
      <div className="d-flex justify-content-center align-items-center h-50">
        <Loader color="black" />
      </div>
    );
  }
  if (state === 'error') {
    return <Alert color="danger">{status}</Alert>;
  }
  return (
    <div className="vendors">
      {vendors.map((v) => (
        <button
          className={
            parseInt(vendor.id, 10) === parseInt(v.id, 10) ? 'btn-info' : ''
          }
          type="button"
          key={v.id}
          onClick={() => setVendor(v)}
        >
          {v.title}
        </button>
      ))}
    </div>
  );
}

export default Vendors;
