import React, {useEffect, useState} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';
import Alert from 'reactstrap/es/Alert';
import {filterTasksByAssignment} from '../../Libs/tasks';
import Task from './Task';
import {fetchTasks} from './actions';
import {makeSelectState, makeSelectTasks} from './selectors';
import Loader from '../../Components/Loader';
import {makeSelectUserPermissions} from '../Authentication/selectors';
import {searchTasks} from '../../Libs/bookings';

function TasksList({showActions = true}) {
  const dispatch = useDispatch();
  const permissions = useSelector(makeSelectUserPermissions());
  const tasksState = useSelector(makeSelectState());
  const tasks = useSelector(makeSelectTasks());
  const [filter, setFilter] = useState('not_assigned');
  const [q, setQ] = useState('delivery');
  const [status, setStatus] = useState('');

  useEffect(() => {
    dispatch(fetchTasks());
  }, []);

  const filteredTasks = filterTasksByAssignment(tasks, filter);

  const searchedTasks = searchTasks(filteredTasks, q);

  if (!permissions.assign_access === true) {
    return <Redirect to="/" />;
  }
  return (
    <div className="tasks-list">
      <div className="filter">
        <div className="left">
          <button
            className={filter === 'assigned' ? 'active' : ''}
            type="button"
            onClick={() => setFilter('assigned')}
          >
            Assigned
          </button>
          <button
            className={filter === 'not_assigned' ? 'active' : ''}
            type="button"
            onClick={() => setFilter('not_assigned')}
          >
            Not Assigned
          </button>
          <div className="vertical-divider" />
          <button
            className={q === 'takeaway' ? 'active' : ''}
            type="button"
            onClick={() => setQ('takeaway')}
          >
            Collection
          </button>
          <button
            className={q === 'delivery' ? 'active' : ''}
            type="button"
            onClick={() => setQ('delivery')}
          >
            Delivery
          </button>
        </div>
        {showActions && (
          <div className="right">
            <button type="button" onClick={() => dispatch(fetchTasks())}>
              Refresh Tasks
            </button>
            <Link to="/tasks/create">Create Task</Link>
          </div>
        )}
      </div>
      {status !== '' && (
        <Row className="m-3">
          <Col>
            <Alert color="danger">{status}</Alert>
          </Col>
        </Row>
      )}
      {searchedTasks.length === 0 && (
        <Row>
          <Col>
            <Alert color="danger">No Task Found</Alert>
          </Col>
        </Row>
      )}
      <ul>
        {tasksState === 'loading' ? (
          <div className="task-loader">
            <Loader color="black" />
          </div>
        ) : (
          searchedTasks.map((task) => (
            <Task setStatus={setStatus} task={task} key={task.id} />
          ))
        )}
      </ul>
    </div>
  );
}

export default TasksList;
