import React from 'react';
import Row from 'reactstrap/es/Row';
import FormGroup from 'reactstrap/es/FormGroup';
import Button from 'reactstrap/es/Button';
import TextInput from '../../Components/TextInput';
import Loadable from '../../Components/Loadable';

function Filters({handleFetchBookings, setQ, q, setFilter, filter, state}) {
  return (
    <>
      <Row className="px-3">
        <FormGroup className="search-bookings w-100 d-flex">
          <Button className="btn-refresh" onClick={handleFetchBookings}>
            <Loadable color="white" active={state === 'loading'}>
              <i className="fa fa-refresh" />
            </Loadable>
          </Button>
          <TextInput
            isFirstLetterUppercase={false}
            inputClassName="form-control"
            onChange={(value) => setQ(value)}
            value={q}
            type="text"
          />
          <Button className="btn-refresh" onClick={() => setQ('')}>
            Clear
          </Button>
        </FormGroup>
      </Row>
      <Row className="px-3">
        <FormGroup className="filter-bookings w-100 d-flex justify-content-between">
          <Button
            className={`btn-primary d-block ${
              filter === 'takeaway' && 'selected'
            }`}
            onClick={() => setFilter('takeaway')}
          >
            Collection
          </Button>
          <Button
            className={`btn-warning d-block ${
              filter === 'delivery' && 'selected'
            }`}
            onClick={() => setFilter('delivery')}
          >
            Delivery
          </Button>
          <Button
            className={`bg-info d-block ${filter === 'web' && 'selected'}`}
            onClick={() => setFilter('web')}
          >
            Web
          </Button>
          <Button
            className={`btn-dark d-block ${filter === 'pos' && 'selected'}`}
            onClick={() => setFilter('pos')}
          >
            POS
          </Button>
          <Button
            className={`bg-light text-dark ${
              filter === 'TP Orders' && 'selected'
            }`}
            onClick={() => setFilter('TP Orders')}
          >
            TP Orders
          </Button>
        </FormGroup>
      </Row>
    </>
  );
}

export default Filters;
