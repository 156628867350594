import qz from 'qz-tray';
import QzTray from '../Configs/QZTray';
import {getSyncBookingReceipt} from './print';

async function printSyncedBooking(booking, branch, openDrawer, printers) {
  try {
    for (let i = 0; i < printers.length; i += 1) {
      const printString = getSyncBookingReceipt(
        booking,
        branch,
        openDrawer,
        printers[i],
      );
      const prints = printers[i].prints || 1;
      for (let j = 0; j < prints; j += 1) {
        const config = await QzTray(printers[i]);
        await qz.print(config, printString);
        await qz.websocket.disconnect();
      }
    }
  } catch (e) {
    console.log('sync print error', e);
  }
}

export default printSyncedBooking;
