import {
  FETCH_TASKS,
  FETCH_TASKS_SUCCESS,
  FETCH_VENDORS,
  FETCH_VENDORS_ERROR,
  FETCH_VENDORS_SUCCESS,
} from './constants';

export const fetchVendors = () => ({
  type: FETCH_VENDORS,
});

export const fetchVendorsSuccess = (vendors) => ({
  type: FETCH_VENDORS_SUCCESS,
  vendors,
});

export const fetchVendorsError = (status) => ({
  type: FETCH_VENDORS_ERROR,
  status,
});

export const fetchTasks = () => ({
  type: FETCH_TASKS,
});

export const fetchTasksSuccess = (tasks) => ({
  type: FETCH_TASKS_SUCCESS,
  tasks,
});

export const fetchTasksError = (status) => ({
  type: FETCH_VENDORS_ERROR,
  status,
});
