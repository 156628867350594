import React from 'react';
import Addon from './Addon';

const Addons = ({addons, title, mode}) => (
  <div className="addons">
    <div className="left">
      <p className="basket-title">{title}: &nbsp;</p>
    </div>
    {addons?.map((addon) => (
      <Addon addon={addon} key={addon.id} mode={mode} />
    ))}
  </div>
);

export default Addons;
