export const checkTaskPaymentStatus = (paymentMethod) => {
  const paid = ['cash', 'card'];
  if (paid.includes(paymentMethod)) {
    return 'Paid';
  }
  return 'Not Piad';
};

export const filterTasksByAssignment = (tasks, filter) => {
  if (filter === '') {
    return tasks;
  }
  if (filter === 'assigned') {
    return tasks.filter((task) => task.driver_id !== null);
  }
  return tasks.filter((task) => task.driver_id === null);
};

export const findTaskById = (tasks, id) => {
  const index = tasks.findIndex((task) => task.id === parseInt(id, 10));
  return tasks[index];
};
