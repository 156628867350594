import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, Redirect} from 'react-router-dom';
import Button from 'reactstrap/es/Button';
import {fromJS} from 'immutable';
import {makeSelectHotel} from '../Authentication/selectors';
import Master from '../Master';
import {
  makeSelectSelectedIndex,
  makeSelectSelectedProduct,
} from '../../Components/Cart/selectors';
import {addHoldItems} from '../../Components/Cart/actions';

function Hold() {
  const dispatch = useDispatch();
  const {push} = useHistory();
  const [items, setItems] = useState(fromJS([]));
  const {branch} = useSelector(makeSelectHotel());
  const selectedIndex = useSelector(makeSelectSelectedIndex());
  const selectedProduct = useSelector(makeSelectSelectedProduct());
  const selectHoldItem = (item) => {
    const index = items.toJS().findIndex((i) => i === item);
    if (index !== -1) {
      setItems(items.delete(index));
    } else {
      setItems(items.push(item));
    }
  };

  useEffect(() => {
    setItems(fromJS(selectedProduct.hold || []));
  }, []);
  if (selectedIndex === -1) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <Master>
      <div className="customer-container">
        <h2 className="hold-title">Hold Items</h2>
        <div className="hold-items">
          <div className="items">
            {branch.holds?.map((item) => (
              <div
                className={`item ${
                  items.toJS().includes(item.title) ? 'active' : ''
                }`}
                key={item.id}
                onClick={() => selectHoldItem(item.title)}
              >
                <p>{item.title}</p>
              </div>
            ))}
          </div>
          <div className="row my-4 mx-0 justify-content-center">
            <div className="w-25">
              <Button
                className="btn btn-success btn-block p-4"
                onClick={() => {
                  dispatch(addHoldItems(items.toJS(), selectedIndex));
                  push('/dashboard');
                }}
              >
                <strong>Done</strong>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Master>
  );
}

export default Hold;
