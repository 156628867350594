import React from 'react';
import Row from 'reactstrap/es/Row';
import {ListGroupItem} from 'reactstrap';

function FreeAddons({addons, removeAddon}) {
  return (
    <ListGroupItem>
      <Row className="selected-addons">
        <p className="addons-tag">Free Addons</p>
        {addons.map((a, i) => (
          <div className="selected" key={a.id}>
            <button
              className="delete"
              type="button"
              onClick={() => removeAddon('free', i)}
            >
              x
            </button>
            <p>{a.title}</p>
            <span>{a.quantity}</span>
          </div>
        ))}
      </Row>
    </ListGroupItem>
  );
}

export default FreeAddons;
