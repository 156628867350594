import React from 'react';
import {Link, Redirect, Route, Switch} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';
import {
  makeSelectHotel,
  makeSelectUserPermissions,
} from '../Authentication/selectors';
import Panel from './Panel';
import Printers from './Printers';

function Management() {
  const {branch} = useSelector(makeSelectHotel());
  const permissions = useSelector(makeSelectUserPermissions());
  if (!permissions.management_access === true) {
    return <Redirect to="/" />;
  }
  return (
    <div className="management">
      <Link to="/" className="go-back">
        Go Back
      </Link>
      <Row>
        <Col>
          <h2 className="title">{branch.official_title}</h2>
        </Col>
      </Row>
      <Switch>
        <Route exact path="/management" component={Panel} />
        <Route exact path="/management/printers" component={Printers} />
      </Switch>
    </div>
  );
}

export default Management;
