import React, {useEffect} from 'react';
import {Redirect, useHistory, Link} from 'react-router-dom';
import moment from 'moment';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';
import {useDispatch, useSelector} from 'react-redux';
import {
  makeSelectClockedIn,
  makeSelectUserPermissions,
} from '../Authentication/selectors';
import {fetchDrivers} from '../Drivers/actions';
import {toggleCheckout} from '../../Components/Cart/actions';
import {fetchMenu} from '../../Actions/Menu';
import {makeSelectState} from '../../Selectors/Menu';
import Loadable from '../../Components/Loadable';
import BranchDetails from './BranchDetails';
import {makeSelectStatus} from '../TillManagement/selectors';
import Firebase from '../../Components/Firebase';
import openTill from '../../Libs/openTill';
import {makeSelectDefaultPrinter} from '../Management/selectors';

function Home() {
  const {push} = useHistory();
  const dispatch = useDispatch();
  const defaultPrinter = useSelector(makeSelectDefaultPrinter());
  const permissions = useSelector(makeSelectUserPermissions());
  const tillStatus = useSelector(makeSelectStatus());
  const clockedIn = useSelector(makeSelectClockedIn());
  const state = useSelector(makeSelectState());

  useEffect(() => {
    dispatch(fetchDrivers());
  }, []);

  const handleRefreshMenu = () => {
    dispatch(fetchMenu());
  };

  const handleCheckout = (mode) => {
    dispatch(toggleCheckout(mode));
    push('/customer');
  };

  const handleLink = (evt, enabled = false) => {
    if (!enabled) {
      evt.preventDefault();
    }
  };

  const handleKickDrawer = async () => {
    await openTill(defaultPrinter);
  };

  if (!clockedIn) {
    return <Redirect to="/clock-in" />;
  }

  return (
    <div className="home">
      <Firebase />
      <Row className="actions">
        <Col>
          <div className="section-header">
            TIME: &nbsp;&nbsp; {moment().format('HH:mm')}
          </div>
          <button
            className="w-33"
            type="button"
            disabled={!permissions.dashboard_access === true}
            onClick={() => handleCheckout('Takeaway')}
          >
            Collection
          </button>
          <button
            className="w-33"
            type="button"
            disabled={!permissions.dashboard_access === true}
            onClick={() => handleCheckout('Delivery')}
          >
            Delivery
          </button>
          <button className="w-33" type="button" disabled>
            Dine In
          </button>
          <Link
            to="/tasks"
            className={`w-50 ${
              !permissions.assign_access === true ? 'disabled' : ''
            }`}
            onClick={(evt) => handleLink(evt, permissions.assign_access)}
          >
            Assign To Driver
          </Link>
          <Link
            to="/tasks/create"
            className={`w-50 ${
              !permissions.tasks_access === true ? 'disabled' : ''
            }`}
            onClick={(evt) => handleLink(evt, permissions.tasks_access)}
          >
            Third Party Order
          </Link>
          <Link
            to="/customers"
            className={`w-33 ${
              !permissions.dashboard_access === true ? 'disabled' : ''
            }`}
            onClick={(evt) => handleLink(evt, permissions.dashboard_access)}
          >
            Customers
          </Link>
          <Link
            to="/members"
            className={`w-33 ${
              !permissions.drivers_access === true ? 'disabled' : ''
            }`}
            onClick={(evt) => handleLink(evt, permissions.drivers_access)}
          >
            Drivers
          </Link>
          <Link
            to="/orders"
            className={`w-33 ${
              !permissions.dashboard_access === true ? 'disabled' : ''
            }`}
            onClick={(evt) => handleLink(evt, permissions.dashboard_access)}
          >
            Orders
          </Link>
          <Link
            to="/sales"
            className={`w-100 ${
              !permissions.summary_access === true ? 'disabled' : ''
            }`}
            onClick={(evt) => handleLink(evt, permissions.summary_access)}
          >
            Today Summary
          </Link>
          <button
            className={`w-100 ${
              !permissions.till_access === true ? 'disabled' : ''
            }`}
            type="button"
            disabled={!permissions.till_access === true}
            onClick={handleKickDrawer}
          >
            Open Drawer
          </button>
        </Col>
        <Col>
          <BranchDetails />
        </Col>
      </Row>
      <Row className="footer">
        <Link to="/clock-out">Shut Down</Link>
        <Link
          to="/management"
          className={`${
            !permissions.management_access === true ? 'disabled' : ''
          }`}
          onClick={(evt) => handleLink(evt, permissions.management_access)}
        >
          Management
        </Link>
        <Link
          to="/till-management"
          className={`${!permissions.till_access === true ? 'disabled' : ''}`}
          onClick={(evt) => handleLink(evt, permissions.till_access)}
        >
          {tillStatus === 'open' ? 'Close' : 'Open'} Till
        </Link>
        <button
          type="button"
          onClick={handleRefreshMenu}
          disabled={!permissions.dashboard_access === true}
        >
          <Loadable color="white" active={state === 'loading'}>
            Refresh Menu
          </Loadable>
        </button>
      </Row>
    </div>
  );
}

export default Home;
