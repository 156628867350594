import {call, put, takeLatest, select} from 'redux-saga/effects';
import {push} from 'connected-react-router';
import apiUrl from '../../Configs/ApiUrl';
import apiCall from '../../Services/ApiCall';
import {FIND_CUSTOMER} from './constants';
import {findCustomerError, findCustomerSuccess} from './actions';
import AuthorizedPostHeaders from '../../Configs/AuthorizedPostHeaders';
import {makeSelectToken} from '../Authentication/selectors';
import {unauthorized} from '../Authentication/actions';
import {bookingProductsToProducts} from '../../Libs/bookings';

export function* findCustomer(action) {
  const token = yield select(makeSelectToken());
  const requestUrl = `${apiUrl}/users/findByTelephoneOrName`;

  try {
    // eslint-disable-next-line camelcase
    const {customer, notes, last_booking} = yield call(
      apiCall,
      requestUrl,
      AuthorizedPostHeaders(
        {
          q: action.q,
        },
        token,
      ),
    );
    const cart = bookingProductsToProducts(last_booking.booking_products || []);
    yield put(findCustomerSuccess(customer, notes || [], cart));
  } catch (e) {
    if (e.message === 'Unauthorized') {
      yield put(unauthorized());
      yield put(push('/'));
    } else {
      yield put(findCustomerError(e.message));
    }
  }
}

export default function* customerSaga() {
  yield takeLatest(FIND_CUSTOMER, findCustomer);
}
