import React from 'react';

const NumpadKey = ({onPress, className, ...props}) => (
  <button
    type="button"
    onClick={onPress}
    className={`numpad-key ${className}`}
    {...props}
  />
);

export default NumpadKey;
