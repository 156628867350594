import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, Redirect} from 'react-router-dom';
import Button from 'reactstrap/es/Button';
import {fromJS} from 'immutable';
import {makeSelectHotel} from '../Authentication/selectors';
import Master from '../Master';
import {
  makeSelectSelectedIndex,
  makeSelectSelectedProduct,
} from '../../Components/Cart/selectors';
import Price from '../../Components/Price';
import {addExtras} from '../../Components/Cart/actions';

function Extras() {
  const dispatch = useDispatch();
  const {push} = useHistory();
  const [items, setItems] = useState(fromJS([]));
  const {branch} = useSelector(makeSelectHotel());
  const selectedIndex = useSelector(makeSelectSelectedIndex());
  const selectedProduct = useSelector(makeSelectSelectedProduct());
  const selectExtras = (item) => {
    const index = items.toJS().findIndex((i) => i.id === item.id);
    if (index !== -1) {
      setItems(items.delete(index));
    } else {
      setItems(items.push(fromJS({...item, quantity: 1})));
    }
  };

  useEffect(() => {
    setItems(fromJS(selectedProduct.extras || []));
  }, []);
  if (selectedIndex === -1) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <Master>
      <div className="customer-container">
        <h2 className="hold-title">Additional Items</h2>
        <div className="hold-items">
          {selectedProduct.hold?.length > 0 ? (
            <h2 className="text-secondary">
              First {selectedProduct.hold.length} extras are free.
            </h2>
          ) : null}
          <div className="items">
            {branch.extras?.map((item) => {
              const index = items.toJS().findIndex((i) => i.id === item.id);
              return (
                <div
                  className={`item ${index !== -1 ? 'active' : ''}`}
                  key={item.id}
                  onClick={() => selectExtras(item)}
                >
                  <p>
                    {item.title}{' '}
                    <strong>
                      <Price>{item.price}</Price>
                    </strong>
                  </p>
                </div>
              );
            })}
          </div>
          <div className="row my-4 mx-0 justify-content-center">
            <div className="w-25">
              <Button
                className="btn btn-success btn-block p-4"
                onClick={() => {
                  dispatch(addExtras(items.toJS(), selectedIndex));
                  push('/dashboard');
                }}
              >
                <strong>Done</strong>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Master>
  );
}

export default Extras;
