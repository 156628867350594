import React, {useEffect, useState} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Row, Col} from 'reactstrap';

import {
  makeSelectClockedIn,
  makeSelectHotel,
  makeSelectUserPermissions,
} from '../Authentication/selectors';
import TotalCharges from './TotalCharges';
import PinVerification from './PinVerification';
import {fetchTill} from './actions';

function TillManagement() {
  const dispatch = useDispatch();
  const {branch} = useSelector(makeSelectHotel());
  const clockedIn = useSelector(makeSelectClockedIn());
  const permissions = useSelector(makeSelectUserPermissions());
  const [total, setTotal] = useState('0');

  const [step, setStep] = useState('total');
  useEffect(() => {
    dispatch(fetchTill());
  }, []);
  if (!clockedIn || !permissions.till_access === true) {
    return <Redirect to="/" />;
  }
  return (
    <div className="drivers">
      <Link to="/" className="go-back">
        Go Back
      </Link>
      <Row>
        <Col>
          <h2 className="title">{branch.official_title}</h2>
        </Col>
      </Row>
      {step === 'total' ? (
        <TotalCharges setTotal={setTotal} total={total} setStep={setStep} />
      ) : (
        <PinVerification total={total} />
      )}
    </div>
  );
}

export default TillManagement;
