import React, {useEffect, useMemo, useState} from 'react';
import {useParams, Redirect, useHistory} from 'react-router-dom';
import {fromJS} from 'immutable';
import {useDispatch, useSelector} from 'react-redux';
import {Row} from 'reactstrap';
import Master from '../Master';
import {getProductById} from '../../Libs/menu';
import {makeSelectMenu, makeSelectSelectedMenu} from '../../Selectors/Menu';
import Steps from './Steps';
import Tier from './Tier';
import {checkIfObjectEmpty} from '../../Libs/object';
import {getCurrentTier} from '../../Libs/product';
import {addTocart} from '../../Components/Cart/actions';

function Product() {
  const dispatch = useDispatch();
  const {push} = useHistory();
  const {id, category} = useParams();
  const selectedMenu = useSelector(makeSelectSelectedMenu());
  const products = useSelector(makeSelectMenu());
  const [previousStep, setPreviousStep] = useState(-1);
  const product = useMemo(() => getProductById(products, parseInt(id, 10)), [
    id,
  ]);
  // console.log('product', product);
  const [currentTier, setCurrentTier] = useState(0);
  const [item, setItem] = useState(
    fromJS({
      id: product.id,
      price_takeaway: product.price_takeaway,
      price_delivery: product.price_delivery,
      title: product.title,
      menu_id: selectedMenu.id,
      tiers: [],
      quantity: 1,
      clickable: {},
      hold: [],
      extras: [],
    }),
  );

  useEffect(() => {
    const tier = getCurrentTier(
      currentTier,
      product.tiers,
      product.specific_tiers,
      product.clickables,
      item.get('clickable').toJS(),
    );
    if (checkIfObjectEmpty(tier)) {
      dispatch(addTocart(item));
      push('/dashboard');
    }
  });

  const setTier = (tier) => {
    let nextIndex = -1;
    if (previousStep !== -1) {
      nextIndex = previousStep;
      setCurrentTier(nextIndex);
    } else {
      nextIndex =
        currentTier === product.tiers.length - 1 && product.clickables?.length
          ? 'clickable'
          : currentTier + 1;
      setCurrentTier(nextIndex);
    }
    setPreviousStep(-1);
    setItem(item.setIn(['tiers', currentTier], fromJS(tier)));
  };

  const setClickable = (clickable) => {
    setItem(item.set('clickable', fromJS(clickable)));
    setCurrentTier(product.tiers.length);
  };

  if (!category || !id) {
    return <Redirect to="/dashboard" />;
  }

  if (checkIfObjectEmpty(item.toJS())) {
    return null;
  }

  const tier = getCurrentTier(
    currentTier,
    product.tiers,
    product.specific_tiers,
    product.clickables,
    item.get('clickable').toJS(),
  );
  return (
    <Master>
      <div className="container-fluid customer-container">
        <Steps
          product={product}
          setCurrentTier={setCurrentTier}
          currentTier={currentTier}
          setPreviousStep={setPreviousStep}
          clickable={item.get('clickable').toJS()}
        />
        <Row className="mx-1 bg-dark text-light mb-2 py-2 pl-3 box-shadow">
          <strong>{product.title}</strong>
        </Row>
        <Row className="tier-wrapper">
          <Tier
            selectedSize={item.getIn(['tiers', 0])?.toJS()}
            selectedTier={item.getIn(['tiers', currentTier])?.toJS()}
            tier={tier}
            clickables={product.clickables}
            setClickable={setClickable}
            setTier={setTier}
          />
        </Row>
      </div>
    </Master>
  );
}

export default Product;
