import React, {useMemo} from 'react';
import {useParams, Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Master from '../Master';
import {bookingByUnique} from '../../Libs/bookings';
import {makeSelectBookings} from '../Bookings/selectors';
import UpdateSyncedBookingStatus from './UpdateSyncedBookingStatus';
import {checkIfObjectEmpty} from '../../Libs/object';

function UpdateBookingStatus() {
  const {id} = useParams();
  const bookings = useSelector(makeSelectBookings());
  const booking = useMemo(() => bookingByUnique(bookings, id), [bookings, id]);
  if (checkIfObjectEmpty(booking)) {
    return <Redirect to="/orders" />;
  }
  return (
    <Master>
      <UpdateSyncedBookingStatus booking={booking} />
    </Master>
  );
}

export default UpdateBookingStatus;
