/* eslint-disable no-use-before-define */
import findIndex from 'lodash/findIndex';
import {fromJS} from 'immutable';
import {checkIfObjectEmpty} from './object';

export const checkIfProductCompleted = (selected, {tiers = []}) => {
  let completable = true;
  tiers.every((tier, index) => {
    if (tier.required === 1) {
      completable = !!selected.tiers[index];
      return completable;
    }
    return true;
  });
  return completable;
};
export const getProductDiscount = (discount, mode) => {
  return discount[mode];
};

export const getAddonIndex = (addon, addons = []) => {
  return findIndex(addons, (a) => a.id === addon.id);
};

export const addAddonToTier = (tier, addon) => {
  const freeQuantity = getFreeAddonsQuantity(
    tier.getIn(['selected', 'free'])?.toJS() || [],
  );
  let tag = 'paid';
  if (freeQuantity < tier.get('free_items')) {
    tag = 'free';
  }
  const addonIndex = getAddonIndex(
    addon,
    tier.getIn(['selected', tag])?.toJS(),
  );
  if (addonIndex !== -1) {
    return tier.setIn(
      ['selected', tag, addonIndex, 'quantity'],
      tier.getIn(['selected', tag, addonIndex, 'quantity']) + 1,
    );
  }
  return tier.setIn(
    ['selected', tag],
    getAddonsByTag(tier, tag).push(fromJS({...addon, quantity: 1})),
  );
};

export const getAddonsByTag = (selected, tag) => {
  return selected.getIn(['selected', tag]) || fromJS([]);
};
export const getAddonQuantity = (addons) => {
  const freeTotal =
    addons.free?.reduce((total, a) => a.quantity + total, 0) || 0;
  const paidTotal =
    addons.paid?.reduce((total, a) => a.quantity + total, 0) || 0;
  return freeTotal + paidTotal;
};

export const getFreeAddonsQuantity = (addons) => {
  return addons.reduce((total, a) => a.quantity + total, 0) || 0;
};

export const getPaidAddonsQuantity = (addons) => {
  return addons.paid?.reduce((total, a) => a.quantity + total, 0) || 0;
};

export const getItemDetails = (index, product) => {
  if (product.tiers < index) {
    // showing extra tiers
  } else {
    // showing normal tiers
  }
  return product.tiers ? product.tiers[index] : {};
};

export const getExtraTiers = (specificTiers = [], clickable = {}) => {
  return specificTiers.filter((tier) => tier.tag === clickable.title);
};

export const getCurrentTier = (
  currentTier,
  productTiers = [],
  specificTiers = [],
  clickables = [],
  clickable,
) => {
  if (currentTier < productTiers.length) {
    return productTiers[currentTier];
  }
  if (checkIfObjectEmpty(clickable)) {
    if (clickables.length > 0) {
      return {type: 'Clickable', clickables};
    }
    return {};
  }
  const extraTiers = getExtraTiers(specificTiers, clickable);
  return extraTiers[currentTier - productTiers.length];
};

export const getSelectedClickable = (
  selectedTiers = [],
  specificTiers = [],
  clickables,
) => {
  if (clickables.length === 0) {
    return {};
  }
  const {id} = selectedTiers[selectedTiers.length - 1];
  if (id) {
    const {tag} = specificTiers.find((tier) => tier.id === id) || {};
    return clickables.find((clickable) => {
      return clickable.title === tag;
    });
  }
  return {};
};
