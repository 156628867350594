import React, {useState} from 'react';
import {Button, Col, Form, FormGroup, Row} from 'reactstrap';
import Input from 'reactstrap/es/Input';
import Alert from 'reactstrap/es/Alert';
import {useDispatch, useSelector} from 'react-redux';
import confirm from 'reactstrap-confirm';
import {useHistory} from 'react-router-dom';
import qz from 'qz-tray';
import Loadable from '../../Components/Loadable';
import Time from '../../Components/Time';
import apiCall from '../../Services/ApiCall';
import apiUrl from '../../Configs/ApiUrl';
import AuthorizedPostHeaders from '../../Configs/AuthorizedPostHeaders';
import {unauthorized} from '../Authentication/actions';
import {makeSelectHotel, makeSelectToken} from '../Authentication/selectors';
import {makeSelectStatus} from './selectors';
import {closeTill, openTill} from './actions';
import {printSalesReport} from '../../Libs/printSalesReport';
import QzTray from '../../Configs/QZTray';
import {makeSelectDefaultPrinter} from '../Management/selectors';

function PinVerification({total}) {
  const dispatch = useDispatch();
  const {push} = useHistory();
  const defaultPrinter = useSelector(makeSelectDefaultPrinter());
  const tillStatus = useSelector(makeSelectStatus());
  const token = useSelector(makeSelectToken());
  const {branch} = useSelector(makeSelectHotel());
  const [code, setCode] = useState('3364');
  const [state, setState] = useState('initial');
  const [status, setStatus] = useState('');
  const handleKeypadClick = (ev, num) => {
    ev.preventDefault();
    if (code.length <= 3) {
      setCode(`${code}${num}`);
    }
  };

  const resetKeypadHandler = (ev) => {
    ev.preventDefault();
    setCode('');
  };

  async function handlePrintSalesReport(summary) {
    try {
      const printString = printSalesReport(
        summary,
        branch,
        'TILL CLOSING REPORT',
      );
      const config = await QzTray(defaultPrinter);
      await qz.print(config, printString);
      await qz.websocket.disconnect();
    } catch (e) {
      console.log('offline print error', e);
    }
  }

  const handleTillSession = async (evt) => {
    evt.preventDefault();

    const confirmed = await confirm({
      title: <>Till action verification</>,
      message: 'Are you sure?',
      confirmText: tillStatus === 'open' ? 'Close Till' : 'Open Till',
      confirmColor: 'primary',
      cancelColor: 'link text-danger',
    });
    // console.log(response);
    if (confirmed === false) {
      return;
    }
    setState('loading');
    setStatus('');
    try {
      const response = await apiCall(
        `${apiUrl}/pos/tillSession`,
        AuthorizedPostHeaders(
          {
            branch_id: branch.id,
            status: tillStatus === 'open' ? 'close' : 'open',
            pin: code,
            total,
          },
          token,
        ),
      );
      if (tillStatus === 'close') {
        dispatch(openTill(response.opening_total, response.created_at));
      } else {
        await handlePrintSalesReport(response.summary);
        dispatch(
          closeTill(
            response.till_session.closing_total,
            response.till_session.updated_at,
          ),
        );
      }
      setState('success');
      push('/');
    } catch (e) {
      console.log('clock in error', e);

      if (e.message === 'Unauthorized') {
        dispatch(unauthorized());
        push('/');
      } else {
        setStatus(e.message);
        setState('error');
      }
    }
  };
  return (
    <>
      <Row>
        <Col>
          <h3 className="header">TILL MANAGEMENT</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form className="form m-0" onSubmit={handleTillSession}>
            <div className="num">
              <Row>
                <Col>
                  <center>
                    <FormGroup>
                      <Input
                        type="password"
                        className="pin-input"
                        name="pin"
                        placeholder="Enter your pin"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                      />
                    </FormGroup>
                  </center>
                </Col>
              </Row>
              {state === 'error' ? (
                <Row>
                  <Col>
                    <center>
                      <Alert color="danger">{status}</Alert>
                    </center>
                  </Col>
                </Row>
              ) : null}
              <Row>
                <Col md="4" sm="4" xs="4">
                  <Button
                    color="primary"
                    onClick={(ev) => handleKeypadClick(ev, 1)}
                    className="numeric-input"
                    size="sm"
                  >
                    1
                  </Button>
                </Col>
                <Col md="4" sm="4" xs="4">
                  <Button
                    color="primary"
                    onClick={(ev) => handleKeypadClick(ev, 2)}
                    className="numeric-input"
                    size="sm"
                  >
                    2
                  </Button>
                </Col>
                <Col md="4" sm="4" xs="4">
                  <Button
                    color="primary"
                    onClick={(ev) => handleKeypadClick(ev, 3)}
                    className="numeric-input"
                    size="sm"
                  >
                    3
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col md="4" sm="4" xs="4">
                  <Button
                    color="primary"
                    onClick={(ev) => handleKeypadClick(ev, 4)}
                    className="numeric-input"
                    size="sm"
                  >
                    4
                  </Button>
                </Col>
                <Col md="4" sm="4" xs="4">
                  <Button
                    color="primary"
                    onClick={(ev) => handleKeypadClick(ev, 5)}
                    className="numeric-input"
                    size="sm"
                  >
                    5
                  </Button>
                </Col>
                <Col md="4" sm="4" xs="4">
                  <Button
                    color="primary"
                    onClick={(ev) => handleKeypadClick(ev, 6)}
                    className="numeric-input"
                    size="sm"
                  >
                    6
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col md="4" sm="4" xs="4">
                  <Button
                    color="primary"
                    onClick={(ev) => handleKeypadClick(ev, 7)}
                    className="numeric-input"
                    size="sm"
                  >
                    7
                  </Button>
                </Col>
                <Col md="4" sm="4" xs="4">
                  <Button
                    color="primary"
                    onClick={(ev) => handleKeypadClick(ev, 8)}
                    className="numeric-input"
                    size="sm"
                  >
                    8
                  </Button>
                </Col>
                <Col md="4" sm="4" xs="4">
                  <Button
                    color="primary"
                    onClick={(ev) => handleKeypadClick(ev, 9)}
                    className="numeric-input"
                    size="sm"
                  >
                    9
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col md="4" sm="4" xs="4">
                  <Button
                    color="primary"
                    onClick={(ev) => handleKeypadClick(ev, 0)}
                    className="numeric-input"
                    size="sm"
                  >
                    0
                  </Button>
                </Col>
                <Col md="8" sm="8" xs="8">
                  <Button
                    color="primary"
                    onClick={(ev) => resetKeypadHandler(ev)}
                    className="numeric-input-2"
                    size="sm"
                  >
                    C
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col md="12" sm="12" xs="12">
                  <button
                    onClick={handleTillSession}
                    className="btn btn-primary btn-sm input-signin"
                    type="submit"
                  >
                    <Loadable color="white" active={state === 'loading'}>
                      {tillStatus === 'open' ? 'CLOSE' : 'OPEN'} TILL
                    </Loadable>
                  </button>
                </Col>
              </Row>
              <Time />
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );
}

export default PinVerification;
