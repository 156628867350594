import React from 'react';
import {useSelector} from 'react-redux';
import {getProperPrice, priceByMode} from '../../Libs/prices';
import Price from '../../Components/Price';
import {makeSelectCheckoutMode} from '../../Components/Cart/selectors';

function EditOption({options, selectedOption, size, updateOption}) {
  const mode = useSelector(makeSelectCheckoutMode());
  return options.map((option) => {
    const prices = getProperPrice(option, size);
    const itemPrice = priceByMode(prices, mode);
    return (
      <div
        key={option.id}
        onClick={() => updateOption(option)}
        className={`prod-list ${
          selectedOption.selected.id === option.id ? 't-selected' : ''
        }`}
      >
        <div className="prod-title">
          <h6>{option.title}</h6>
        </div>
        <div className="prod-price">
          <strong>
            <Price>{itemPrice}</Price>
          </strong>
        </div>
      </div>
    );
  });
}

export default EditOption;
