export default function (request, token) {
  return {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      platform: 'pos',
    },
    body: JSON.stringify(request),
  };
}
