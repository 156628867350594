import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {makeSelectCheckoutMode} from './Cart/selectors';
import {deformatTelephoneNumber, reformatTelephoneNumber} from '../Libs/object';
import {clearCustomer, findCustomer} from '../Pages/Customer/actions';
import {toggleCheckout} from './Cart/actions';

function IncomingCall({call, onProcessCall}) {
  const dispatch = useDispatch();
  const {push} = useHistory();
  const checkoutMode = useSelector(makeSelectCheckoutMode());

  const handleFindCustomer = () => {
    dispatch(clearCustomer());
    dispatch(
      findCustomer(
        reformatTelephoneNumber(deformatTelephoneNumber(call?.from)),
        'live',
      ),
    );
    onProcessCall(call, 'processed');
    push('/customer');
  };

  if (call.status === 'call_answered' || call.status === 'accepted') {
    return (
      <div className="incoming-call" key={call.callId}>
        <div className="header">
          <p>New Customer</p>
        </div>
        <div className="body">
          <p>
            <strong>Number: </strong>
            {call.from}
          </p>
        </div>
        <div className="footer">
          {call.status === 'accepted' ? (
            <div className="checkout-mode">
              <div className="mods">
                <div
                  className={`mod takeaway ${
                    checkoutMode === 'Takeaway' ? 'selected' : ''
                  }`}
                  onClick={() => dispatch(toggleCheckout('Takeaway'))}
                >
                  <p>Takeaway</p>
                </div>
                <div
                  className={`mod delivery ${
                    checkoutMode === 'Delivery' ? 'selected' : ''
                  }`}
                  onClick={() => dispatch(toggleCheckout('Delivery'))}
                >
                  <p>Delivery</p>
                </div>
              </div>
              <button
                type="button"
                onClick={handleFindCustomer}
                className="done"
              >
                FIND CUSTOMER
              </button>
            </div>
          ) : (
            <>
              <div
                className="accept"
                onClick={() => onProcessCall(call, 'accepted')}
              >
                <i className="fa fa-phone" />
              </div>
              <div
                className="ignore"
                onClick={() => onProcessCall(call, 'rejected')}
              >
                <i className="fa fa-phone-slash" />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
  return null;
}

export default IncomingCall;
