import React from 'react';
import Price from '../../Components/Price';
import {getSyncedItemPrice, priceByMode} from '../../Libs/prices';
import Tier from './Tier';

function Item({product, mode}) {
  const prices = getSyncedItemPrice(
    product.price_takeaway,
    product.price_delivery,
    product.booking_tiers[0],
  );
  return (
    <div className="product">
      <div className="product-header">
        <strong>
          {product.quantity}x&nbsp;{product.title}&nbsp;&nbsp;
        </strong>
        <Price>{priceByMode(prices, mode)}</Price>
      </div>
      {product.booking_hold?.length > 0 && (
        <div className="tiers">
          <div className="tier">
            <div className="addons">
              <div className="left">
                <p className="basket-title">Hold: &nbsp;</p>
              </div>
              {product.booking_hold.map((hold) => (
                <div className="addon" key={hold.id}>
                  <div className="left">
                    <p className="extras-text">{hold.title} &nbsp;</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {product.booking_extra?.length > 0 && (
        <div className="tiers">
          <div className="tier">
            <div className="addons">
              <div className="left">
                <p className="basket-title">Extras: &nbsp;</p>
              </div>
              {product.booking_extra.map((extra) => (
                <div className="addon" key={extra.id}>
                  <div className="left">
                    <p className="extras-text">{extra.title} &nbsp;</p>
                    <strong>
                      <Price className="extras-text">{extra.price}</Price>
                    </strong>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <div className="tiers">
        {product.booking_tiers.map((tier, i) => (
          <Tier key={tier.id + i} tier={tier} mode={mode} />
        ))}
      </div>
    </div>
  );
}

export default Item;
