import React from 'react';
import configureStore from '../../Redux/Store';

function UpdateSystem() {
  const handleUpdateSystem = () => {
    const {persistedStore} = configureStore({});
    persistedStore.purge([
      'menu',
      'auth',
      'cart',
      'bookings',
      'customer',
      'checkout',
      'drivers',
      'tasks',
      'till',
    ]);
    window.location = '/login';
  };
  return (
    <div className="update-system">
      <button type="submit" onClick={handleUpdateSystem}>
        Update System
      </button>
    </div>
  );
}

export default UpdateSystem;
