import qz from 'qz-tray';
import 'rsvp';
import {stob64, hextorstr, KEYUTIL, KJUR} from 'jsrsasign';

const cert = `-----BEGIN CERTIFICATE-----
MIIEJzCCAw+gAwIBAgIURKj3Wqzatu3B0fWjXkYovgAngbgwDQYJKoZIhvcNAQEL
BQAwgaExCzAJBgNVBAYTAlVLMQ8wDQYDVQQIDAZMb25kb24xEDAOBgNVBAcMB0Ny
YXdsZXkxDDAKBgNVBAoMA1NHVDEPMA0GA1UECwwGRGV2T3BzMS8wLQYDVQQDDCYq
Lml6bWlyYmFyZ3JpbGwuY28udWssICouZ2V0dGVzdC5jby51azEfMB0GCSqGSIb3
DQEJARYQd2NhYWFuQGdtYWlsLmNvbTAgFw0yMTAyMDkxMjUyMDVaGA8yMDUyMDgw
NDEyNTIwNVowgaExCzAJBgNVBAYTAlVLMQ8wDQYDVQQIDAZMb25kb24xEDAOBgNV
BAcMB0NyYXdsZXkxDDAKBgNVBAoMA1NHVDEPMA0GA1UECwwGRGV2T3BzMS8wLQYD
VQQDDCYqLml6bWlyYmFyZ3JpbGwuY28udWssICouZ2V0dGVzdC5jby51azEfMB0G
CSqGSIb3DQEJARYQd2NhYWFuQGdtYWlsLmNvbTCCASIwDQYJKoZIhvcNAQEBBQAD
ggEPADCCAQoCggEBANWoT++72BCCD2ULKLrAdODIfCzg3ZJNr2R4Jqm/tQINBYYA
+uV6DTI+Ahl4N+sR8Py3LamtR6wgvuqYl2pQ6/PBGTOp2JPwH0hflURUF3nki1WG
sajRIKhLcah29jgr+KouapBLtq7FMwHvzgaNxxHuXnJDcxyw1a9k8TNt/dXS37PQ
Z34MfJqn/rdjGn21OjnLapD0bmOwhb+/VKzsOqqDfQDZKz2xC6/66bqxXK8du80s
o8cCa3ANsK0uPWDBk9S+l9e6kldAtM0TiJLh4FDyTQ0/ZHMFH3TovsuZKec4PbJ6
Xj0Ej+nrlUdIL/AhD1ni+LvWVv8IxUWtjvf48JMCAwEAAaNTMFEwHQYDVR0OBBYE
FDaFknQ05kK3rX+rFwWf9MCJe8Y6MB8GA1UdIwQYMBaAFDaFknQ05kK3rX+rFwWf
9MCJe8Y6MA8GA1UdEwEB/wQFMAMBAf8wDQYJKoZIhvcNAQELBQADggEBADtPjP1P
XZF6i1lQous8SMrbLA0lzQ0YJVxEs4aFv8r0lp1LM4ZbRiKWcZqn7NA9uzGxduj6
SYZLxOZ1JUlsNmXyqUkyBN2Px33LCe0eGc5XGnlMm9N0vALbIqAFZjaOthm/29bl
KZn/lyKYGx/tqIt3iA912QfeSO1e5karsFfCiUHMWX9LTYcvf9X6qli+rkk45ibe
JLHSDBlHUpIHZvCelPdwrurqRoUjanViOvO5Btm4HUA7iHclpGyMmRhg8GBDWWIg
Esj8GtpsM8oMlKZqGQf8WYmxIVAsL2k0KfFaAXUcUO/Hq4foMshZoutFztXwAkSB
pEPStrMeYSvm714=
-----END CERTIFICATE-----`;

const privateKey = `-----BEGIN PRIVATE KEY-----
MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDVqE/vu9gQgg9l
Cyi6wHTgyHws4N2STa9keCapv7UCDQWGAPrleg0yPgIZeDfrEfD8ty2prUesIL7q
mJdqUOvzwRkzqdiT8B9IX5VEVBd55ItVhrGo0SCoS3GodvY4K/iqLmqQS7auxTMB
784GjccR7l5yQ3McsNWvZPEzbf3V0t+z0Gd+DHyap/63Yxp9tTo5y2qQ9G5jsIW/
v1Ss7Dqqg30A2Ss9sQuv+um6sVyvHbvNLKPHAmtwDbCtLj1gwZPUvpfXupJXQLTN
E4iS4eBQ8k0NP2RzBR906L7LmSnnOD2yel49BI/p65VHSC/wIQ9Z4vi71lb/CMVF
rY73+PCTAgMBAAECggEAVmwPcWws+HhzW67I2clxPV87Azgtx7kOofgLw4vc3qh/
vHV3Bw14ewMj/ENpcvc94CsEGGcT36cbMm9oh8OVvXI1DHW+OhDdxnuLOCk/mbl9
NqoWALAeopRps/fynkQDAGtoBVfq3AS+HiFu163PgQxHPbbUwB0zQg/1lJUAINW/
G7rFIOxNy6BthXPKWCbZ0YY0Gy8kZoadYJ6UIJobrM+7eefq7+9uZcjCZrsnQ2x/
7R0ZDL9Pj3MFgVWbqsUcB0o4xDjxsTcMYKOxSaaAh5joXjG4pJ8RdNQ3XoxwaoNu
z/joQt/LHzBY4MEik7uQ7f56TXcCj7QqmmwpcTA9mQKBgQDr3Im7q8ETJ3kvbqx6
78mJj+2Eam2aQETzJrseh9u+wAj0yvo/ZjJPF2hDFSEyXjGKqAxqZhGl5EiT+A9n
sVZyBav+xweQqb4iCR0QxcXgUWW1xQ3NwOpz3qumzd+Cdk7t6NpDFCAYrvl7vqg5
0IX3UGVZ6SerMSjumOWjvVcoxwKBgQDn5nBaQrVrq/bkyCosIiBSUdoPHy9UaDoU
uY+xACmPlBx9DOHbyI/HPGxeUersOZzYi32qJWapnD1GnamKIlSYJxq1loe0d73p
EvAV2JCRrys/a7Yuox6A3WergWvAJ26WDHl8L8v3OcDbiL2Yia2SORE57RniStSG
8yx9Ozbl1QKBgQCF1grB12Ajwk5dv+tXhyFRUb5xWQMAeF+nqd6cIHNs9gMfBN6j
gxYhEyMyjZbcwd5S07DX3zs8ayFT9XqoWFS5Nsa2N7HvQKKHJay8i70Q4J9aWYrP
qhxfy4aTjFa77O40yeCwF4du+jrPIQzMYari/1fDgS3lUmvrmA8WrqX6mQKBgHFl
psUMX8iB854WH0ErguCeM+I3Hp7IxLYDszcbXDdgdrnThdJCdcQ4UMmihxzyYzck
ptN/rH797pzMVe49MV6hqJbpP/zInO4PG6WjG7aRkLVXh2Ow0iaznZEpHTZ+Ub4/
5VwLx1JiRGNVr1ia4mxhSYIo5gJZEynWWYf3X7XtAoGAIQPLTC9GV/h/5AzKc6kH
xQ5+qwRmU7NYCwZhKC0Vh17JVr/UNJ8/atqZy+0A15Sfdm9wRAi5rvIaecfMC6B+
RDFWsMcpQDK0gaGjJ5fTQJZVHzzCsSgUuZrhb72a6vRJbdieP19LFeLSStBM1F8U
78fLB+VBiG8U47HTR0ExIKA=
-----END PRIVATE KEY-----`;

export function QzCertificate() {
  qz.security.setCertificatePromise(function (resolve) {
    resolve(cert);
  });
  qz.security.setSignaturePromise(function (toSign) {
    return function (resolve, reject) {
      try {
        const pk = KEYUTIL.getKey(privateKey);
        const sig = new KJUR.crypto.Signature({alg: 'SHA1withRSA'});
        sig.init(pk);
        sig.updateString(toSign);
        const hex = sig.sign();
        // console.log(`DEBUG: \n\n${stob64(hextorstr(hex))}`);
        resolve(stob64(hextorstr(hex)));
      } catch (err) {
        console.error(err);
        reject(err);
      }
    };
  });
}

async function QzTray(printer) {
  if (!qz.websocket.isActive()) {
    try {
      await qz.websocket.connect();
      if (printer.type === 'usb') {
        const p = await qz.printers.find(printer.name);
        return qz.configs.create(p);
      }

      return qz.configs.create({
        host: printer.host,
        port: printer.port,
      });
    } catch (e) {
      console.log('error connecting printer', e);
    }
  }
}

export default QzTray;
