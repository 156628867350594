/* eslint-disable */
// printing rolls have two sizes (approx width: 80mm) and (approx width: 88mm)
// approx width 80mm: maximum number of characters is 42
// approx width 88mm: maximum number of characters is 48
// find out how much do you need on the left side.
// for ex: In case of 80mm, 35 characters on the left side, rest character will be on the right. i.e 7 characters
// for totalColsPerPage = 40, leftCols = 28
// for totalColsPerPage = 42, leftCols = 30
// for totalColsPerPage = 48, leftCols = 36
import moment
  from 'moment';
import {paidStatus} from './bookings';
import {fromCents} from './prices';

const totalColsPerPage = 48;
const leftCols = 43;

const init = '\x1B\x40';
const alignCenter = '\x1B\x61\x31';
const alignLeft = '\x1B\x61\x30';
const alignRight = '\x1B\x61\x32';
const newLine = '\x0A';
const bold_on = '\x1B\x45\x0D';
const bold_off = '\x1B\x45\x0A';
const em_mode_on = '\x1B\x21\x30';
const em_mode_off = '\x1B\x21\x0A\x1B\x45\x0A';
const small_text = '\x1B\x4D\x31';
const normal_text = '\x1B\x4D\x25';
const norm_text = '\x1B\x4D\x25';
const big_text = '\x1B\x21\x25';
const bigger_text = '\x1B\x21\x20';
const cutPaper = '\x1B\x69';
const openCashDrawer = '\x10' + '\x14' + '\x01' + '\x00' + '\x05';
const total_amount = 0;
const voucher_amount = 0;

// Pound sign
// \u00a35

/// //////////////////////////////////////////////////////////////////
//
// Server Side method for verifying the certificate
// Recommended: Because the private key is on server
//
/// //////////////////////////////////////////////////////////////////

export const printDriverLogReport = function(report, driver) {
  return [
    init +
    em_mode_on +
    alignCenter +
    getTitle('DRIVER WAGES REPORT') +
    em_mode_off +
    newLine +
    newLine +
    dottedLine() +
    newLine +
    newLine +
    getSalesReport(report) +
    em_mode_off +
    alignCenter +
    dottedLine() +
    newLine +
    alignLeft +
    getDriverInfo(driver) +
    newLine +
    newLine +
    newLine +
    newLine +
    newLine +
    newLine +
    newLine +
    cutPaper,
  ];
};

function getTitle(title) {
  return title;
}

function getSalesReport(summary) {
  let summaryString = '';
  let totalPay = 0;
  let totalHours = moment.duration(0);
  for (let i = 0; i < summary.length; i++) {
    totalPay += parseInt(summary[i].total_pay || 0);
    totalHours.add(summary[i].total_hours);
    summaryString += bold_on + getItemRowFull('Date', summary[i].date) + bold_off;
    summaryString += getItemRowFull('Clock In', summary[i].clock_in) + newLine;
    summaryString += getItemRowFull('Clock Out', summary[i].clock_out) + newLine;
    summaryString += getItemRowFull('Total Hours', summary[i].total_hours) + newLine;
    summaryString += getItemRowFull('Total Pay', fromCents(summary[i].total_pay)) + newLine + newLine;
  }
  summaryString += dottedLine() + newLine;
  summaryString += bold_on;
  summaryString += getItemRowFull('Total Hours ', totalHours.humanize()) + newLine;
  summaryString += getItemRowFull('Total Wages ', fromCents(totalPay)) + newLine;
  summaryString += bold_off;
  return summaryString;
}


// Custom Details
function getDriverInfo(userAddress) {
  return (
    alignCenter +
    bold_on +
    bigger_text +
    maxRound('Driver Info') +
    bold_off +
    newLine +
    alignLeft +
    big_text +
    maxRound(userAddress.name) +
    newLine +
    maxRound(userAddress.address) +
    newLine +
    maxRound(`${userAddress.town}, ${userAddress.postcode}`) +
    newLine +
    maxRound(`P: ${userAddress.telephone || userAddress.mobile}`) +
    newLine +
    normal_text
  );
}

function getItemRowFull(itemTitle, itemPrice = '') {
  const price = itemPrice || '';
  const marginSpace = totalColsPerPage === 48 ? '' : '   ';

  let colsLeft = leftCols;
  if (itemTitle.length > leftCols) {
    colsLeft = !price ? totalColsPerPage - 5 : colsLeft;
    let rightCols = !price ? 5 : price.length;

    const itemTitleLength = itemTitle.length;
    let receipt = '';
    let startFrom = 0;
    let remainingCharacters = itemTitle.length;

    while (startFrom < itemTitleLength) {
      receipt +=
        /* alignLeft + */ marginSpace +
        itemTitle
          .substr(startFrom, colsLeft)
          .padEnd(totalColsPerPage - rightCols, ' ') +
        (startFrom === 0 ? price : '');
      receipt += newLine;
      remainingCharacters =
        remainingCharacters + itemTitleLength - (itemTitleLength + colsLeft);
      startFrom += colsLeft;
    }
    return receipt;
  }
  return (
    marginSpace +
    itemTitle.padEnd(totalColsPerPage - price.length, ' ') + price
  );
}

function maxRound(text) {
  const re = new RegExp(`.{1,${leftCols}}`, 'g');
  const chunk = text.match(re);
  const spaced = chunk.map((i) => `  ${i}`);
  return spaced.join(newLine);
}


function getPrice(price) {
  return parseFloat(price / 100).toFixed(2);
}

function dottedLine() {
  if (totalColsPerPage === 48) {
    return `${alignCenter}------------------------------------------------`;
  }
  return `${alignCenter}------------------------------------------`;
}
