import {fromJS} from 'immutable';

import {
  FIND_CUSTOMER_SUCCESS,
  FIND_CUSTOMER_ERROR,
  FIND_CUSTOMER,
  CLEAR_CUSTOMER,
} from './constants';
import {CLEAR_CART} from '../../Components/Cart/constants';

const initialState = fromJS({
  customer: {},
  state: 'initial',
  status: '',
  busy: false,
  customerNumber: '',
  customerNotes: [],
});

export default function customerReducer(state = initialState, action) {
  switch (action.type) {
    case FIND_CUSTOMER: {
      return state
        .set('customerNumber', action.mode === 'live' ? action.q : '')
        .set('state', 'loading')
        .set('customer', fromJS({}))
        .set('status', '')
        .set('busy', true)
        .set('customerNotes', fromJS([]));
    }
    case FIND_CUSTOMER_SUCCESS: {
      return state
        .set('state', 'success')
        .set('customer', fromJS(action.customer))
        .set('customerNotes', fromJS(action.notes));
    }
    case FIND_CUSTOMER_ERROR:
      return state.set('state', 'error').set('status', action.status);
    case CLEAR_CUSTOMER:
      return initialState;
    case CLEAR_CART:
      return initialState;
    default:
      return state;
  }
}
